import React, { useState } from 'react';
import {
  IonButton,
  IonTextarea,
  IonText,
  IonIcon,
  IonInput,
  IonPage,
  IonHeader,
  IonToolbar,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButtons,
  IonTitle,
  IonCard,
  IonFooter,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import {
  CertificationService,
  CoinGradeValue,
  CoinModel,
  CoinType,
  CoinValueItem,
  Grade,
  Options,
} from '../models/types';
import { closeOutline } from 'ionicons/icons';
import ItemSelector from './items/ItemSelector';
import QuantitySelector from './common/QuantitySelector';
import ValuesList from './values/ValuesList';
import ValuesLinks from './values/ValuesLinks';
// import { useModelPicker } from '../hooks/useModelPicker';

interface BidFormProps {
  coinValueItem: CoinValueItem;
  coinGradeValue?: CoinGradeValue;
  // grade
  coinModel: CoinModel;
  coinType: CoinType;
  options: Options;
  onDismiss: () => void;
  onSubmit: () => void;
  measure?: string;
}

/**
 * Provides a form to view/add/edit a bid
 * @param coinInfo information already gathered about a coin that will be bid on
 * @param coinGradeValue
 * @param onDismiss callback when user wishes to revert changes
 * @catalog contains category, type, model for selection
 * @constructor
 */
const PricingFeedbackForm: React.FC<BidFormProps> = ({
  coinValueItem,
  coinModel,
  coinGradeValue,
  onDismiss,
  options,
}) => {
  const [userComments] = useState<string>();
  const [quantity, setQuantity] = useState<number>(1);
  const labelPlacement = 'stacked';
  const [item, setItem] = useState<CoinValueItem>({
    ...coinValueItem,
    model: coinModel,
    grade: coinGradeValue?.grade,
    bidAmount: Number(coinGradeValue?.value || 0),
    eachAmount: Number(coinGradeValue?.value || 0),
    comments: userComments,
    faceValue: coinValueItem?.faceValue || 0.0,
    certificationService: coinValueItem?.certificationService,
  });
  const [estimatedCoinGradeValue, setEstimatedCoinGradeValue] = useState<CoinGradeValue | undefined>(coinGradeValue);

  //  // To use this it needs to be put into a modal like this...
  // * First parameter is the component to show, second is the props to pass
  // */
  // const [present] = useIonModal(
  //   <AppTypeahead
  //     title='Select Multiple'
  //     items={coinModels}
  //     // TODO could include all the ids of models on the selected sheet
  //     selectedItems={[]} // selectedItems={selectedFruits}
  //     // onSelectionCancel={() => modal.current?.dismiss()}
  //     // onSelectionChange={fruitSelectionChanged}
  //   />,
  // );

  // const { openModelPicker } = useModelPicker();

  return (
    <IonPage>
      <IonHeader translucent={true} color={'primary'}>
        <IonToolbar color={'primary'}>
          <IonTitle class={'ion-text-center'}>
            <IonText>Select Item</IonText>
          </IonTitle>
          <IonButtons slot={'end'}>
            <IonButton onClick={onDismiss}>
              <IonIcon slot={'icon-only'} icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {/*<IonCard>{item && JSON.stringify(item)}</IonCard>*/}
        <IonGrid>
          <IonRow>
            <IonCol sizeXs={'12'} sizeSm={'6'}>
              <IonInput
                readonly={true}
                // onClick={openModelPicker}
                fill='outline'
                value={`${coinModel?.name} ${coinModel?.coinType?.name}`}
              />
            </IonCol>
            <IonCol sizeXs={'12'} sizeSm={'3'}>
              {/*<IonNote>*/}
              {/*  <IonLabel>&nbsp;</IonLabel>*/}
              {/*</IonNote>*/}
              <IonSelect
                label='Certification'
                labelPlacement={labelPlacement}
                value={item.certificationService}
                fill='outline'
                placeholder={'Choose One...'}
                onIonChange={(e) => {
                  setItem({ ...item, certificationService: e.detail.value });
                }}
              >
                {options?.certificationService &&
                  options?.certificationService?.map((cs: CertificationService) => (
                    <IonSelectOption key={cs.id} value={cs.id}>
                      {cs.display}
                    </IonSelectOption>
                  ))}
              </IonSelect>
            </IonCol>
            <IonCol sizeXs={'12'} sizeSm={'3'}>
              <IonSelect
                label='Condition/Grade'
                labelPlacement={labelPlacement}
                value={item.grade}
                fill='outline'
                placeholder={'Choose One...'}
                onIonChange={(e) => {
                  const newCoinGradeValue = coinModel?.values?.find((m) => m.grade === e.detail.value);
                  setItem({ ...item, grade: e.detail.value, eachAmount: newCoinGradeValue?.value });
                  setEstimatedCoinGradeValue(newCoinGradeValue);
                  console.debug(`selected grade ${e.detail.value} for model `, coinModel);
                }}
              >
                {options?.grade &&
                  options?.grade?.map((g: Grade) => (
                    <IonSelectOption key={g.code} value={g.code}>
                      {g.display}
                    </IonSelectOption>
                  ))}
              </IonSelect>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonTextarea
                label={'Additional Details'}
                aria-label={'Additional Details Input'}
                fill='outline'
                rows={1}
                labelPlacement={labelPlacement}
                spellCheck={true}
                // placeholder={'Describe the item...'}
                onIonInput={(e) => {
                  setItem({ ...item, comments: `${e.detail.value}` });
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeXs={'6'} sizeSm={'4'}>
              <div
                // className={'input-wrapper sc-ion-input-md'}
                style={{
                  height: '50px',
                  // border: '1px solid black',
                  // height: '100%',
                  verticalAlign: 'middle',
                  // lineHeight: '.5em',
                  maxHeight: '50px',
                  overflow: 'hidden',
                }}
              >
                {/*TODO to edit we will check for id and update vs. add*/}
                {(!coinModel?.measure || coinModel?.measure === 'qty') && (
                  <QuantitySelector quantity={quantity} onChange={setQuantity} increment={1} />
                )}
                {(!coinModel?.measure || coinModel?.measure === 'fv') && (
                  <QuantitySelector
                    quantity={item?.faceValue || 0.0}
                    onChange={(v) => setItem({ ...item, faceValue: Number(v) })}
                    increment={0.05}
                    precision={2}
                  />
                )}
              </div>
            </IonCol>
            {/*<IonCol class={'ion-align-items-center ion-text-center ion-padding-vertical'}>@</IonCol>*/}
            <IonCol sizeXs={'6'} sizeSm={'4'}>
              <IonInput
                label={'Each Value'}
                aria-label={'Each Value Input'}
                fill='outline'
                style={{ textAlign: 'center' }}
                type={'number'}
                labelPlacement={labelPlacement}
                value={item?.eachAmount}
                helperText={`Est. value is ${
                  estimatedCoinGradeValue?.value
                    ? Number(estimatedCoinGradeValue.value).toLocaleString('en-US', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })
                    : 0
                }`}
                placeholder={`$${
                  estimatedCoinGradeValue?.value
                    ? Number(estimatedCoinGradeValue.value).toLocaleString('en-US', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })
                    : 0
                }`}
                spellCheck={true}
                onIonInput={(e) => {
                  setItem({ ...item, eachAmount: Number(e.detail.value) });
                }}
              />
            </IonCol>
            <IonCol sizeXs={'12'} sizeSm={'4'}>
              <IonInput
                label={'Total'}
                aria-label={'Total'}
                readonly={true}
                fill='outline'
                style={{ textAlign: 'center' }}
                type={'number'}
                labelPlacement={labelPlacement}
                helperText={`${quantity} x ${
                  item?.eachAmount
                    ? Number(item?.eachAmount).toLocaleString('en-US', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })
                    : 0
                }`}
                placeholder={
                  Number((item?.eachAmount || 0) * (quantity || 1) * (item?.faceValue || 1)).toLocaleString('en-US', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }) ?? 0
                }
                spellCheck={true}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonCard>
          <ValuesList value={estimatedCoinGradeValue} model={coinModel} />
        </IonCard>
      </IonContent>
      <IonFooter>
        <ValuesLinks value={estimatedCoinGradeValue} model={coinModel} />
      </IonFooter>
      <IonFooter class={'ion-padding'}>
        <IonGrid>
          <IonRow>
            <IonCol class={'ion-text-center'}>
              <IonButton color={'medium'} expand={'block'} onClick={onDismiss}>
                <IonText>Cancel</IonText>
              </IonButton>
            </IonCol>
            <IonCol class={'ion-text-center'}>
              <ItemSelector item={item} quantity={quantity} onSuccess={onDismiss} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonFooter>
    </IonPage>
  );
};

export default PricingFeedbackForm;
