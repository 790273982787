import { IonCol, IonGrid, IonItem, IonItemSliding, IonLabel, IonNote, IonRow } from '@ionic/react';
import React from 'react';
import { CoinValueItem } from '../../models/types';
import QuantitySelector from '../common/QuantitySelector';
import OptionValue from '../common/OptionValue';

interface ItemListItemProps {
  item: CoinValueItem;
  removeHandler?: (item: CoinValueItem) => void;
  quantityChangeHandler?: (item: CoinValueItem, matchBy: string[], quantity?: number) => void;
  sortCols?: string[];
}

const ItemListItem: React.FC<ItemListItemProps> = ({
  item,
  removeHandler,
  quantityChangeHandler,
  sortCols = ['grade', 'model.id', 'eachAmount', 'comments'],
}) => {
  return (
    <IonItemSliding>
      {/*<IonItemOptions side='start'>*/}
      {/*  <IonItemOption color='danger' expandable={!!removeHandler} onClick={handleRemoveClick}>*/}
      {/*    <IonText>Delete</IonText>*/}
      {/*  </IonItemOption>*/}
      {/*</IonItemOptions>*/}

      <IonItem>
        <IonGrid>
          <IonRow class='ion-padding-bottom ion-padding-top'>
            <IonCol>
              <IonLabel class='ion-text-wrap'>
                {item?.model?.name}
                {item?.certificationService && (
                  <span>
                    &nbsp;
                    <OptionValue
                      optionName={'certificationService'}
                      value={item?.certificationService}
                      valueProp={'id'}
                    />
                  </span>
                )}
                {item?.grade && (
                  <span>
                    &nbsp;
                    <OptionValue optionName={'grade'} value={item?.grade} valueProp={'code'} />
                  </span>
                )}
              </IonLabel>
              {item?.model?.coinType?.name && <IonNote>{item?.model?.coinType?.name}</IonNote>}
            </IonCol>
            <IonCol class='ion-text-right'>
              <IonLabel>
                $
                {`${Number(item?.bidAmount).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              </IonLabel>
              {item?.faceValue === 0 ? (
                // style={{ display: 'inline-flex', width: 350 }}
                <IonNote>
                  {} @ $
                  {Number(item?.eachAmount || 0).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </IonNote>
              ) : (
                <IonNote>
                  FV @ $
                  {Number(item?.eachAmount || 0).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{' '}
                </IonNote>
              )}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size={'8'}>
              <div style={{ width: '100%', overflow: 'hidden', height: '30px', maxHeight: '30px', lineHeight: '.5em' }}>
                {item?.model?.measure === 'qty' ? (
                  <QuantitySelector
                    quantity={item.quantity || 1}
                    increment={1}
                    allowDelete={true}
                    onChange={(newQty: number) => {
                      if (quantityChangeHandler) {
                        quantityChangeHandler(item, sortCols, newQty);
                      }
                    }}
                    onDelete={() => removeHandler && removeHandler(item)}
                  />
                ) : (
                  <>
                    {item?.model?.measure === 'fv' && (
                      <QuantitySelector
                        quantity={item?.faceValue ?? 0}
                        increment={0.05}
                        precision={2}
                        allowDelete={true}
                        onChange={(newQty: number) => {
                          if (quantityChangeHandler) {
                            // face value does not use quantity
                            quantityChangeHandler({ ...item, faceValue: newQty }, sortCols, 1);
                          }
                        }}
                        onDelete={() => removeHandler && removeHandler(item)}
                      />
                    )}
                  </>
                )}
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonNote>{item?.comments}</IonNote>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonItem>
    </IonItemSliding>
  );
};

export default ItemListItem;
