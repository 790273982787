import { IonButton, IonCol, IonIcon, IonImg, IonRow } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { CoinGradeValue, CoinModel, CoinType, Options } from '../../models/types';
import { connect } from '../../data/connect';
import { logOutOutline } from 'ionicons/icons';
import { useUrlFormatter } from '../../hooks/useUrlFormatter';

const { REACT_APP_GS_BASE_ITEM_URL, REACT_APP_PCGS_BASE_URL, REACT_APP_PCGS_AUCTIONS_URL, REACT_APP_GC_SEARCH_URL } =
  process.env;

interface OwnProps {
  value?: CoinGradeValue;
  model?: CoinModel;
}

interface StateProps {
  userToken: string;
  selectedModel?: CoinModel;
  selectedType?: CoinType;
  options?: Options;
}

interface ValuesListProps extends OwnProps, StateProps {}

const ValuesLinks: React.FC<ValuesListProps> = ({ value, options, model }) => {
  const { formatGSUrl, formatPCGSUrl, formatPCGSAuctionsUrl, formatGCSearchUrl } = useUrlFormatter();

  const [gsLink, setGSLink] = useState<string>();
  const [pcgsLink, setPcgsLink] = useState<string>();
  const [gcSearchLink, setGCSearchLink] = useState<string>();
  const [pcgsAuctionsLink, setPcgsAuctionsLink] = useState<string>();
  const [columnWidth, setColumnWidth] = useState<number>(1);

  // initialize value
  useEffect(() => {
    console.debug(`updating urls...`, model);
    if (value && value.grade && value.modelId && options?.grade) {
      setGSLink(
        formatGSUrl(
          REACT_APP_GS_BASE_ITEM_URL,
          model,
          options?.grade.find((g) => g.code === value?.grade),
        ),
      );

      setPcgsLink(formatPCGSUrl(REACT_APP_PCGS_BASE_URL, model));

      setPcgsAuctionsLink(formatPCGSAuctionsUrl(REACT_APP_PCGS_AUCTIONS_URL, model));

      setGCSearchLink(
        formatGCSearchUrl(
          REACT_APP_GC_SEARCH_URL,
          model,
          options?.grade.find((g) => g.code === value?.grade),
        ),
      );
    } else {
      setGSLink('');
      setPcgsLink('');
      setPcgsAuctionsLink('');
      setGCSearchLink('');
    }

    // eslint-disable-next-line
  }, [value, model, options?.grade]);

  useEffect(() => {
    // calculate column width based upon the number of links we have for this item
    const n = [gsLink, pcgsLink, gcSearchLink, pcgsAuctionsLink].filter((link) => link && link.length > 0);
    console.debug(`there are ${n?.length} non-blank columns...`, n);
    if (n.length) {
      setColumnWidth(12 / n.length);
    } else {
      setColumnWidth(0);
    }
    // eslint-disable-next-line
  }, [gsLink, pcgsLink, pcgsAuctionsLink, gcSearchLink]);

  return (
    <>
      <IonRow class={'ion-align-items-center ion-text-center'}>
        {pcgsLink && pcgsLink.length > 0 && columnWidth && columnWidth > 0 && (
          <IonCol class={'ion-align-items-stretch ion-text-center'} sizeXs={'6'} sizeSm={`${columnWidth}`}>
            <IonButton href={pcgsLink} target={'_blank'} fill={'outline'} expand={'block'}>
              <IonIcon slot={'start'} icon={logOutOutline} />
              <IonImg src={'./assets/icon/coin.svg'} style={{ height: '32px' }} />
              &nbsp;Coin Facts
            </IonButton>
          </IonCol>
        )}
        {pcgsAuctionsLink && pcgsAuctionsLink.length > 0 && columnWidth && columnWidth > 0 && (
          <IonCol class={'ion-align-items-stretch ion-text-center'} sizeXs={'6'} sizeSm={`${columnWidth}`}>
            <IonButton href={pcgsAuctionsLink} target={'_blank'} fill={'outline'} expand={'block'}>
              <IonIcon slot={'start'} icon={logOutOutline} />
              <IonImg src={'./assets/img/pcgs-logo.png'} style={{ height: '32px' }} />
              &nbsp;Sales
            </IonButton>
          </IonCol>
        )}
        {gcSearchLink && gcSearchLink.length > 0 && columnWidth && columnWidth > 0 && (
          <IonCol class={'ion-align-items-stretch ion-text-center'} sizeXs={'6'} sizeSm={`${columnWidth}`}>
            <IonButton href={gcSearchLink} target={'_blank'} fill={'outline'} expand={'block'}>
              <IonIcon slot={'start'} icon={logOutOutline} />
              <IonImg src={'./assets/img/greatcollections-logo.jpg'} style={{ height: '32px' }} />
            </IonButton>
          </IonCol>
        )}
        {gsLink && gsLink.length > 0 && columnWidth && columnWidth > 0 && (
          <IonCol class={'ion-align-items-stretch ion-text-center'} sizeXs={'6'} sizeSm={`${columnWidth}`}>
            <IonButton href={gsLink} target={'_blank'} fill={'outline'} expand={'block'}>
              <IonIcon slot={'start'} icon={logOutOutline} />
              <IonImg src={'./assets/img/greysheet-logo-color-2020.svg'} style={{ height: '32px' }} />
            </IonButton>
          </IonCol>
        )}
      </IonRow>
    </>
  );
};

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state, ownProps: OwnProps) => ({
    userToken: state.user.userToken,
    value: ownProps.value,
    selectedModel: state.data.selectedModel,
    selectedType: state.data.selectedType,
    options: state.data.options,
  }),
  mapDispatchToProps: {},
  component: ValuesLinks,
});
