import React from 'react';
import { IonAvatar, IonItem, IonLabel } from '@ionic/react';
import { CoinType } from '../models/types';

interface CoinTypeCardProps {
  onSelect?: (type: CoinType) => void;
  type: CoinType;
}

/**
 * Provides a form to search
 * @param coinInfo information already gathered about a coin that will be bid on
 * @param onSelect callback when user chooses an item
 * @constructor
 */
const TypeItem: React.FC<CoinTypeCardProps> = ({ type, onSelect }) => {
  return (
    <IonItem key={type.id} detail={false} routerLink={`/tabs/catalog/${type.id}`} onClick={() => onSelect}>
      <IonAvatar slot='start'>
        <img
          // if obverse thumbnail is not specified then use thumbnail by type id
          src={type.obverseThumbnail ? type.obverseThumbnail : `./assets/img/catalog/types/${type.id}_o_thumbnail.png`}
          alt={`${type?.id}`}
          aria-hidden={true}
        />
      </IonAvatar>
      <IonLabel>
        {type.name}
        {type.startYear && (
          <p>
            ({type.startYear}-{type.endYear})
          </p>
        )}
      </IonLabel>
      <IonLabel slot={'end'}>
        <p>{type.denomination?.code}</p>
      </IonLabel>
    </IonItem>
  );
};

export default TypeItem;
