import React from 'react';
import {
  IonAvatar,
  IonBreadcrumb,
  IonBreadcrumbs,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonPage,
  IonRow,
} from '@ionic/react';
import { connect } from '../data/connect';
import CommonHeader from '../components/layout/CommonHeader';
import LogoutButton from '../components/layout/LogoutButton';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { person } from 'ionicons/icons';
import { trpc } from '../util/trpc';

interface OwnProps {}

interface StateProps {
  username?: string;
  isAdmin?: boolean;
}

interface DispatchProps {}

interface ProfileTabProps extends OwnProps, StateProps, DispatchProps {}

const ProfileTab: React.FC<ProfileTabProps> = () => {
  const valueSheets = trpc.valueSheets.getAllValueSheets.useQuery({
    // cursor: 0,
    // limit: 0,
  });

  const { user } = useAuth0();

  return (
    <IonPage>
      <CommonHeader
        pageTitle={
          <IonBreadcrumbs class={'ion-float-start'}>
            <IonBreadcrumb>
              <IonIcon icon={person} slot={'start'} />
              Profile
            </IonBreadcrumb>
          </IonBreadcrumbs>
        }
        endTools={
          <>
            <LogoutButton />
          </>
        }
        showDate={true}
      />
      <IonContent>
        {user && (
          <>
            <IonItem>
              <IonAvatar slot={'start'}>
                <img src={user?.picture || './assets/img/avatar.svg'} alt='Dealer Profile' />
                {/*{user?.name}*/}
              </IonAvatar>
              {user?.name} (Role: {JSON.stringify(user).indexOf('Admin') ? 'Admin' : 'Dealer'})
            </IonItem>
            {/*<p>{JSON.stringify(user)}</p>*/}
          </>
        )}
        <IonGrid>
          <IonRow>
            <IonCol sizeXs={'12'} sizeMd={'6'}>
              <IonList>
                <IonItem routerLink={'/tabs/selectedItems'} detail={true}>
                  {/*<IonIcon slot={'start'} icon={''} />*/}
                  <IonLabel>My Worksheets</IonLabel>
                  {valueSheets.isFetched && <IonNote slot='end'>{valueSheets.data?.data.length}</IonNote>}
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default withAuthenticationRequired(
  connect({
    mapStateToProps: (state) => ({
      username: state.user.username,
      isAdmin: state.user.isAdmin,
      options: state.data.options,
      selectedItems: state.item.selectedItems,
      selectedTotal: state.item.selectedTotal,
      coinModels: state.data.coinModels,
      coinTypes: state.data.coinTypes,
      coinValues: state.data.coinValues,
      selectedItemGroups: state.item.selectedItemGroups,
    }),
    mapDispatchToProps: {},
    component: ProfileTab,
  }),
);
