import React, { ReactNode, useEffect } from 'react';
import {
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonMenuToggle,
  IonProgressBar,
  IonSearchbar,
  IonToolbar,
} from '@ionic/react';
import { connect } from '../../data/connect';
import './CommonHeader.scss';
import { CoinModel, CoinType, CoinValueItem } from '../../models/types';
import { hideMenu, showMenu } from '../../data/item/item.actions';
import { useSearch } from '../../hooks/useSearch';
import { albumsOutline, analyticsOutline, desktopOutline, peopleOutline, person } from 'ionicons/icons';
import { setMenuEnabled } from '../../data/sessions/sessions.actions';
import { Link, useLocation } from 'react-router-dom';
import ItemSelectionBadge from '../items/ItemSelectionBadge';
import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from './LoginButton';
const { REACT_APP_RTT_APP_ROOT_URL, REACT_APP_NFUSION_BANNER_SCRIPT_SRC } = process.env;

interface HeaderLink {
  title: string;
  path?: string;
  iconSource?: string; // .svg image
  icon?: any; // used when .svg image is not provided
  badge?: ReactNode;
  link?: string;
}

interface OwnProps {
  pageTitle?: any;
  children?: any;
  showDate?: boolean;
  startTools?: any;
  endTools?: any;
}

interface StateProps {
  isStale?: boolean;
  loadDateTime?: Date;
  selectedItems?: CoinValueItem[];
  isMenuVisible?: boolean;
  menuEnabled?: boolean;
  searchText?: string;
  coinTypes: CoinType[];
  coinModels: CoinModel[];
  isLoading: boolean;
  selectedSheetId: number;
}

interface DispatchProps {
  hideMenu: typeof hideMenu;
  showMenu: typeof showMenu;
  setMenuEnabled: typeof setMenuEnabled;
}

interface CommonHeaderProps extends OwnProps, StateProps, DispatchProps {}

/**
 * Provides a reusable search input box that can route to a given page
 * @param pageTitle
 * @param children
 * @param startTools
 * @param endTools
 * @param isStale
 * @param selectedItems
 * @param isMenuVisible
 * @param hideMenu
 * @param showMenu
 * @constructor
 */
const CommonHeader: React.FC<CommonHeaderProps> = ({
  pageTitle,
  children,
  startTools,
  endTools,
  selectedItems,
  isMenuVisible,
  hideMenu,
  showMenu,
  setMenuEnabled,
  isLoading,
  selectedSheetId,
}) => {
  const { openSearch } = useSearch();
  const location = useLocation();
  const { isAuthenticated, user } = useAuth0();

  // inserts the nfusion script code to show ticket banner
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `${REACT_APP_NFUSION_BANNER_SCRIPT_SRC}`;
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <IonHeader class={'common-header ion-no-border'} translucent={true}>
      {/*header always on top*/}

      <IonToolbar style={{ height: '6em' }}>
        <Link to={'/tabs/home'} slot={'start'} onClick={() => hideMenu()}>
          <img
            src={'assets/img/CoinBidz_logo_small.png'}
            alt='CoinBidz'
            style={{
              padding: '2em',
              width: '200px',
            }}
          />
        </Link>
        {isAuthenticated && (
          <IonButtons slot={'primary'} class={'ion-hide-md-down'}>
            {startTools}
            {[
              { title: 'Dealers', path: '/tabs/dealers', icon: peopleOutline },
              { title: 'Catalog', path: '/tabs/catalog', icon: analyticsOutline },
              { title: 'Generics & Bullion', path: '/tabs/genericBids', iconSource: `./assets/icon/coins.svg` },
              {
                title: 'Worksheet',
                path: `/tabs/selectedItems/${selectedSheetId}`,
                icon: albumsOutline,
                badge: (
                  <IonBadge slot={'end'} style={{ marginLeft: '0.5em' }} color={'danger'}>
                    {selectedItems?.length}
                  </IonBadge>
                ),
              },
              { title: 'RoundTable App', link: `${REACT_APP_RTT_APP_ROOT_URL}`, icon: desktopOutline },
              { title: `${user ? user.name : 'My CB'}`, path: '/tabs/profileTab', icon: person },
            ].map((p: HeaderLink) => (
              <IonButton
                {...p}
                key={p?.path}
                routerLink={p?.path}
                href={p?.link}
                className={location.pathname.startsWith(p.path ?? 'http') ? 'selected' : ''}
                disabled={!isAuthenticated}
                onClick={() => hideMenu()}
              >
                {p?.badge}
                {p.iconSource && <IonIcon slot='start' src={p.iconSource} className={'ion-hide-lg-down'} />}
                {!p.iconSource && <IonIcon slot='start' icon={p.icon} className={'ion-hide-lg-down'} />}
                {p?.title}
              </IonButton>
            ))}
          </IonButtons>
        )}
        <IonButtons slot={'primary'}>
          <LoginButton />
        </IonButtons>

        {isAuthenticated && (
          <IonButtons slot='primary' class={'ion-hide-md-up'}>
            <IonMenuToggle
              auto-hide={false}
              menu={'pricingMenu'}
              onClick={() => (isMenuVisible ? hideMenu() : showMenu())}
            >
              {
                <IonButton>
                  <IonIcon icon={albumsOutline} slot={'icon-only'} />
                  <ItemSelectionBadge count={selectedItems?.length} />
                </IonButton>
              }
            </IonMenuToggle>
            <IonMenuButton
              autoHide={false}
              onClick={() => {
                setMenuEnabled(true);
              }}
            />
          </IonButtons>
        )}
      </IonToolbar>

      <div
        id='810183ae-7fd2-4de8-af66-60dbb7110912'
        style={{
          width: '100%',
          lineHeight: '25px',
          padding: '10px',
          backgroundColor: 'mediumaquamarine',
          color: 'white',
        }}
      >
        Placeholder for nFusion spot price ticker when using roundtabletrading domain.
      </div>

      <IonToolbar color={'primary'}>
        <IonSearchbar
          animated={false}
          placeholder='Search coins and values'
          onClick={() => openSearch('')}
          mode='ios'
          color={'light'}
          class={'ion-margin-top'}
        />
      </IonToolbar>

      {endTools && (
        <IonToolbar>
          <IonButtons slot={'start'} class={'ion-hide-sm-up'}>
            <IonBackButton />
          </IonButtons>
          {pageTitle}
          <div className={'ion-padding-horizontal'} slot={'end'}>
            {endTools}
          </div>
        </IonToolbar>
      )}

      {/*other items that need to go in the header*/}
      {children && <IonToolbar>{children}</IonToolbar>}

      {isLoading && <IonProgressBar type='indeterminate' />}
    </IonHeader>
  );
};

export default connect<OwnProps, StateProps>({
  mapStateToProps: (state, ownProps) => ({
    children: ownProps.children,
    isStale: state.data.isStale,
    loadDateTime: state.data.loadDateTime,
    selectedItems: state.item.selectedItems,
    isMenuVisible: state.item.isMenuVisible,
    menuEnabled: state.data.menuEnabled,
    searchText: state.data.searchText,
    coinTypes: state.data.coinTypes,
    coinModels: state.data?.coinModels,
    isLoading: state.data.isLoading,
    coinValues: state.data?.coinValues,
    selectedSheetId: state.item.selectedSheetId,
  }),
  mapDispatchToProps: {
    hideMenu,
    showMenu,
    setMenuEnabled,
  },
  component: React.memo(CommonHeader),
});
