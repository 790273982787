import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import {
  IonBreadcrumb,
  IonBreadcrumbs,
  IonContent,
  IonPage,
  useIonModal,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from '@ionic/react';
import { connect } from '../data/connect';
import { BidSheet, CoinModel, CoinType, Grade, Options } from '../models/types';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { loadBidSheetValueData, setSelectedModel } from '../data/sessions/sessions.actions';
import Refresher from '../components/Refresher';
import ValueGrid from '../components/values/ValueGrid';
import { useExport } from '../hooks/useExport';
import PopoverMenuButton from '../components/common/PopoverMenuButton';
import CommonHeader from '../components/layout/CommonHeader';
import { codeDownloadOutline, downloadOutline, lockOpenOutline } from 'ionicons/icons';
import ValuesPdf from '../components/values/ValuesPdf';

interface OwnProps extends RouteComponentProps {
  bidSheet: BidSheet;
  options: Options;
  isAdmin: boolean;
}

interface StateProps {
  selectedModel: CoinModel;
  selectedBidSheet: BidSheet;
  bidSheetId: number;
  loading: boolean;
  bidSheets: BidSheet[];
  coinTypes: CoinType[];
}

interface DispatchProps {
  loadBidSheetValueData: typeof loadBidSheetValueData;
  setSelectedModel: typeof setSelectedModel;
}

interface GenericBidProps extends OwnProps, StateProps, DispatchProps {}

const BidSheetDetail: React.FC<GenericBidProps> = ({
  options,
  selectedBidSheet,
  bidSheetId,
  loadBidSheetValueData,
  bidSheets,
  coinTypes,
  isAdmin,
}) => {
  const [activeGrades, setActiveGrades] = useState<Grade[]>();
  const contentRef = useRef<any>(null);
  const { generateCSV, gradesWithValues } = useExport();

  useIonViewWillEnter(() => {
    console.log('ionViewWillEnter event fired');
    setActiveGrades(undefined);
  }, []);

  useIonViewDidEnter(() => {
    console.log('ionViewDidEnter event fired');
    if (bidSheetId) {
      //  && options?.grade?.length
      console.debug(`loading bid sheet ${bidSheetId}...`);
      loadBidSheetValueData(bidSheets, coinTypes, bidSheetId);
    }
  }, [bidSheetId, activeGrades]);

  useEffect(() => {
    if (bidSheetId) {
      //  && options?.grade?.length
      console.debug(`loading bid sheet ${bidSheetId}...`);
      loadBidSheetValueData(bidSheets, coinTypes, bidSheetId);
    }
    // eslint-disable-next-line
  }, [bidSheetId]);

  /**
   * Find grades for the selected type
   */
  useEffect(() => {
    if (selectedBidSheet && selectedBidSheet?.formattedModels?.length && selectedBidSheet?.grades && options?.grade) {
      console.debug(`selectedBidSheet.grades are `, selectedBidSheet?.grades);
      // const grades = selectedBidSheet?.grades.length ? selectedBidSheet?.grades : options?.grade;

      const grades = selectedBidSheet?.grades.length
        ? selectedBidSheet?.grades
        : gradesWithValues(selectedBidSheet?.models, options.grade);

      setActiveGrades(grades);
    }
    // eslint-disable-next-line
  }, [selectedBidSheet, options?.grade]);

  /**
   * First parameter is the component to show, second is the props to pass
   */
  const [present, dismiss] = useIonModal(ValuesPdf, {
    title: selectedBidSheet?.name,
    models: selectedBidSheet?.formattedModels,
    grades: activeGrades,
    onDismiss: () => {
      dismiss();
    },
  });

  return (
    <IonPage>
      <CommonHeader
        pageTitle={
          <>
            <IonBreadcrumbs slot={'start'}>
              <IonBreadcrumb routerLink={'/tabs/genericBids'} className={'ion-hide-sm-down'}>
                Generics &amp; Bullion
              </IonBreadcrumb>
              {selectedBidSheet && <IonBreadcrumb>{selectedBidSheet?.name}</IonBreadcrumb>}
            </IonBreadcrumbs>
          </>
        }
        startTools={<>{/*<IonMenuButton />*/}</>}
        endTools={
          <PopoverMenuButton
            types={(selectedBidSheet
              ? [
                  {
                    label: 'Download PDF',
                    icon: downloadOutline,
                    onSelect: () => present({ cssClass: 'rounded-modal', backdropDismiss: true }),
                  },
                ]
              : []
            ).concat(
              isAdmin
                ? [
                    {
                      label: 'Download Excel',
                      icon: codeDownloadOutline,
                      onSelect: () => {
                        return generateCSV(selectedBidSheet?.name, selectedBidSheet?.formattedModels, activeGrades);
                      },
                    },
                    {
                      label: 'Edit in Retool',
                      icon: lockOpenOutline,
                      onSelect: () => {
                        window.open(`${process.env.REACT_APP_ADMIN_BID_SHEETS}${selectedBidSheet?.id}`, '_blank');
                      },
                    },
                  ]
                : [],
            )}
          />
        }
        showDate={true}
      />
      <IonContent scrollEvents={true} ref={contentRef} scrollX={true}>
        <>
          <Refresher />
          {selectedBidSheet?.formattedModels && activeGrades && (
            <ValueGrid
              models={selectedBidSheet?.formattedModels}
              visibleGrades={activeGrades || options.grade}
              showTypeImage={false}
              options={options}
            />
          )}
        </>
      </IonContent>
    </IonPage>
  );
};

export default withAuthenticationRequired(
  connect({
    mapStateToProps: (state, ownProps) => ({
      bidSheetId: ownProps?.match.params['bidSheetId'],
      selectedBidSheet: state.data.selectedBidSheet,
      options: state.data.options,
      selectedModel: state.data.selectedModel,
      isAdmin: state.user.isAdmin,
      userToken: state.user.userToken,
      bidSheets: state.data.bidSheets,
      coinTypes: state.data.coinTypes,
    }),
    mapDispatchToProps: {
      loadBidSheetValueData,
      setSelectedModel,
    },
    component: BidSheetDetail,
  }),
);
