import React from 'react';
import {
  IonPage,
  IonContent,
  IonFooter,
  IonRow,
  IonCol,
  IonText,
  IonGrid,
  IonButton,
  IonCardHeader,
  IonCard,
  IonCardTitle,
  IonCardSubtitle,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
} from '@ionic/react';
import { closeOutline, pricetagOutline, pricetagsOutline } from 'ionicons/icons';

interface ExportFormProps {
  onDismiss: () => void;
  onGroupedPdf?: () => void;
  onGroupedCsv: () => void;
  onFlatCsv?: () => void;
}

/**
 * Provides a form to view/add/edit a bid
 * @catalog contains category, type, model for selection
 * @constructor
 */
const ExportForm: React.FC<ExportFormProps> = ({ onDismiss, onGroupedCsv, onFlatCsv }) => {
  return (
    <IonPage>
      <IonHeader translucent={false}>
        <IonToolbar color={'primary'}>
          <IonTitle class={'ion-text-center'}>
            <IonText>Export</IonText>
          </IonTitle>
          <IonButtons slot={'end'}>
            <IonButton onClick={() => onDismiss()}>
              <IonIcon slot={'icon-only'} icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent class={'ion-padding'}>
        <IonGrid>
          <IonRow>
            <IonCol class={'ion-align-items-center'} sizeXs={'12'} sizeSm={'6'}>
              <IonCard>
                {/*<img*/}
                {/*  style={{ width: '100%', height: '10em', objectFit: 'cover' }}*/}
                {/*  alt='Silhouette of mountains'*/}
                {/*  src='./assets/img/customer-offer.jpeg'*/}
                {/*/>*/}
                <IonCardHeader>
                  <IonCardTitle>Summary Listing</IonCardTitle>
                  <IonCardSubtitle>Combines like items indicating quantity</IonCardSubtitle>
                </IonCardHeader>
                {/*<IonCardContent style={{ height: '7em' }} class={'ion-hide-sm-down'}>*/}
                {/*  Combines like items indicating quantity.*/}
                {/*</IonCardContent>*/}
                <IonFooter class={'ion-padding ion-align-items-center'}>
                  {/*<IonButton disabled={true} onClick={() => onGroupedPdf && onGroupedPdf()}>*/}
                  {/*  <IonIcon icon={pricetagsOutline} slot={'start'} />*/}
                  {/*  <IonText>PDF</IonText>*/}
                  {/*</IonButton>*/}
                  <IonButton color={'primary'} onClick={() => onGroupedCsv && onGroupedCsv()}>
                    <IonIcon icon={pricetagsOutline} slot={'start'} />
                    <IonText>Excel (CSV)</IonText>
                  </IonButton>
                </IonFooter>
              </IonCard>
            </IonCol>
            <IonCol class={'ion-align-items-center'} sizeXs={'12'} sizeSm={'6'}>
              <IonCard>
                {/*<img*/}
                {/*  style={{ width: '100%', height: '10em', objectFit: 'cover' }}*/}
                {/*  alt='Silhouette of mountains'*/}
                {/*  src='./assets/img/inventory.jpeg'*/}
                {/*/>*/}
                <IonCardHeader>
                  <IonCardTitle>Detail Listing</IonCardTitle>
                  <IonCardSubtitle>Breaks item quantities into multiple lines</IonCardSubtitle>
                </IonCardHeader>
                {/*<IonCardContent style={{ height: '7em' }} class={'ion-hide-sm-down'}>*/}
                {/*  <IonText>Detailed sheet shows each item on a single line instead of grouping.</IonText>*/}
                {/*</IonCardContent>*/}
                <IonFooter class={'ion-padding ion-align-self-center'}>
                  <IonButton onClick={() => onFlatCsv && onFlatCsv()}>
                    <IonIcon icon={pricetagOutline} slot={'start'} />
                    <IonText>Excel (csv)</IonText>
                  </IonButton>
                </IonFooter>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ExportForm;
