import React, { useEffect, useRef, useState } from 'react';
import { CoinGradeValue, CoinModel, Grade, Options } from '../../models/types';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useExport } from '../../hooks/useExport';
import { IonAvatar, useIonModal } from '@ionic/react';
import PricingFeedbackForm from '../PricingFeedbackForm';
import { ValuesChartDemo } from './ValuesChartDemo';
import ValueAndBadge from './ValueAndBadge';

interface CoinValueGridProps {
  models: CoinModel[];
  visibleGrades: Grade[];
  highlightedModel?: CoinModel;
  showTypeImage: boolean;
  options: Options;
}

/**
 * Provides a grid to display values based on models
 * @param dealer the record to show
 * @constructor
 */
const ValueGrid: React.FC<CoinValueGridProps> = ({
  visibleGrades,
  models,
  highlightedModel,
  showTypeImage,
  options,
}) => {
  const [dynamicColumns, setDynamicColumns] = useState<any>();
  const { standardizeModelValues } = useExport();
  const [formattedModels, setFormattedModels] = useState<CoinModel[]>();
  const [gradesToDisplay, setGradesToDisplay] = useState<Grade[]>();
  const [expandedRow, setExpandedRow] = useState<any>([]);
  const [selectedCell, setSelectedCell] = useState<any>();
  const [selectedCoinGradeValue, setSelectedCoinGradeValue] = useState<CoinGradeValue | undefined>();
  const tableRef = useRef<any>();

  /**
   * First parameter is the component to show, second is the props to pass
   */
  const [present, dismiss] = useIonModal(PricingFeedbackForm, {
    coinGradeValue: selectedCoinGradeValue, // sometimes empty
    grade: selectedCell?.field,
    coinModel: selectedCell?.rowData,
    coinType: selectedCell?.rowData?.coinType,
    measure: selectedCell?.rowData?.measure,
    options: options,
    onDismiss: () => {
      dismiss();
    },
  });

  /**
   * Update gradesToDisplay so each column has at least one value
   */
  useEffect(() => {
    if (visibleGrades?.length > 0 && models) {
      setSelectedCell(null);
      tableRef?.current?.resetScroll();
      tableRef?.current?.reset();

      // show all grades as indicated
      setGradesToDisplay(visibleGrades);

      const formatted = standardizeModelValues(models || [], visibleGrades);
      setFormattedModels(formatted);
    }
    // eslint-disable-next-line
  }, [models, visibleGrades]);

  /**
   * Adjust grid columns based on which grades to display.
   */
  useEffect(() => {
    if (gradesToDisplay?.length) {
      const newCols = gradesToDisplay?.map((g: Grade) => {
        return (
          <Column
            key={g.code}
            field={g.code}
            body={(model: CoinModel, data: ColumnBodyOptions) => {
              return <ValueAndBadge value={model.values?.find((v) => v.grade === data.field)} />;
            }}
            header={g.display}
            headerStyle={{ minWidth: '50px', maxWidth: '200px' }}
            align={'right'}
          />
        );
      });

      if (newCols) {
        setDynamicColumns(newCols);
      }
    }
  }, [gradesToDisplay]);

  /**
   * Find the corresponding published value.
   */
  useEffect(() => {
    console.debug('selectedCell is', selectedCell);

    if (selectedCell) {
      const model = selectedCell.rowData;
      if (model) {
        const cgv = model?.values?.find((modelValue: any) => modelValue.grade === selectedCell.field);
        if (cgv) setSelectedCoinGradeValue(cgv);
      }
    }
    // eslint-disable-next-line
  }, [selectedCell]);

  /**
   * Once the value has been identified we can show the popup
   */
  useEffect(() => {
    if (selectedCell && selectedCell.field !== 'name' && selectedCell.field !== 'sortBy') {
      // selected value not row
      present({ cssClass: 'rounded-modal pricing-feedback-modal', backdropDismiss: true });
    }
    // eslint-disable-next-line
  }, [selectedCell, selectedCoinGradeValue]);

  /**
   * If a highlight model was passed AND it exists in the models then highlight it.
   */
  useEffect(() => {
    if (highlightedModel) {
      // && models.indexOf(highlightedModel) !== -1
      console.debug(`TODO: highlight model ${highlightedModel?.name}`, highlightedModel);
      // tableRef?.current?.getVirtualScroller().scrollIntoView();
      // setSelectedRow(highlightedModel);
      // tableRef.current.scrollBody.scrollTop = 1000;
    }
  }, [highlightedModel, models]);

  const imageBodyTemplate = (model: CoinModel) => {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <IonAvatar class={'ion-no-padding ion-no-margin'}>
          {model.typeId && (
            <img
              style={{ height: '4em', width: '4em' }}
              src={
                model.coinType?.obverseThumbnail
                  ? model.coinType.obverseThumbnail
                  : `./assets/img/catalog/types/${model.typeId}_o_thumbnail.png`
              }
              alt={`${model.typeId}`}
              aria-hidden={true}
            />
          )}
        </IonAvatar>
      </div>
    );
  };

  const rowExpansionTemplate = (data: any) => {
    // get the source values for all grades
    console.debug(`TODO: Get source values for row with data:`, data);
    return (
      <div style={{ marginLeft: '150px' }}>
        <ValuesChartDemo />
      </div>
    );
  };

  const headerTemplate = (data: CoinModel) => {
    console.debug(`header and data is: `, data);
    return (
      <div className='flex align-items-center gap-2'>
        {/*<img alt={data.name} src={`https://primefaces.org/cdn/primereact/images/avatar/${data.image}`} width="32" />*/}
        <span className='font-bold'>Category {data.coinType?.category?.name}</span>
      </div>
    );
  };

  return (
    <>
      {models?.length && visibleGrades?.length && (
        <DataTable
          ref={tableRef as any}
          value={formattedModels}
          reorderableColumns={false}
          className={'p-datatable-striped p-datatable-gridlines p-datatable-sm'}
          resizableColumns
          columnResizeMode='expand'
          frozenWidth='200px'
          scrollable
          scrollHeight='flex'
          selectionMode='single'
          dataKey='id'
          // sortField='sortOrder'
          // sortField='coinType.categoryId'
          sortMode='single'
          sortOrder={1}
          virtualScrollerOptions={{ itemSize: 46 }}
          cellSelection
          selection={selectedCell}
          onCellClick={(e) => {
            console.debug(`selected cell - e is:`, e);
            setSelectedCell(e);
            // setSelectedRow(e.rowData);
          }}
          // selection={selectedRow}
          rowExpansionTemplate={rowExpansionTemplate}
          expandedRows={expandedRow}
          onRowToggle={(e: any) => {
            console.debug(`onRowToggle e is:`, e);
            setExpandedRow(e.data);
            // console.debug(`onRowToggle selectedRow is:`, selectedRow);
          }}
          onRowExpand={(e) => {
            console.debug(`onRowExpand`, e.data);
            // setSelectedRow(e.data);
          }}
          // TODO: to enable grouping uncomment these lines and set sortField to match
          // rowGroupMode='subheader'
          // groupRowsBy='coinType.categoryId'
          rowGroupHeaderTemplate={headerTemplate}
        >
          <Column
            body={imageBodyTemplate}
            style={{ width: '50px' }}
            hidden={!showTypeImage}
            align={'center'}
            frozen={true}
          />
          <Column
            field='name'
            header='Issue'
            frozen={true}
            sortable={true}
            headerStyle={{ width: '150px' }}
            style={{ width: '150px' }}
            filter={true}
            filterField={'name'}
            filterType={'menu'}
          />
          {/*Hiding expansion for now*/}
          {/*<Column expander field='sortBy' style={{ width: '40px' }} frozen={true} />*/}
          {/*remaining columns created dynamically once values are loaded*/}
          {dynamicColumns}
        </DataTable>
      )}
    </>
  );
};

export default ValueGrid;
