import {
  getBidSheetData,
  getCoinCategoryData,
  getCoinModelData,
  getCoinTypeData,
  getCoinValueData,
  getDealerData,
  getOptionData,
  getTokenData,
  listBidSheetData,
  listCoinTypeData,
} from '../dataApi';
import { ActionType } from '../../util/types';
import { ConfState } from './conf.state';
import {
  BidSheet,
  CoinCategory,
  CoinGradeValue,
  CoinModel,
  CoinType,
  Dealer,
  Mintmark,
  Options,
} from '../../models/types';
import * as _ from 'lodash';

export const loadDealerData =
  () =>
  async (dispatch: React.Dispatch<any>): Promise<void> => {
    dispatch(setLoading(true));
    let data = { dealers: [] as Dealer[] };
    await getDealerData().then((dealers: Dealer[] | void) => {
      if (dealers) data = { dealers };
    });
    dispatch(setData(data));
    dispatch(setLoading(false));
  };

export const loadCoinCategoryData =
  () =>
  async (dispatch: React.Dispatch<any>): Promise<void> => {
    dispatch(setLoading(true));
    const coinCategories = await getCoinCategoryData();

    dispatch(setData({ coinCategories: coinCategories }));
    dispatch(setLoading(false));
  };

/**
 * Client side massaging of data for display
 * @param models
 */
const formatModelValues = (models: CoinModel[]): CoinModel[] => {
  // filter value changes
  console.debug(`formatting ${models.length} models...`, models);
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 7);
  const formattedModels = models?.map((m: CoinModel) => ({
    ...m,
    values: m.values
      ?.filter((v: CoinGradeValue) => v.value > 0.99)
      .map((v: CoinGradeValue) => ({
        ...v,
        formattedValue: `${Number(v.value).toLocaleString('en-US', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })}`,
        // overwrite valueChange based on display rule
        valueChange:
          v.valueChange !== v.value && // new value
          Math.abs(v.valueChange) >= 1 && // changed by at least a dollar
          Math.abs(v.valueChange) >= v.value * 0.01 && // changed by 1%
          new Date(v.updatedAt.iso) > startDate // changed since the start date (ex. 7 days)
            ? v.valueChange
            : 0,
        formattedUpdatedAt: `${new Date(v.updatedAt.iso).toLocaleDateString()} @ ${new Date(
          v.updatedAt.iso,
        ).toLocaleTimeString()}`,
      })),
  }));
  return _.sortBy(formattedModels, ['coinType.sortOrder', 'coinType.id', 'sortOrder', 'id']);
};

/**
 * Loads coin type detail with values and formats it
 * @param typeId
 * @param grades
 */
export const loadCoinTypeData =
  (token: string, typeId: number | undefined, options: Options) =>
  async (dispatch: React.Dispatch<any>): Promise<void> => {
    dispatch(setLoading(true));

    if (typeId) {
      const coinType = await getCoinTypeData(token, typeId);

      const modelsWType =
        coinType?.models?.map((m) => ({
          ...m,
          coinType: {
            ...coinType,
            denomination: options.denomination.find((d) => d.id === coinType?.denominationId),
          },
          mintmark: options.mintmark?.find((mmOption: Mintmark) => mmOption.id === m.mintmarkId),
        })) ?? [];

      const formattedModels = formatModelValues(modelsWType);

      console.debug(`${formattedModels?.length} formatted models are`, formattedModels);

      // const activeGrades = _.filter(gradesWithValues(formattedModels, grades?.length ? grades : []));

      dispatch(
        setData({
          selectedCategory: coinType?.category,
          selectedType: {
            ...coinType,
            models: formattedModels || [],
            grades: coinType?.visibleGrades?.length ? JSON.parse(coinType?.visibleGrades || '') : [],
          } as CoinType,
          loadDateTime: new Date(),
          // activeGrades,
        }),
      );
    } else {
      dispatch(
        setData({
          selectedType: { id: -1 } as CoinType,
          activeGrades: options.grade,
        }),
      );
    }
    dispatch(setLoading(false));
  };

// /**
//  * Reduces grades based on which values are in the passed model population
//  * @param models
//  * @param grades
//  */
// const gradesWithValues = (models: CoinModel[], grades: Grade[]): Grade[] => {
//   if (!models?.length) return [];
//
//   const allValues = models
//     .map((m: CoinModel) => m.values || [])
//     .reduce((acc: CoinGradeValue[], current: CoinGradeValue[]) => {
//       return acc.concat(current);
//     });
//
//   return _.filter(grades, (g: Grade) => {
//     return _.some(allValues, (cv: CoinGradeValue) => cv.grade === g.code && cv.value !== 0 && cv.value !== -1);
//   });
// };

/**
 * Loads coin type detail with values and formats it
 *
 * @param bidSheetId
 */
export const loadBidSheetValueData =
  (bidSheets: BidSheet[], coinTypes: CoinType[], bidSheetId: number) =>
  async (dispatch: React.Dispatch<any>): Promise<void> => {
    console.debug(`loadBidSheetValueData with id = ${bidSheetId}`);
    dispatch(setLoading(true));
    const bidSheet = await getBidSheetData(await getTokenData(), bidSheetId);

    console.debug(
      `bidSheet ${bidSheet?.name} (id=${bidSheet?.id}) found with ${bidSheet?.models?.length} models:`,
      bidSheet,
    );

    // find child bid sheets as well and add them in...
    const childBidSheets = bidSheets.filter((bs) => bs.parentBidSheetId === bidSheet.id);
    console.debug(`found ${childBidSheets.length} child bid sheets`, childBidSheets);

    console.debug(`modelsForFormatting:`, bidSheet?.models);

    const formattedModels = formatModelValues(
      bidSheet?.models.map((m) => ({
        ...m,
        coinType: coinTypes.find((ct) => m.typeId === ct.id),
      })),
    );

    console.debug(`formatted to ${formattedModels.length} models...`, formattedModels);

    const selectedBidSheet = {
      ...bidSheet,
      grades: bidSheet?.visibleGrades?.length ? JSON.parse(bidSheet?.visibleGrades || '') : [],
      formattedModels,
      childBidSheets,
    } as BidSheet;

    console.debug(`selectedBidSheet is...`, selectedBidSheet);

    dispatch(setSelectedBidSheet(selectedBidSheet));

    dispatch(setData({ loadDateTime: new Date() }));

    dispatch(setLoading(false));
  };

// export const loadOptionData =
//   () =>
//   async (dispatch: React.Dispatch<any>): Promise<void> => {
//     dispatch(setLoading(true));
//     const options = await getOptionData();
//
//     dispatch(
//       setData({
//         options: {
//           mintmark: _.filter(options.mintmark, { visible: 1 }) as Mintmark[],
//           denomination: _.filter(options.denomination, { visible: 1 }) as Denomination[],
//           strike: _.filter(options.strike, { visible: 1 }) as Strike[],
//           grade: _.filter(options.grade, { visible: 1 }) as Grade[],
//         },
//       }),
//     );
//     dispatch(setLoading(false));
//   };

/**
 * Loads all coin values with the intention of offline usage
 * @param token
 */
export const loadCoinValueData =
  (token: string) =>
  async (dispatch: React.Dispatch<any>): Promise<void> => {
    dispatch(setLoading(true));
    const values = await getCoinValueData(token);
    dispatch(setData({ coinValues: values }));
    dispatch(setLoading(false));
  };

/**
 * This is only needed when trying to load ALL the values
 * @param coinModels
 * @param coinValues
 *
 * TO use it example:
 * // mapValuesToModels(coinModels.filter((m) => m.typeId===76), coinValues),
 */
export const mapValuesToModels = (coinModels: CoinModel[], coinValues: CoinGradeValue[]): CoinModel[] => {
  console.debug(`Starting to map ${coinValues.length} values onto ${coinModels.length} models...`);
  console.time(`Mapping ${coinValues.length} values onto ${coinModels.length} models...`);
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 7);
  const values = _.chain(coinValues)
    .filter((v: CoinGradeValue) => v.value > 0.99 && coinModels.map((m: CoinModel) => m.id).indexOf(v.modelId) !== -1)
    .map((v) => ({
      ...v,
      formattedValue: `${Number(v.value).toLocaleString('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })}`,
      // overwrite valueChange based on display rule
      valueChange:
        Math.abs(v.valueChange) >= 1 && // changed by at least a dollar
        Math.abs(v.valueChange) >= v.value * 0.01 && // changed by 1%
        new Date(v.updatedAt.iso) > startDate // changed since the start date (ex. 7 days)
          ? v.valueChange
          : 0,
      formattedUpdatedAt: `${new Date(v.updatedAt.iso).toLocaleDateString()} @ ${new Date(
        v.updatedAt.iso,
      ).toLocaleTimeString()}`,
    }))
    .value(); // eslint-disable-line eqeqeq

  const mappedModels = coinModels.map((m: CoinModel) => ({
    ...m,
    values: _.filter(values, { modelId: m.id }),
    // coinType: coinType
  }));

  console.timeEnd(`Mapping ${coinValues.length} values onto ${coinModels.length} models...`);

  console.debug(`Resulting models with values are: `, mappedModels);

  return mappedModels;
};

/**
 * Loads data needed for menu, price sheet, bid sheets, and search. Excludes values.
 * @param token
 */
export const loadCoinCatalogData =
  (token: string) =>
  async (dispatch: React.Dispatch<any>): Promise<void> => {
    dispatch(setLoading(true));

    // needed for populating menu
    dispatch(
      setData({
        bidSheets: await listBidSheetData(token),
      }),
    );

    const options = await getOptionData();

    const types = (await listCoinTypeData(token)).map((ct) => ({
      ...ct,
      // CB flesh out denomination based on id
      denomination: options.denomination?.find((d) => d.id == ct.denominationId),
    }));

    // needed for catalog
    dispatch(
      setData({
        options: options,
        coinTypes: types,
      }),
    );

    console.debug(`setting coinModels using mintmark options:`, options.mintmark);

    const coinModels = (await getCoinModelData(token)).map((cm) => ({
      ...cm,
      // CB uses model.coinType with fleshed out category
      coinType: types.find((ct) => ct.id === cm.typeId),
      // TODO: switch to use type set from server side
      type: undefined,
      mintmark: options.mintmark?.find((mmOption: Mintmark) => mmOption.id === cm.mintmarkId),
    }));

    console.debug(`catalog models set to: `, coinModels);

    // all models are currently needed for site search
    dispatch(
      setData({
        coinModels: coinModels,
        isStale: false,
        loadDateTime: new Date(),
      }),
    );

    dispatch(setLoading(false));
  };

export const setLoading = (isLoading: boolean) =>
  ({
    type: 'set-conf-loading',
    isLoading,
  }) as const;

export const setData = (data: Partial<ConfState>) =>
  ({
    type: 'set-conf-data',
    data,
  }) as const;

export const addFavorite = (sessionId: number) =>
  ({
    type: 'add-favorite',
    sessionId,
  }) as const;

export const removeFavorite = (sessionId: number) =>
  ({
    type: 'remove-favorite',
    sessionId,
  }) as const;

export const updateFilteredTracks = (filteredTracks: string[]) =>
  ({
    type: 'update-filtered-tracks',
    filteredTracks,
  }) as const;

export const setSearchText = (searchText?: string) =>
  ({
    type: 'set-search-text',
    searchText,
  }) as const;

export const setSelectedModel = (selectedModel?: CoinModel) =>
  ({
    type: 'set-selected-model',
    selectedModel,
  }) as const;

/**
 * Sets the category and simultaneously de-selects the children
 * @param selectedCategory
 */
export const changeCategory =
  (selectedCategory?: CoinCategory) =>
  async (dispatch: React.Dispatch<any>): Promise<void> => {
    dispatch(
      setData({
        selectedCategory: selectedCategory,
        selectedType: { id: -1 } as CoinType,
        selectedModel: undefined,
        // TODO trigger a reload of models by setting stale
      }),
    );
  };

/**
 * Sets the type and simultaneously de-selects the children
 * @param selectedType
 */
export const changeType =
  (selectedType?: CoinType) =>
  async (dispatch: React.Dispatch<any>): Promise<void> => {
    dispatch(
      setData({
        // selectedCategory: selectedType?.category,
        selectedType: selectedType,
        // selectedModel: undefined
        // TODO trigger a reload of models by setting stale
      }),
    );
  };

export const setMenuEnabled = (menuEnabled: boolean) =>
  ({
    type: 'set-menu-enabled',
    menuEnabled,
  }) as const;

export const setStale = (isStale: boolean) =>
  ({
    type: 'set-stale',
    isStale,
  }) as const;

export const setLoadDateTime = (loadDateTime: Date) =>
  ({
    type: 'set-load-date-time',
    loadDateTime,
  }) as const;

export const setSelectedBidSheet = (selectedBidSheet?: BidSheet) =>
  ({
    type: 'set-selected-bid-sheet',
    selectedBidSheet,
  }) as const;

export type SessionsActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof setData>
  | ActionType<typeof addFavorite>
  | ActionType<typeof removeFavorite>
  | ActionType<typeof updateFilteredTracks>
  | ActionType<typeof setSearchText>
  | ActionType<typeof setMenuEnabled>
  | ActionType<typeof setSelectedModel>
  | ActionType<typeof setStale>
  | ActionType<typeof setLoadDateTime>
  | ActionType<typeof setSelectedBidSheet>;
