import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import {
  IonAvatar,
  IonBreadcrumb,
  IonBreadcrumbs,
  IonContent,
  IonPage,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from '@ionic/react';
import { connect } from '../data/connect';
import { CoinModel, CoinType, Grade, Options, User } from '../models/types';
import { changeType, loadCoinTypeData, setSelectedModel } from '../data/sessions/sessions.actions';
import CommonHeader from '../components/layout/CommonHeader';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import DownloadValuesButton from '../components/common/DownloadValuesButton';
import CommonFooter from '../components/layout/CommonFooter';
import { useExport } from '../hooks/useExport';
import ValueGrid from '../components/values/ValueGrid';
import Refresher from '../components/Refresher';

interface OwnProps extends RouteComponentProps {
  options: Options; // all options
  isAdmin: boolean;
}

interface StateProps {
  selectedModel: CoinModel;
  selectedType: CoinType;
  typeId: number;
  activeGrades: Grade[];
  user: User;
  isStale: boolean;
  coinTypes: CoinType[];
}

interface DispatchProps {
  setSelectedModel: typeof setSelectedModel;
  loadCoinTypeData: typeof loadCoinTypeData;
  changeType: typeof changeType;
}

interface CoinTypeProps extends OwnProps, StateProps, DispatchProps {}

const CoinTypeDetail: React.FC<CoinTypeProps> = ({
  options,
  selectedModel,
  selectedType,
  loadCoinTypeData,
  typeId,
  user,
  // coinTypes,
}) => {
  const contentRef = useRef<any>(null);
  const { gradesWithValues, standardizeModelValues } = useExport();
  const [formattedValues, setFormattedValues] = useState<CoinModel[]>();
  const [columnDefs, setColumnDefs] = useState<any>();
  const [gradesToShow, setGradesToShow] = useState<Grade[]>();
  // const history = useHistory();

  useIonViewWillEnter(() => {
    console.log('ionViewWillEnter event fired');
    setFormattedValues(undefined);
  }, []);

  useIonViewDidEnter(() => {
    console.log('ionViewDidEnter event fired');
    if (typeId && user?.userToken?.length && options.grade?.length) {
      console.debug(`loading coin type ${typeId}...`);
      loadCoinTypeData(user?.userToken, Number(typeId), options);
    }
  }, [typeId, user, options?.grade]);

  /**
   * Use on first load when type comes in
   */
  useEffect(() => {
    if (typeId && user?.userToken?.length && options.grade?.length) {
      console.debug(`loading coin type ${typeId}...`);
      loadCoinTypeData(user?.userToken, Number(typeId), options);
    }
    // eslint-disable-next-line
  }, [typeId, user, options?.grade]);

  /**
   * Find grades for the selected type
   */
  useEffect(() => {
    if (selectedType?.grades && options?.grade) {
      console.debug(`visibleGrades are `, selectedType?.grades);
      const grades = selectedType?.grades.length
        ? selectedType?.grades
        : gradesWithValues(
            selectedType?.models,
            options.grade.filter((g: Grade) => g.generic !== 1 || g.code === 'Melt'),
          );
      setGradesToShow(grades);
    }
    // eslint-disable-next-line
  }, [selectedType, options?.grade]);

  // /**
  //  * Scroll to selected model (during search)
  //  */
  // useEffect(() => {
  //   if (selectedModel) {
  //     gridRef.current?.api?.forEachNode(function (node) {
  //       node.setSelected(node.data.id === selectedModel.id, node.data.id === selectedModel.id);
  //       if (node.data.id === selectedModel.id) {
  //         // , 'middle'
  //         gridRef.current?.api.ensureNodeVisible(node);
  //       }
  //     });
  //   }
  //   // eslint-disable-next-line
  // }, [selectedModel, gridRef, formattedValues]);

  /**
   * Setup columns with appropriate grades for catalog
   */
  useEffect(() => {
    if (gradesToShow && gradesToShow.length > 0 && selectedType?.models) {
      const formatted = standardizeModelValues(selectedType?.models || [], gradesToShow);
      setFormattedValues(formatted);

      setColumnDefs([
        {
          field: 'name',
          headerName: 'Issue',
          pinned: 'left',
          width: 150,
          flex: 500,
        },
        ...(gradesToShow?.map((grade: Grade) => ({
          field: grade.code,
          headerName: grade.display,
          // pinned: grade.code === 'Melt' ? 'right' : '',
          // flex: true,
          type: 'numericColumn',
        })) ?? []),
      ]);
    }
    // eslint-disable-next-line
  }, [selectedType, gradesToShow]);

  return (
    <IonPage>
      <CommonHeader
        pageTitle={
          <>
            <IonBreadcrumbs slot={'start'}>
              <IonBreadcrumb routerLink={'/tabs/catalog'} className={'ion-hide-sm-down'}>
                Catalog
              </IonBreadcrumb>
              <IonBreadcrumb>{selectedType?.name}</IonBreadcrumb>
            </IonBreadcrumbs>
            <IonAvatar slot={'start'} class={'ion-padding'}>
              <img
                src={
                  selectedType?.obverseThumbnail
                    ? selectedType.obverseThumbnail
                    : `./assets/img/catalog/types/${selectedType?.id}_o_thumbnail.png`
                }
                alt={`${selectedType?.id}`}
                aria-hidden={true}
              />
            </IonAvatar>
          </>
        }
        endTools={gradesToShow && <DownloadValuesButton visibleGrades={gradesToShow} />}
        showDate={true}
      />

      <IonContent scrollEvents={true} ref={contentRef} scrollX={false} scrollY={false}>
        <Refresher />
        {formattedValues && columnDefs && gradesToShow && (
          <>
            <div id='div4Type' style={{ height: '100%', width: '100%' }}>
              {gradesToShow && (
                <ValueGrid
                  models={formattedValues}
                  visibleGrades={gradesToShow}
                  highlightedModel={selectedModel}
                  showTypeImage={false}
                  options={options}
                />
              )}
            </div>
          </>
        )}
      </IonContent>
      <CommonFooter showDate={true} />
    </IonPage>
  );
};

export default withAuthenticationRequired(
  connect({
    mapStateToProps: (state, ownProps) => ({
      typeId: ownProps?.match.params['typeId'],
      selectedType: state.data.selectedType,
      options: state.data.options,
      selectedModel: state.data.selectedModel,
      isAdmin: state.user.isAdmin,
      activeGrades: state.data.activeGrades,
      user: state.user,
      coinTypes: state.data.coinTypes,
    }),
    mapDispatchToProps: {
      setSelectedModel,
      loadCoinTypeData,
      changeType,
    },
    component: CoinTypeDetail,
  }),
);
