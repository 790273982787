import { IonButtons, IonItem, IonList, IonToolbar } from '@ionic/react';
import React from 'react';
import { CoinValueItem, Options } from '../../models/types';
import { removeItem, removeAll, selectItem, changeQuantity } from '../../data/item/item.actions';
import { connect } from '../../data/connect';
import ItemListItem from './ItemListItem';

interface OwnProps {
  sortBy: string[];
  groupBy: string[];
  showMenu?: boolean;
}

interface StateProps {
  selectedItems: CoinValueItem[];
  selectedTotal: number;
  options: Options;
  selectedItemGroups: CoinValueItem[];
}

interface ItemSelectorProps extends OwnProps, StateProps {
  removeItem: typeof removeItem;
  removeAll: typeof removeAll;
  selectItem: typeof selectItem;
  changeQuantity: typeof changeQuantity;
}

const ItemSelectionList: React.FC<ItemSelectorProps> = ({
  selectedItemGroups,
  removeItem,
  showMenu,
  changeQuantity,
}) => {
  return (
    <IonList>
      {showMenu && (
        <IonItem>
          <IonToolbar>
            <IonButtons slot={'end'} />
          </IonToolbar>
        </IonItem>
      )}
      {selectedItemGroups?.length > 0 &&
        selectedItemGroups?.map((item: any) => (
          <ItemListItem key={item.key} item={item} removeHandler={removeItem} quantityChangeHandler={changeQuantity} />
        ))}
    </IonList>
  );
};

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state, ownProps: OwnProps) => ({
    selectedItems: state.item.selectedItems,
    selectedTotal: state.item.selectedTotal,
    sortBy: ownProps.sortBy,
    groupBy: ownProps.groupBy,
    showMenu: ownProps.showMenu,
    options: state.data.options,
    selectedItemGroups: state.item.selectedItemGroups,
  }),
  mapDispatchToProps: {
    removeItem,
    removeAll,
    selectItem,
    changeQuantity,
  },
  component: ItemSelectionList,
});
