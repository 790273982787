import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { IonButton } from '@ionic/react';

const LogoutButton: React.FC<{}> = () => {
  const { isAuthenticated, logout } = useAuth0();

  return (
    <>
      {isAuthenticated && (
        <IonButton color={'medium'} onClick={() => logout({ returnTo: window.location.origin })}>
          Log Out
        </IonButton>
      )}
    </>
  );
};

export default LogoutButton;
