import {
  BidSheet,
  Catalog,
  CertificationService,
  CoinCategory,
  CoinGradeValue,
  CoinInfo,
  CoinModel,
  CoinType,
  CoinValueItem,
  Dealer,
  Denomination,
  Grade,
  Mintmark,
  Options,
  PostItem,
  SpotQuote,
  Strike,
} from '../models/types';
import axios from 'axios';
import { AxiosResponse } from 'axios';
import * as _ from 'lodash';

import staticSpot from './samples/summaries.json';
import SecureLS from 'secure-ls';
import { Storage } from '@ionic/storage';

const ls = new SecureLS({
  isCompression: false,
  // encodingType: 'none',
  // encryptionSecret: 'none',
  // encryptionNamespace: 'none',
});
const HAS_LOGGED_IN = 'HAS_LOGGED_IN';
const USER_TOKEN = 'CB_A_T';
const USER_NAME = 'USER_NAME';
const HAS_SEEN_TUTORIAL = 'HAS_SEEN_TUTORIAL';
const SELECTED_ITEMS_DATA = 'SELECTED_ITEMS_DATA';

// pull data urls from env vars
const {
  // REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_DEALER_API_URL,
  REACT_APP_OPTIONS_API_URL,
  REACT_APP_CATEGORIES_API_URL,
  REACT_APP_TYPES_API_URL,
  REACT_APP_MODELS_API_URL,
  REACT_APP_VALUES_API_URL,
  REACT_APP_BID_SHEETS_API_URL,
  REACT_APP_ITEMS_API_URL,
  // REACT_APP_OCR_API_URL,
  // REACT_APP_OCR_API_KEY,
  REACT_APP_FEEDBACK_GROUP,
} = process.env;

const dealersUrl = `${REACT_APP_DEALER_API_URL}`;
const optionsUrl = `${REACT_APP_OPTIONS_API_URL}`;
const coinCategoriesUrl = `${REACT_APP_CATEGORIES_API_URL}`;
const coinTypesUrl = `${REACT_APP_TYPES_API_URL}`;
const coinModelsUrl = `${REACT_APP_MODELS_API_URL}`;
const coinValuesUrl = `${REACT_APP_VALUES_API_URL}`;
const bidSheetsUrl = `${REACT_APP_BID_SHEETS_API_URL}`;
const itemUrl = `${REACT_APP_ITEMS_API_URL}`;
// const ocrUrl = `${REACT_APP_OCR_API_URL}`;
// const ocrKey = `${REACT_APP_OCR_API_KEY}`;
const feedbackGroup = `${REACT_APP_FEEDBACK_GROUP}`;

// IonicStorage used to retain our selected items list
const store = new Storage();
store.create();

/**
 * Retrieves dealer information.
 */
export const getDealerData = async (): Promise<Dealer[]> => {
  // Storage.remove({key : 'dealers'});

  let dealers = [];

  // const cachedDealersJSON = await Storage.get({key: 'dealers'}) || '';

  // console.log(cachedDealersJSON.value);
  // if (cachedDealersJSON) {
  // dealers = JSON.parse(cachedDealersJSON.value as string);
  // console.debug(`loaded ${dealers.length} dealer(s) from cache.`);
  // }
  // console.log(JSON.parse(`${cachedDealersJSON.value}`));
  // console.log(`using cache`);
  // return dealers.data as Dealer[];

  if (window.navigator.onLine && dealersUrl) {
    const response = await fetch(dealersUrl).catch((error: any) => {
      console.log(`unable to load customer/dealers`, error);
    });

    if (response && response.status === 200) {
      dealers = (await response.json()).data;

      console.debug(`loaded ${dealers.length} company/account/dealerships form server`);
      // Storage.set({key: 'dealers', value: JSON.stringify(dealers)});
    }
  }

  // // if we still dont have...need empty default value
  // if (!dealers) {
  //   console.warn(`Remote data not available so using cached json.`);
  //   dealers = staticDealers; // test data
  // }

  return dealers.sort((a: Dealer, b: Dealer) => (a.companyName < b.companyName ? -1 : 1)) as Dealer[];
};

export const getCoinCategoryData = async (): Promise<CoinCategory[]> => {
  const response = await fetch(coinCategoriesUrl).catch((error: any) => {
    console.log(`unable to fetch coin categories`, error);
  });
  if (response && response.status === 200) {
    const coinCategories: CoinCategory[] = (await response.json()).data;
    console.debug(`loaded ${coinCategories.length} coinCategories from server.`, coinCategories);
    return coinCategories.filter((cc: CoinCategory) => cc.isVisible) as CoinCategory[];
  } else {
    return [] as CoinCategory[];
  }
};

export const listCoinTypeData = async (token: string): Promise<CoinType[]> => {
  const response = await fetch(coinTypesUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).catch((error: any) => {
    console.log(`unable to fetch coin types`, error);
  });
  if (response && response.status === 200) {
    const coinTypes = (await response.json()).data;
    console.debug(`loaded ${coinTypes.length} coinTypes from server.`, coinTypes);
    return coinTypes.filter((ct: CoinType) => ct.isVisible) as CoinType[];
  } else {
    console.warn(`unable to fetch coin types`, response?.statusText);
    console.warn(`unable to fetch coin types - error was:`, (await response?.json())?.error);
    return [] as CoinType[];
  }
};

export const getCoinTypeData = async (token: string, typeId: number): Promise<CoinType | undefined> => {
  const response = await fetch(`${coinTypesUrl}/${typeId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).catch((error: any) => {
    console.log(`unable to fetch coin types`, error);
  });
  if (response && response.status === 200) {
    const coinType = (await response.json()).data;
    console.debug(`loaded coinType (id=${coinType.id}) from server.`, coinType);
    return coinType;
  } else {
    console.warn(`unable to fetch coin type ${typeId}`, response?.statusText);
    console.warn(`unable to fetch coin type ${typeId} - error was:`, (await response?.json())?.error);
    return undefined;
  }
};

export const getCoinModelData = async (token: string): Promise<CoinModel[]> => {
  const response = await fetch(coinModelsUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).catch((error: any) => {
    console.log(`unable to fetch coinModels`, error);
  });
  if (response && response.status === 200) {
    const coinModels = (await response.json()).data as CoinModel[];
    console.debug(`loaded ${coinModels.length} coinModels from server.`, coinModels);
    return coinModels;
  } else {
    console.warn(`unable to fetch coin models`, response?.statusText);
    console.warn(`unable to fetch coin models - error was:`, (await response?.json())?.error);
    return [] as CoinModel[];
  }
};

export const listBidSheetData = async (token: string): Promise<BidSheet[]> => {
  console.debug(`getBidSheetData...`);
  const response = await fetch(bidSheetsUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).catch((error: any) => {
    console.log(`unable to load bidSheets`, error);
  });
  if (response && response.status === 200) {
    const bidSheets = (await response.json()).data;

    console.debug(`loaded ${bidSheets.length} bidSheets from server.`, bidSheets);

    return bidSheets as BidSheet[];
  } else {
    console.log(`unable to load bidSheets`, response?.status);
    return [] as BidSheet[];
  }
};

export const getBidSheetData = async (token: string, bidSheetId: number): Promise<BidSheet> => {
  return axios
    .get(`${bidSheetsUrl}/${bidSheetId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((bidSheets: AxiosResponse) => {
      if (bidSheets && bidSheets.data) {
        console.debug(`loaded ${bidSheets.data.data.length} coin values from server.`, bidSheets.data.data);
        return bidSheets.data.data as BidSheet;
      } else {
        console.warn(`unable to fetch coin values - error was:`, bidSheets?.data.error);
        return {} as BidSheet;
      }
    })
    .catch((reason: any) => {
      console.debug(reason);
      console.warn(`unable to fetch bid sheets from ${bidSheetsUrl}.`);
      return {} as BidSheet;
    });
};

export const getOptionData = async (): Promise<Options> => {
  const response = await fetch(optionsUrl).catch((error: any) => {
    console.log(`unable to load options`, error);
  });
  if (response && response.status === 200) {
    const options = await response.json();
    console.debug(`loaded options from server.`, options);

    return {
      mintmark: _.filter(options.mintmark, { visible: 1 }) as Mintmark[],
      denomination: _.filter(options.denomination, { visible: 1 }) as Denomination[],
      strike: _.filter(options.strike, { visible: 1 }) as Strike[],
      grade: _.filter(options.grade, { visible: 1 }) as Grade[],
      certificationService: _.filter(options.certificationService, { visible: 1 } as CertificationService),
    } as Options;
  } else {
    return {
      denomination: [] as Denomination[],
      mintmark: [] as Mintmark[],
      strike: [] as Strike[],
      grade: [] as Grade[],
    } as Options;
  }
};

export const getCoinValueData = async (token: string): Promise<CoinGradeValue[]> => {
  return axios
    .get(coinValuesUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((values: AxiosResponse) => {
      if (values && values.data) {
        console.debug(`loaded ${values.data.data.length} coin values from server.`, values.data.data);
        return values.data.data as CoinGradeValue[];
      } else {
        console.warn(`unable to fetch coin values - error was:`, values?.data.error);
        return [] as CoinGradeValue[];
      }
    })
    .catch((reason: any) => {
      console.debug(reason);
      console.warn(`unable to load coin values from ${coinValuesUrl}.`);
      return [] as CoinGradeValue[];
    });
};

export const getCoinValueSourceBids = async (token: string, valueId: number): Promise<CoinGradeValue[]> => {
  return axios
    .get(`${coinValuesUrl}/${valueId}/sourceBids`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((values: AxiosResponse) => {
      if (values && values.data) {
        console.debug(`loaded ${values.data.data.length} source bids for ${valueId} from server.`, values.data.data);
        return (values.data.data as CoinGradeValue[]).map((v) => ({
          ...v,
          formattedValue: `${Number(v.value).toLocaleString('en-US', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}`,
          formattedUpdatedAt: `${new Date(v.updatedAt.iso).toLocaleDateString()}`,
        }));
      } else {
        console.warn(`unable to fetch source bids for ${valueId} - error was:`, values?.data.error);
        return [] as CoinGradeValue[];
      }
    })
    .catch((reason: any) => {
      console.debug(reason);
      console.warn(`unable to load source bids for ${valueId} from ${coinValuesUrl}.`);
      return [] as CoinGradeValue[];
    });
};

export const getCoinValueAuctionResults = async (token: string, valueId: number): Promise<CoinGradeValue[]> => {
  return axios
    .get(`${coinValuesUrl}/${valueId}/auctionValues`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((values: AxiosResponse) => {
      if (values && values.data) {
        console.debug(
          `loaded ${values.data.data.length} auction results for ${valueId} from server.`,
          values.data.data,
        );
        return (values.data.data as CoinGradeValue[]).map((v) => ({
          ...v,
          formattedValue: `${Number(v.value).toLocaleString('en-US', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}`,
          formattedUpdatedAt: `${new Date(v.updatedAt.iso).toLocaleDateString()}`,
        }));
      } else {
        console.warn(`unable to fetch auction results for ${valueId} - error was:`, values?.data.error);
        return [] as CoinGradeValue[];
      }
    })
    .catch((reason: any) => {
      console.debug(reason);
      console.warn(`unable to load auction results for ${valueId} from ${coinValuesUrl}.`);
      return [] as CoinGradeValue[];
    });
};

// TODO add more samples and implement test mechanism to exercise them
// const text_samples = [
//   `SAMPLE PCGS MS63+ High Relief, Peace 7356.63+/40516952`,
//   `SAMPLE -¯ii95\r\nPCGS G06\r\nLettered Edge\r\n1377.06/35573542\r\n`,
//   `1923 PCGS MS66 7360.66/26631491`,
//   `$1 1882-0 PCGS MS64 7216.64/16103914 epet...`,
//   `1923
// $1
// PCGS MS66
// 7360.66/26631491`,
//   `PCGS
// 1923
// MS63
// SSI
// 7360. 63/9237498`
// ];

// /**
//  * Uses OCR to parse text from image
//  * @param url location of image to parse text
//  * @param engine gives which ocr engine to use (defaults to 1)
//  */
// export const extractTextFromImageAtUrl = async (url: string, engine?: number): Promise<string> => {
//   const image = await Jimp.read(url);
//   image.resize(300, Jimp.AUTO);
//   // future: gather stats so I can send over JUST the label
//   image.crop(1, 1, 300, 150);
//   image.quality(75); // 75% quality
//
//   const bodyFormData = new FormData();
//   bodyFormData.append('language', 'eng');
//   bodyFormData.append('base64image', await image.getBase64Async(image.getMIME()));
//   bodyFormData.append('scale', 'true');
//   bodyFormData.append('OCREngine', `${engine ? engine : 1}`);
//
//   return axios
//     .post(ocrUrl, bodyFormData, {
//       timeout: 15000, // times out after 15 seconds
//       headers: {
//         apikey: ocrKey,
//       },
//     })
//     .then((response: AxiosResponse) => {
//       return response.data?.ParsedResults[0].ParsedText;
//       // return text_samples[2];
//     })
//     .catch((reason: any) => {
//       console.warn(`unable to extract text from image ${url}. Please see debug log for more information.`, reason);
//       return `Unable to extract text from image: '${reason}'`;
//     });
// };

const rx = {
  expIsPCGS: `((PCGS)|(\\d*(.)\\d{2}(\\+){0,1}(\\/)\\d*))`,
  expIsNGC: `(\\d*(\\-)\\d{3})|(NGC)|(GUARANTY)`,
  // expPCGSNumber: `(\\d*(?=(\\.)\\d{2}(\\+){0,1}(\\/)))`,
  expPCGSNumber: `[.](\\d{4,})`,
  expGrade: `((PR)|(MS)|(SP)|G|(VG)|(VF)|(XF)|(AU))\\-{0,1}\\d\\d(\\*|\\+){0,1}`,
  expPCGSGrade: `(\\d{2}\\+{0,1})(?=\\/)`,
  expNGCGrade: `\\b[A-Z]{1,2}(\\s){0,1}\\d{2}\\b`,
  expCertificationNumber: `((\\d*(\\-)\\d{3})|((\\d{6,10})))`, //covers NGC | PCGS
  expParts: `[\\s]+|[\\.]|[\\/]+|[\\-]+`,
  expYear: `(^|\\s)[\\d]{4}(\\s|$)`,
  expYearAndMM: `((^|\\s)[\\d]{4})(-|\\s).{1}`,
  expDenomination: `\\$20|\\$10|\\$5|\\$4|\\$3|\\$2.5|\\$1|50C|25C|10C|5C|1C`,
  expKeywords: `([a-zA-Z]{3,})`,
  expMintmark: `[\\s|-]([a-zA-Z]{1,2})(\\s|$)`,
};

export const parseCoinInfo = (text: string, coinInfo = {} as CoinInfo): CoinInfo => {
  text = text.toUpperCase();

  console.info(`parsing coin info from text "${text}..."`);
  const pcgsNumberMatches = text.match(rx.expPCGSNumber);
  const pcgsGradeMatches = text.match(rx.expPCGSGrade);
  const certNumberMatches = text.match(rx.expCertificationNumber);
  const isPCGSMatch = text.match(rx.expIsPCGS);
  const isNGCMatch = text.match(rx.expIsNGC);
  const gradeMatches = text.match(rx.expGrade);
  const ngcGradeMatches = text.match(rx.expNGCGrade);
  const yearMatches = text.match(rx.expYear);
  const yearAndMMMatches = text.match(rx.expYearAndMM);
  const denominationMatches = text.match(rx.expDenomination);
  const keywords = text.match(RegExp(rx.expKeywords, 'g'));
  const mintmarkMatches = text.match(rx.expMintmark);

  let parts = text.split(new RegExp(rx.expParts));
  parts = _.union(parts, coinInfo?.parts);
  parts = _.pull(parts, '', ' ');

  const grade = gradeMatches && gradeMatches.length ? gradeMatches[0].trim() : '';
  const pcgsGrade = pcgsGradeMatches && pcgsGradeMatches.index ? pcgsGradeMatches[0] : '';
  const ngcGrade = ngcGradeMatches && ngcGradeMatches.index ? ngcGradeMatches[0] : '';
  const isPCGS = !!(isPCGSMatch && isPCGSMatch.length) || coinInfo.isPCGS; // non-zero length = found match
  const isNGC = !!(isNGCMatch && isNGCMatch.length) || coinInfo.isPCGS; // non-zero length = found match
  const certificationCompany = isPCGS ? 'PCGS' : isNGC ? 'NGC' : 'Other/Raw';
  const yearAndMMParts = yearAndMMMatches && yearAndMMMatches.length ? yearAndMMMatches[0].split('-') : [null, null];
  const year = yearMatches && yearMatches.length ? Number(yearMatches[0].trim()) : Number(yearAndMMParts[0]?.trim());
  const mint = mintmarkMatches?.length ? mintmarkMatches[0] : 'P'; // no mintmark found assumes philly
  const mintmark = mintmarkMatches?.length ? mintmarkMatches[0] : '';
  const pcgsCoinNumber =
    pcgsNumberMatches && pcgsNumberMatches.length ? pcgsNumberMatches[0].replace('.', '') : coinInfo.pcgsCoinNumber;
  const denomination =
    denominationMatches && denominationMatches.length ? denominationMatches[0].trim() : coinInfo.denomination || '';

  return {
    imageUrl: coinInfo.imageUrl,
    text: text.trim() || coinInfo.text,
    isPCGS: isPCGS,
    isNGC: isNGC,
    pcgsCoinNumber: pcgsCoinNumber || coinInfo.pcgsCoinNumber,
    grade: grade.trim() || pcgsGrade || ngcGrade || coinInfo.grade,
    certificationNumber:
      coinInfo.certificationNumber || (certNumberMatches && certNumberMatches.index ? certNumberMatches[0] : ''),
    parts: parts,
    certificationCompany: coinInfo.certificationCompany || certificationCompany,
    year: year || coinInfo.year,
    mint: mint.trim() || coinInfo.mint,
    denomination: coinInfo.denomination || denomination.trim(),
    identifiable: !!pcgsCoinNumber || !!(denomination && year && mint),
    keywords: keywords,
    mintmark: mintmark.trim(),
  } as CoinInfo;
};

/**
 * Uses extracted values and catalog to initialize a coin bid
 * TODO break this into separate reducers in an effort to promote reuse
 * @param coinInfo
 * @param options
 * @param catalog
 * @deprecated
 */
export const buildCoinBid = (coinInfo: CoinInfo, options: Options, catalog: Catalog): CoinValueItem => {
  const year = coinInfo.year;
  const mintmark = _.find(options.mintmark, { code: coinInfo.mint });
  const grade = _.find(options.grade, { code: coinInfo.grade });
  const denomination = _.find(options.denomination, { code: coinInfo.denomination });
  const coinTypes = _.filter(catalog.coinTypes, (ct: CoinType) => {
    return (
      // ct.pcgs_start &&
      // // ct.pcgs_start <= coinInfo.pcgsCoinNumber &&
      // ct.pcgs_end &&
      // ct.pcgs_end >= coinInfo.pcgsCoinNumber &&
      ct.denomination === denomination?.id
    );
  }) as CoinType[];

  console.info(`found ${coinTypes.length} coinTypes matching pcgsNumber ${coinInfo.pcgsCoinNumber}`, coinTypes);

  const coinTypeIds = _.map(coinTypes, (ct: CoinType) => ct.id);

  const coinModels = _.filter(catalog.coinModels, (cm: CoinModel) => {
    return (
      (!coinTypeIds || _.find(coinTypeIds, (ctid: number) => ctid === cm.typeId)) &&
      cm.year === year &&
      cm.mintmarkId === mintmark?.id
    );
  }) as CoinModel[];

  console.info(
    `found ${coinModels.length} coinModels matching year: ${year} and mintmark ${mintmark?.code}`,
    coinModels,
  );

  // set this based on type or model
  const coinCategories = _.filter(catalog.coinCategories, (cc: CoinCategory) => {
    return _.find(coinTypes, (ct: CoinType) => ct.categoryId === cc.id);
  }) as CoinCategory[];

  console.info(`found ${coinCategories.length} coinCategories matching coinTypes`, coinCategories);

  // const coinTypeDenominations = _.uniq(_.map(coinTypes, (ct: CoinType) =>
  // options.denominations[ct.denomination || 0]));

  return {
    coinInfo: coinInfo, // is this necessary
    coinCategory: coinCategories.length === 1 ? coinCategories[0] : undefined, // need ANY
    denomination: denomination, // || coinTypeDenominations.length === 1 ? coinTypeDenominations[0] : {},
    type: coinTypes.length === 1 ? coinTypes[0] : undefined,
    year: coinInfo.year,
    mintmark: mintmark,
    grade: grade,
    model: coinModels.length === 1 ? coinModels[0] : undefined,
    pcgsCoinNumber: coinInfo.pcgsCoinNumber,
    certificationCompany: coinInfo.certificationCompany,
    certificationNumber: coinInfo.certificationNumber,
  } as CoinValueItem;
};

/**
 * Quick method to interface with items web service to post a message
 * @param token
 * @param post
 */
export const createPost = (token: string, post: PostItem): Promise<{ success: boolean; message: string }> => {
  return axios
    .post(`${itemUrl}/${feedbackGroup}/posts`, post, {
      timeout: 15000, // times out after 15 seconds
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response: AxiosResponse) => {
      console.log(`Response was ${response.statusText} (${response.status})`, response);
      return { success: true, message: `Thanks for your feedback` };
    })
    .catch((reason: any) => {
      return { success: false, message: `Unable to Post: '${reason}'` };
    });
};

/**
 * Quick method to load spot
 * @param token
 */
export const getSpotQuotes = (token?: string): Promise<SpotQuote[]> => {
  if (token) {
    const quotes = _.map(staticSpot.quotes, (quote: any) => ({
      ...quote.data,
    })) as SpotQuote[];
    // console.debug(`returned quotes are:`, quotes);
    return Promise.resolve(quotes);
  }
  return Promise.resolve([]);
};

export const getUserData = async (): Promise<any> => {
  const response = await Promise.all([
    ls.get(HAS_LOGGED_IN),
    ls.get(HAS_SEEN_TUTORIAL),
    ls.get(USER_TOKEN),
    ls.get(USER_NAME),
  ]);
  const isLoggedin = (await response[0].value) === 'true';
  const hasSeenTutorial = (await response[1].value) === 'true';
  const username = (await response[2].value) || undefined;
  const data = {
    isLoggedin,
    hasSeenTutorial,
    username,
  };
  return data;
};

export const setIsLoggedInData = async (isLoggedIn: boolean): Promise<void> => {
  ls.set(HAS_LOGGED_IN, isLoggedIn);
};

export const setHasSeenTutorialData = async (hasSeenTutorial: boolean): Promise<void> => {
  ls.set(HAS_SEEN_TUTORIAL, hasSeenTutorial);
};

export const setUsernameData = async (username?: string): Promise<void> => {
  if (!username) {
    ls.remove(USER_NAME);
  } else {
    ls.set(USER_NAME, username);
  }
};

export const setTokenData = async (token?: string): Promise<void> => {
  console.debug(`setTokenData`);
  if (!token) {
    ls.remove(USER_TOKEN);
  } else {
    ls.set(USER_TOKEN, token);
  }
};

export const getTokenData = async (): Promise<string> => {
  console.debug(`getTokenData`);
  return ls.get(USER_TOKEN);
};

export const setSelectedItemsData = (selectedItems: CoinValueItem[]): Promise<any> => {
  console.debug(`setSelectedItemsData`, selectedItems);
  return store.set(SELECTED_ITEMS_DATA, selectedItems);
};

export const getSelectedItemsData = async (): Promise<CoinValueItem[]> => {
  console.debug(`getSelectedItemsData`);
  return store.get(SELECTED_ITEMS_DATA);
};
