import { createTRPCReact } from '@trpc/react-query';
import { httpBatchLink, loggerLink, TRPCClient } from '@trpc/client';
import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server';
import type { AppRouter } from 'src/trpc/appRouter';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';

const { REACT_APP_TRPC_URL, REACT_APP_ENVIRONMENT, REACT_APP_AUTH0_AUDIENCE } = process.env;
export const trpc = createTRPCReact<AppRouter>();

export type RouterOutput = inferRouterOutputs<AppRouter>;
export type RouterInput = inferRouterInputs<AppRouter>;

export function useTrpcClient(): TRPCClient<any> {
  const { getAccessTokenSilently } = useAuth0();

  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        loggerLink({
          enabled: (opts) =>
            (REACT_APP_ENVIRONMENT === 'development' && typeof window !== 'undefined') ||
            (opts.direction === 'down' && opts.result instanceof Error),
        }),
        httpBatchLink({
          url: REACT_APP_TRPC_URL || 'unknownURL',
          // removes maxURULength to support large image uploads
          // (which result in large url sizes because of the urls included in a post)
          //  if this becomes an issue we will need send the image urls in chunks as an update.
          // maxURLLength: 3000, // a suitable size
          async headers() {
            const accessToken = await getAccessTokenSilently({
              // scope: 'offline_access',
              audience: REACT_APP_AUTH0_AUDIENCE,
            });

            console.debug(`accessToken is: `, accessToken);

            // if (accessToken == null && config.env === 'development') {
            //   console.warn('access token is empty, request might fail');
            //   return {};
            // }

            return {
              authorization: `Bearer ${accessToken}`,
            };
          },
        }),
      ],
    }),
  );
  return trpcClient;
}
