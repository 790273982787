import { IonButton, IonIcon, IonInput, useIonAlert } from '@ionic/react';
import React from 'react';
import { addCircleOutline, removeCircleOutline, trashOutline } from 'ionicons/icons';
import { useFormat } from '../../hooks/useFormat';

interface OwnProps {
  quantity: number;
  onChange?: (newQty: number) => void;
  onDelete?: () => void;
  increment: number;
  precision?: number;
  allowDelete?: boolean;
}

/**
 * Provides stateless grouped interface controls for quantity selection. The parent should manage state of quantity
 * by responding to onChange event.
 * @param onChange triggered when quantity value should change based upon user actions
 * @param onDelete
 * @param quantity the represents the number to display and acts as the basis for value changes
 * @param increment
 * @param precision
 * @param className
 * @param allowDelete
 */
const QuantitySelector: React.FC<OwnProps> = ({
  onChange,
  onDelete,
  quantity,
  increment,
  precision,
  allowDelete = false,
}) => {
  const [presentAlert] = useIonAlert();
  const { mathRound } = useFormat();

  const handleRemoveClick = () => {
    presentAlert({
      header: `Remove?`, // ${item?.model?.name}
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Delete',
          role: 'confirm',
          handler: () => {
            // process delete handler
            // removeHandler && removeHandler(item, sortCols);
            onDelete && onDelete();
          },
        },
      ],
    });
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: '-1',
        textAlign: 'center',
        verticalAlign: 'middle',
      }}
    >
      <IonButton
        fill={'clear'}
        disabled={!allowDelete && quantity == increment}
        size={'small'}
        color={'primary'}
        style={{ padding: '0 0 0 0', margin: '0 0 0 0' }}
        onClick={() => {
          const newQuantity = mathRound(quantity - increment, precision ?? 0);
          console.debug(`newQuantity`, newQuantity);
          if (allowDelete && newQuantity <= 0 && onDelete) {
            handleRemoveClick();
          } else if (onChange) {
            onChange(quantity > increment ? newQuantity : increment);
          }
        }}
      >
        <IonIcon
          slot='icon-only'
          icon={
            allowDelete && mathRound(quantity - increment, precision ?? 0) <= 0 ? trashOutline : removeCircleOutline
          }
        />
      </IonButton>
      <IonInput
        aria-label='Quantity'
        placeholder={`${increment}`}
        value={quantity.toFixed(precision ? precision : 0)}
        debounce={1000}
        onIonChange={(e: CustomEvent) => {
          if (onChange) {
            // make sure we have a non-negative number
            const positiveNum = mathRound(Math.abs(e.detail.value), precision ?? 0);
            if (positiveNum) onChange(positiveNum);
          }
        }}
        style={{ minWidth: '4em', padding: '0 0 0 0', margin: '0 0 0 0', maxWidth: '10em' }}
        fill={'solid'}
        type={'number'}
      />
      <IonButton
        fill={'clear'}
        style={{ padding: '0 0 0 0', margin: '0 0 0 0' }}
        onClick={() => {
          if (onChange) onChange(Number(quantity + increment));
        }}
        size={'small'}
        color={'primary'}
      >
        <IonIcon slot='icon-only' icon={addCircleOutline} />
      </IonButton>
    </div>
  );
};

export default QuantitySelector;
