export function useFormat(): any {
  /**
   * formatNumber as currency
   * @param params
   */
  const formatCurrency = (params: any): string => {
    return params.value
      ? params.value.toLocaleString('en-US', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      : `0.00`;
  };

  /**
   * Rounding using Math
   * @see https://en.wikipedia.org/wiki/Rounding
   * @param n
   * @param precision
   */
  const mathRound = (n: number | string, precision?: number): number => {
    const num = parseFloat(`${n}`);
    const preciseNumber = parseFloat(num.toFixed(precision));
    const evalNum = precision ? preciseNumber : preciseNumber;
    return Math.round((evalNum + Number.EPSILON) * 100) / 100;
  };

  /**
   * Formats date string in format of 2023-11-07T20:49:14.000Z as 11/7/2023 @3:49:14 PM
   * @param isoDateString
   */
  const formatAsDateTime = (isoDateString: string): string => {
    return `${new Date(isoDateString).toLocaleDateString()} @ ${new Date(isoDateString).toLocaleTimeString()}`;
  };

  return {
    formatCurrency,
    mathRound,
    formatAsDateTime,
  } as const;
}
