import React, { useMemo, useState } from 'react';
import { CoinGradeValue, CoinValueItem } from '../../models/types';
import { selectItem } from '../../data/item/item.actions';
import { connect } from '../../data/connect';
import { IonBadge, IonIcon } from '@ionic/react';
import { caretDownOutline, caretUpOutline } from 'ionicons/icons';

interface OwnProps {
  value?: CoinGradeValue;
}

interface StateProps {
  selectedItems: CoinValueItem[];
}

interface ValueSelectionBadgeProps extends OwnProps, StateProps {
  selectItem: typeof selectItem;
}

const ValueAndBadge: React.FC<ValueSelectionBadgeProps> = ({ value, selectedItems }) => {
  const [matchingCount, setMatchingCount] = useState<number>();

  /**
   * Query how many of the current item are in the selected items list
   */
  useMemo(() => {
    if (selectedItems && selectedItems?.length > 0) {
      //&& value && value?.modelId) {
      const matchingItems = selectedItems?.filter(
        (eachItem: CoinValueItem) =>
          eachItem.model?.id === value?.modelId && (!value?.grade || `${eachItem.grade}` === value?.grade),
      );

      setMatchingCount(matchingItems.length);
    } else {
      setMatchingCount(0);
    }
  }, [selectedItems, value]);

  return (
    <div
      title={
        value?.valueChange
          ? value?.valueChange > 0
            ? `+${value?.valueChange} on ${value.formattedUpdatedAt}` // UP
            : value?.valueChange < 0
              ? `${value?.valueChange} on ${value.formattedUpdatedAt}` // DOWN
              : ''
          : ''
      }
    >
      {selectedItems && matchingCount !== 0 && (
        <IonBadge
          style={{ margin: '2px' }}
          color={'danger'}
          onMouseOver={(e) => {
            console.debug(`TODO: perhpas we show the popover here on desktop`, e);
          }}
        >
          {matchingCount}
        </IonBadge>
      )}
      {value?.valueChange !== 0 && (
        <>
          {/* value is UP */}
          {value?.valueChange && value.valueChange > 0 && <IonIcon color={'success'} icon={caretUpOutline} />}
          {/* value is DOWN */}
          {value?.valueChange && value.valueChange < 0 && (
            <IonIcon title={`-${value.valueChange}`} color={'danger'} icon={caretDownOutline} />
          )}
        </>
      )}
      {value?.formattedValue ? value.formattedValue : '--'}
    </div>
  );
};

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state, ownProps: OwnProps) => ({
    value: ownProps.value,
    selectedItems: state.item.selectedItems,
  }),
  mapDispatchToProps: {
    selectItem,
  },
  component: ValueAndBadge,
});
