import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonInput,
  IonTextarea,
  IonButton,
  IonButtons,
  IonTitle,
  IonDatetimeButton,
  IonModal,
  IonDatetime,
  IonText,
  IonFooter,
  IonIcon,
} from '@ionic/react';
import { BidSheet, CoinValueItem } from '../../models/types';
import { closeOutline } from 'ionicons/icons';
import { trpc } from '../../util/trpc';
import { useIonToast } from '@ionic/react';
import { ValueSheet } from 'src/services/valueSheets/models/valueSheet';
import { connect } from '../../data/connect';
import { selectItem, selectSheet } from '../../data/item/item.actions';
import { useFormat } from '../../hooks/useFormat';

interface OwnProps {
  worksheet?: BidSheet;
  onCancel: () => void;
  onSave: (savedId?: number) => void;
}

interface StateProps {
  selectedItems: CoinValueItem[];
  selectedSheetId: number;
}

interface DispatchProps {
  selectItem: typeof selectItem;
  selectSheet: typeof selectSheet;
}

interface WorksheetFormProps extends OwnProps, StateProps, DispatchProps {}

/**
 * Provides a form to view/add/edit a value sheet
 */
const WorksheetForm: React.FC<WorksheetFormProps> = ({
  onCancel,
  onSave,
  selectedItems,
  selectSheet,
  selectedSheetId,
}) => {
  const history = useHistory();

  const [present] = useIonToast();

  const trpcContext = trpc.useContext();

  const valueSheet = trpc.valueSheets.getById.useQuery({
    id: selectedSheetId,
  });

  const [formWorksheet, setFormWorksheet] = useState<ValueSheet>();

  const { formatAsDateTime } = useFormat();

  useEffect(() => {
    setFormWorksheet(valueSheet?.data?.data);
  }, [valueSheet.data?.data, selectedSheetId]);

  const createValueSheet = trpc.valueSheets.create.useMutation({
    onMutate: (variables) => {
      // A mutation is about to happen!
      console.debug('creating sheet using: ', variables);
      // Optionally return a context containing data to use when for example rolling back
      return { name: variables.sheet?.name };
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.debug(`error: rolling back optimistic create ${context?.name}`, { error, variables, context });
      present(`Unable to save sheet '${context?.name}'`, 5000);
    },
    onSuccess: (data, variables, context) => {
      // Boom baby!
      console.debug(`created valuesheet ${context?.name}`, { data, variables, context });
      present(`Created sheet '${context?.name}'`, 3000);

      trpcContext.valueSheets.getAllValueSheets.invalidate();
      selectSheet(data);

      history.replace(`/tabs/selectedItems`);
      history.push(`/tabs/selectedItems/${data}`);

      // notify of save
      onSave && onSave(data);
    },
    // onSettled: (data, error, variables, context) => {
    //   // Error or success... doesn't matter!
    // },
  });

  const updateValueSheet = trpc.valueSheets.update.useMutation({
    onMutate: (variables: any) => {
      // A mutation is about to happen!
      console.debug('updating sheet using: ', variables);
      // Optionally return a context containing data to use when for example rolling back
      return { name: variables.sheet?.name };
    },
    onError: (error: any, variables: any, context: any) => {
      // An error happened!
      console.debug(`error: rolling back optimistic update ${context?.name}`, { error, variables, context });
      present(`Unable to save sheet '${context?.name}'`, 5000);
    },
    onSuccess: (data: any, variables: any, context: any) => {
      // Boom baby!
      console.debug(`updated valuesheet ${context?.name} with id ${'?????'}`, { data, variables, context });
      present(`Updated sheet '${context?.name}'`, 3000);

      trpcContext.valueSheets.invalidate().then(() => {
        // notify of save
        onSave && onSave(data);
      });
    },
    // onSettled: (data, error, variables, context) => {
    //   // Error or success... doesn't matter!
    // },
  });

  const handleSave = () => {
    // create if brand new
    if (!selectedSheetId) {
      createValueSheet.mutate({
        sheet: formWorksheet,
        items: selectedItems
          ?.filter((item) => !item.isDeleted)
          ?.map((item) => ({
            comments: item.comments,
            faceValue: Number(item.faceValue || 0.0),
            certificationService: item.certificationService ?? 1,
          })),
        values: selectedItems
          ?.filter((item) => !item.isDeleted)
          ?.map((item) => ({
            modelId: item.model?.id,
            value: Number(item.eachAmount || 0.0),
            grade: item.grade || '',
            priceSource: '',
            priceCode: 'worksheet',
          })),
      });
    } else {
      updateValueSheet.mutate({
        sheet: {
          ...formWorksheet,
          values: undefined,
          items: undefined,
        },
      });
    }
  };

  const labelPlacement = 'floating';

  return (
    <IonPage>
      <IonHeader translucent={false} color={'primary'}>
        <IonToolbar color={'primary'}>
          <IonButtons slot={'start'} class={'ion-hide-md-up'}>
            <IonButton>Cancel</IonButton>
          </IonButtons>
          <IonTitle class={'ion-text-center'}>
            <IonText>{formWorksheet?.name}</IonText>
          </IonTitle>
          <IonButtons slot={'end'}>
            <IonButton className={'ion-hide-md-up'}>Save</IonButton>
            <IonButton className={'ion-hide-sm-down'} onClick={() => onCancel()}>
              <IonIcon slot={'icon-only'} icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol size={'12'}>
              <IonInput
                label={'Name:'}
                labelPlacement={labelPlacement}
                value={formWorksheet?.name}
                onIonInput={(e: any) => {
                  setFormWorksheet({
                    ...formWorksheet,
                    name: e.target.value,
                  });
                }}
              />
            </IonCol>
            <IonCol size={'12'} className={'ion-hide'}>
              <IonInput label={'Customer:'} labelPlacement={labelPlacement} />
            </IonCol>
          </IonRow>
          <IonRow className={'ion-hide'}>
            <IonCol size={'12'}>
              <IonLabel>Good through: </IonLabel>
              <IonDatetimeButton datetime='datetime' />
              <IonModal keepContentsMounted={true}>
                <IonDatetime id='datetime' />
              </IonModal>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size={'12'}>
              <IonTextarea
                label={'Comments:'}
                labelPlacement={'floating'}
                autoGrow={true}
                rows={5}
                value={formWorksheet?.comments}
                onIonInput={(e: any) => {
                  setFormWorksheet({
                    ...formWorksheet,
                    comments: e.target.value,
                  });
                }}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      {formWorksheet?.id && (
        <IonFooter class={'no-padding'}>
          <IonGrid>
            <IonRow>
              <IonCol size={'12'} class={'ion-text-center'}>
                <IonText class={'ion-margin ion-padding'} color={'medium'}>
                  Created {formatAsDateTime(formWorksheet?.createdAt)}
                </IonText>
              </IonCol>
              <IonCol size={'12'} class={'ion-text-center'}>
                <IonText class={'ion-margin ion-padding'} color={'medium'}>
                  Last updated {formatAsDateTime(formWorksheet?.updatedAt)}
                </IonText>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonFooter>
      )}
      <IonFooter class={'ion-padding'}>
        <IonGrid>
          <IonRow>
            <IonCol sizeXs={'12'} sizeMd={'6'} class={'ion-text-center'}>
              <IonButton color={'medium'} expand={'block'} onClick={() => onCancel()}>
                <IonText>Cancel</IonText>
              </IonButton>
            </IonCol>
            <IonCol sizeXs={'12'} sizeMd={'6'} class={'ion-text-center'}>
              <IonButton color={'primary'} expand={'block'} onClick={() => handleSave()}>
                <IonText>{formWorksheet?.id ? 'Update' : 'Save'}</IonText>
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonFooter>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state, ownProps: OwnProps) => ({
    worksheet: ownProps.worksheet,
    onCancel: ownProps.onCancel,
    onSave: ownProps.onSave,
    selectedItems: state.item.selectedItems,
    selectedSheetId: state.item.selectedSheetId,
  }),
  mapDispatchToProps: {
    selectItem,
    selectSheet,
  },
  component: WorksheetForm,
});
