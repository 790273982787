import { CoinGradeValue, CoinModel, CoinValueItem, Grade, Options } from '../models/types';
import { jsonToCSV } from 'react-papaparse';
import * as _ from 'lodash';

export function useExport(): any {
  const exportGrouped = (selectedItemGroups: CoinValueItem[], name?: string, options?: Options) => {
    generateItemsCSV(
      name ?? 'selected_items_grouped',
      selectedItemGroups?.map((item: CoinValueItem) => ({
        Item: `${item.model?.name} ${item.model.coinType?.name ? item.model.coinType.name : ''}`,
        'PCGS No': item.model?.pcgsNumber,
        // Denom: item.type?.name,
        Category: item.model.coinType?.category?.name,
        // Service: item.certificationCompany || 'RAW',
        Cert: options ? options['certificationService'].find((cs) => cs.id === item.certificationService)?.display : '',
        Grade: item.grade,
        Notes: item.comments,
        Each: item.eachAmount,
        Measure: item.model?.measure,
        'Qty/FV': item.faceValue || item.quantity,
        Value: item.bidAmount,
      })),
    );
  };

  const exportFlat = (selectedItems: CoinValueItem[], name?: string, options?: Options) => {
    const formattedItems = selectedItems?.map((item: CoinValueItem) => ({
      Year: item.model?.year,
      'Mint Mark': item.model?.mintmark?.code,
      Denomination: item.model?.coinType?.denomination?.code,
      Type: item?.model.coinType ? `${item?.model.coinType?.name}` : '',
      Grade: item.grade,
      Title:
        // _.pick(item, ['item.model.year', 'item.model?.mintmark?.code', 'item.model?.coinType?.denomination?.code',
        // 'item.model?.coinType.name', 'item.grade', 'item.model?.description', 'item.comments'])
        [
          item.model?.year,
          item.model?.mintmark?.code,
          item.model?.coinType?.denomination?.code,
          options ? options['certificationService'].find((cs) => cs.id === item.certificationService)?.code : '',
          `${
            // number only for MS grades
            options && item.grade?.startsWith('MS') && item.model?.strikeId
              ? options['strike'].find((cs) => cs.id === item.model?.strikeId)?.code
              : ''
          }${options ? options['grade'].find((cs) => cs.code === item.grade)?.display : ''}`,
          // description is for variety and such
          item.model?.description,
          item.model?.coinType?.name,
        ]
          .filter(
            (strPart: string | null | undefined) =>
              strPart !== null && strPart != undefined && strPart !== 'undefined' && strPart.length > 0,
          )
          .join(' '),
      Description: item.comments?.length ? item.comments : '',
      Value: item.eachAmount,
      Measure: item.model?.measure,
      'PCGS No': item.model?.pcgsNumber,
      Category: item.model.coinType?.category ? item.model.coinType?.category?.name : '',
    }));

    console.debug(`exportFlat using : `, { selectedItems, formattedItems });

    generateItemsCSV(name ?? 'selected_items_flat', formattedItems);
  };

  /**
   *
   * @param filePrefix
   * @param models
   * @param grades
   */
  const generateCSV = async (
    filePrefix: string, // something like "MorganDollars"
    models: CoinModel[],
    grades: Grade[],
  ) => {
    console.debug(`generating csv for ${models.length} coin models:`, models);

    // pick values in order to show
    const formattedModels = models?.map((m: CoinModel) => ({
      modelId: m.id,
      name: m.name,
      pcgsNumber: m.pcgsNumber,
      categoryId: m.coinType?.category?.id || 'Not Available',
      typeId: m.typeId,
      type: m.coinType?.name || 'Not Available',
      sortOrder: m.sortOrder,
      priceSource: 'rtt-admin',
      priceCode: '',
      ...flatten(
        m.values || [],
        _.map(grades, (g: Grade) => g.code),
        m,
      ),
    }));

    const csv = jsonToCSV(formattedModels);
    const file = new Blob([csv], { type: 'text/csv' });
    return triggerDownload(filePrefix, file);
  };

  /**
   *
   * @param filePrefix
   * @param items
   */
  const generateItemsCSV = async (
    filePrefix: string, // something like "MorganDollars"
    items: any[],
  ) => {
    console.debug(`generating csv for ${items?.length} coin models:`, items);

    // pick values in order to show
    const formattedModels = items?.map((item: CoinValueItem) => ({
      ...item,
    }));

    const csv = jsonToCSV(formattedModels);
    const file = new Blob([csv], { type: 'text/csv' });
    return triggerDownload(filePrefix, file);
  };

  const triggerDownload = async (filePrefix: string, file: Blob) => {
    const now = new Date();
    const dd = String(now.getDate()).padStart(2, '0');
    const mm = String(now.getMonth() + 1).padStart(2, '0'); //January is 0!

    const element = document.createElement('a');
    element.href = URL.createObjectURL(file);
    element.download = `${filePrefix}_${now.getFullYear()}_${mm}_${dd}.csv`;
    element.target = '_new';
    document.body.appendChild(element);
    element.click();
  };

  /**
   * Need to find the right home for this...likely on the reducer side
   * @param models
   * @param grades
   */
  const gradesWithValues = (models: CoinModel[], grades: Grade[]): Grade[] => {
    if (models.length === 0) return [];

    // pull all the values together
    const allValues = models
      ?.map((m: CoinModel) => m.values || [])
      ?.reduce((acc: CoinGradeValue[], current: CoinGradeValue[]) => {
        return acc.concat(current);
      });

    return _.filter(grades, (g: Grade) => {
      return _.some(
        allValues,
        (cv: CoinGradeValue) => cv.grade === g.code && cv.value !== 0 && cv.value !== -1 && cv.formattedValue !== '--',
      );
    });
  };

  /**
   * Returns models with values in same shape inserting blank grade (aka --) when grade is missing.
   * @param models
   * @param grades
   */
  const standardizeModelValues = (models: CoinModel[], grades: Grade[]): CoinModel[] => {
    console.debug(`standardizing grades to`, grades);

    return models?.map((m: CoinModel) => ({
      // name: m.name,
      ...m,
      values: standardize(
        m.values || [],
        _.map(grades, (g: Grade) => g.code),
        m,
      ),

      // this is only used for csv export atm
      ...flatten(
        m.values || [],
        _.map(grades, (g: Grade) => g.code),
        m,
      ),
    }));
  };

  /**
   * Standardize shape to include all grades
   *
   * @param modelValues
   * @param grades
   */
  const standardize = (modelValues: CoinGradeValue[], grades: string[], cm: CoinModel): any => {
    // add in missing coin grade values
    const valuesForAll = _.map(grades, (g) => ({
      ...(_.find(modelValues, (mv) => mv.grade === g) || { modelId: cm.id, grade: g, formattedValue: '--' }),
    }));
    return valuesForAll;
  };

  /**
   * Returns an object of grades flattened to property names
   *
   * @param modelValues
   * @param grades
   */
  const flatten = (modelValues: CoinGradeValue[], grades: string[], cm: CoinModel): any => {
    const valuesForAll = standardize(modelValues, grades, cm);
    return _.zipObject(_.map(valuesForAll, 'grade'), _.map(valuesForAll, 'formattedValue'));
  };

  /**
   * Returns grades without special grades CAC and plus
   */
  const excludeSpecialGrades = (grades: Grade[]) => {
    return grades.filter((g: Grade) => !g.code.endsWith('CAC') && !g.code.endsWith('plus'));
  };

  return {
    generateCSV,
    generateItemsCSV,
    standardizeModelValues,
    gradesWithValues,
    flatten,
    excludeSpecialGrades,
    exportGrouped,
    exportFlat,
  } as const;
}
