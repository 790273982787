import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { connect } from '../data/connect';
import { CoinCategory, CoinType } from '../models/types';
import CommonHeader from '../components/layout/CommonHeader';
import { RouteComponentProps } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import CategoryListing from '../components/CategoryListing';

interface OwnProps extends RouteComponentProps {}

interface StateProps {
  categories: CoinCategory[];
  coinTypes: CoinType[];
}

interface DispatchProps {}

interface CoinCatalogListProps extends OwnProps, StateProps, DispatchProps {}

/**
 * List of all coin types grouped by category
 * @param categories
 * @param coinTypes
 * @constructor
 */
const CoinTypeList: React.FC<CoinCatalogListProps> = () => {
  return (
    <IonPage>
      <CommonHeader />
      <IonContent fullscreen={true}>
        <CategoryListing />
      </IonContent>
    </IonPage>
  );
};

export default withAuthenticationRequired(
  connect<OwnProps, StateProps, DispatchProps>({
    mapStateToProps: (state) => ({
      categories: state.data.coinCategories,
      coinTypes: state.data.coinTypes,
    }),
    component: CoinTypeList,
  }),
);
