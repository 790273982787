import { IonButton, IonIcon, IonText, useIonAlert, useIonModal } from '@ionic/react';
import { createOutline } from 'ionicons/icons';
import React, { useRef } from 'react';
import NewSheetForm from './NewSheetForm';

interface NewSheetModalProps {
  onNewSheet?: (priceCode: string) => void;
  disabled: boolean;
  requireConfirm?: boolean;
}

/**
 * Provides a form to view/add/edit a bid
 * @catalog contains category, type, model for selection
 * @constructor
 */
const NewSheetModalButton: React.FC<NewSheetModalProps> = ({ disabled, onNewSheet, requireConfirm }) => {
  const buttonRef = useRef<HTMLIonButtonElement>(null);
  const [presentAlert] = useIonAlert();

  const handleNew = (priceCode: string) => {
    if (requireConfirm) {
      presentAlert({
        header: `Confirm`,
        // ${selectedItems?.length}
        message: `This action will remove all item(s) from pending worksheet. Continue?`,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              return false;
            },
          },
          {
            text: 'New',
            role: 'confirm',
            handler: () => {
              // process new handler
              onNewSheet && onNewSheet(priceCode);
              // close the popup
              dismiss();
            },
          },
        ],
      });
    } else {
      // process new handler
      onNewSheet && onNewSheet(priceCode);
      // close the popup
      dismiss();
    }
  };

  /**
   * First parameter is the component to show, second is the props to pass
   */
  const [present, dismiss] = useIonModal(NewSheetForm, {
    onNewSheet: (priceCode: string) => {
      handleNew(priceCode);
    },
    onDismiss: () => {
      dismiss();
    },
  });

  return (
    <IonButton
      className={'ion-hide-sm-down ion-float-end'}
      onClick={() =>
        present({
          cssClass: 'rounded-modal new-sheet-modal', // see App.scss for modal height
          backdropDismiss: true,
          presentingElement: buttonRef.current || undefined,
        })
      }
      disabled={disabled}
      color={'tertiary'}
      ref={buttonRef}
    >
      {/*mobile*/}
      <IonIcon icon={createOutline} slot={'icon-only'} className={'ion-hide-md-up'} />
      {/*web*/}
      <IonIcon icon={createOutline} slot={'start'} className={'ion-hide-md-down'} />
      <IonText className={'ion-hide-md-down'}>New</IonText>
    </IonButton>
  );
};

export default NewSheetModalButton;
