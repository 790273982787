import { IonBadge } from '@ionic/react';
import React, { useEffect, useState } from 'react';

interface ItemListIndicatorProps {
  count?: number;
  maxDisplay?: number;
}

/**
 * Provides a 'cart' like button with any combo of title, count, and amount
 * @param count
 * @constructor
 */
const ItemSelectionBadge: React.FC<ItemListIndicatorProps> = ({ count, maxDisplay }) => {
  const [countToDisplay, setCountToDisplay] = useState<string>();

  useEffect(() => {
    if (count && count > (maxDisplay ?? 99)) {
      setCountToDisplay(`${maxDisplay ?? 99}+`);
    } else if (count) {
      setCountToDisplay(`${count}`);
    } else {
      setCountToDisplay(undefined);
    }
  }, [count, maxDisplay]);

  return (
    <IonBadge
      color={'danger'}
      style={{
        position: 'absolute',
        right: '-5px',
      }}
    >
      {countToDisplay}
    </IonBadge>
  );
};

export default ItemSelectionBadge;
