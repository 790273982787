import React from 'react';
import {
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonItem,
  IonLabel,
  IonSkeletonText,
} from '@ionic/react';
import { CoinCategory, CoinType } from '../models/types';
import TypeItem from './TypeItem';

interface CategoryCardProps {
  // extends StateProps {
  onSelect?: (typeId: number) => void;
  category: CoinCategory;
  coinTypes?: CoinType[];
}

/**
 * Provides a form to search
 * @param coinInfo information already gathered about a coin that will be bid on
 * @param onSelect callback when user chooses an item
 * @constructor
 */
const CategoryCard: React.FC<CategoryCardProps> = ({ category, coinTypes, onSelect }) => {
  return (
    <div style={{ minHeight: '1px', minWidth: '33%' }} className='ion-text-center'>
      <IonCardHeader>
        <IonCardTitle>{category.name}</IonCardTitle>
        <IonCardSubtitle>{coinTypes?.length} types</IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        {coinTypes ? (
          coinTypes?.map((type: CoinType) => (
            <TypeItem key={type.id} type={type} onSelect={() => onSelect && onSelect(type?.id)} />
          ))
        ) : (
          <>
            <IonItem>
              <IonIcon slot='start' src={`./assets/icon/${'coin.svg'}`} />
              <IonLabel>
                <IonSkeletonText animated style={{ width: '33%' }} />
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonIcon slot='start' src={`./assets/icon/${'coin.svg'}`} />
              <IonLabel>
                <IonSkeletonText animated style={{ width: '33%' }} />
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonIcon slot='start' src={`./assets/icon/${'coin.svg'}`} />
              <IonLabel>
                <IonSkeletonText animated style={{ width: '33%' }} />
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonIcon slot='start' src={`./assets/icon/${'coin.svg'}`} />
              <IonLabel>
                <IonSkeletonText animated style={{ width: '33%' }} />
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonIcon slot='start' src={`./assets/icon/${'coin.svg'}`} />
              <IonLabel>
                <IonSkeletonText animated style={{ width: '33%' }} />
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonIcon slot='start' src={`./assets/icon/${'coin.svg'}`} />
              <IonLabel>
                <IonSkeletonText animated style={{ width: '33%' }} />
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonIcon slot='start' src={`./assets/icon/${'coin.svg'}`} />
              <IonLabel>
                <IonSkeletonText animated style={{ width: '33%' }} />
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonIcon slot='start' src={`./assets/icon/${'coin.svg'}`} />
              <IonLabel>
                <IonSkeletonText animated style={{ width: '33%' }} />
              </IonLabel>
            </IonItem>
          </>
        )}
      </IonCardContent>
    </div>
  );
};

export default CategoryCard;
