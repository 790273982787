import { BidSheet, CoinCategory, CoinType, Grade } from '../../models/types';
import { connect } from '../../data/connect';
import { codeDownloadOutline, downloadOutline, lockOpenOutline } from 'ionicons/icons';
import ValuesPdf from '../values/ValuesPdf';
import { useIonModal } from '@ionic/react';
import React from 'react';
import PopoverMenuButton from './PopoverMenuButton';
import { useExport } from '../../hooks/useExport';

interface OwnProps {
  // allow inputs to make generic OR change store to retain models
  // name
  // models
  // grades
  visibleGrades: Grade[];
}

interface StateProps {
  selectedCategory: CoinCategory;
  selectedType: CoinType;
  selectedBidsheet: BidSheet;
  // activeGrades: Grade[];
  isAdmin: boolean;
}

interface DownloadValuesButtonProps extends OwnProps, StateProps {
  // selectItem: typeof selectItem;
}

/**
 * Uses features of download hook along with state info
 * @param item
 * @param selectItem
 * @param onSuccess
 * @param quantity
 */
/** */
const DownloadValuesButton: React.FC<DownloadValuesButtonProps> = ({ selectedType, visibleGrades, isAdmin }) => {
  const { generateCSV, gradesWithValues } = useExport();
  /**
   * First parameter is the component to show, second is the props to pass
   */
  const [present, dismiss] = useIonModal(ValuesPdf, {
    title: selectedType?.name,
    models: selectedType?.models,
    grades: visibleGrades,
    onDismiss: () => {
      dismiss();
    },
  });

  return (
    <PopoverMenuButton
      types={[
        {
          label: 'Download PDF',
          icon: downloadOutline,
          onSelect: () => present({ cssClass: 'rounded-modal', backdropDismiss: true }),
        },
      ].concat(
        isAdmin
          ? [
              {
                label: 'Download Excel',
                icon: codeDownloadOutline,
                onSelect: () => {
                  return generateCSV(
                    selectedType?.name,
                    selectedType?.models,
                    gradesWithValues(selectedType?.models, visibleGrades),
                  );
                },
              },
              {
                label: 'Edit in Retool',
                icon: lockOpenOutline,
                onSelect: () => {
                  window.open(`${process.env.REACT_APP_ADMIN_COIN_MODELS}${selectedType.id}`, '_blank');
                },
              },
            ]
          : [],
      )}
    />
  );
};

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    selectedCategory: state.data.selectedCategory,
    selectedType: state.data.selectedType,
    selectedBidsheet: state.data.selectedBidsheet,
    activeGrades: state.data.activeGrades,
    isAdmin: state.user.isAdmin,
  }),
  mapDispatchToProps: {},
  component: DownloadValuesButton,
});
