import React from 'react';
import {
  IonPage,
  IonContent,
  IonFooter,
  IonRow,
  IonCol,
  IonText,
  IonGrid,
  IonButton,
  IonCardHeader,
  IonCard,
  IonCardTitle,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
} from '@ionic/react';
import { closeOutline, createOutline } from 'ionicons/icons';

interface NewSheetFormProps {
  onDismiss: () => void;
  onNewSheet?: (priceCode: string) => void;
}

/**
 * Provides a form to view/add/edit a bid
 * @catalog contains category, type, model for selection
 * @constructor
 */
const NewSheetForm: React.FC<NewSheetFormProps> = ({ onDismiss, onNewSheet }) => {
  return (
    <IonPage>
      <IonHeader translucent={false}>
        <IonToolbar color={'primary'}>
          <IonTitle class={'ion-text-center'}>
            <IonText>Create Sheet</IonText>
          </IonTitle>
          <IonButtons slot={'end'}>
            <IonButton onClick={() => onDismiss()}>
              <IonIcon slot={'icon-only'} icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent class={'ion-padding'}>
        <IonGrid>
          <IonRow>
            <IonCol class={'ion-align-items-center'}>
              {/*sizeXs={'12'} sizeSm={'6'}*/}
              <IonCard>
                <img
                  style={{ width: '100%', height: '10em', objectFit: 'cover' }}
                  alt='Silhouette of mountains'
                  src='./assets/img/customer-offer.jpeg'
                />
                <IonCardHeader>
                  <IonCardTitle>Buy</IonCardTitle>
                  {/*<IonCardSubtitle>Items Grouped by Quantity</IonCardSubtitle>*/}
                </IonCardHeader>
                {/*<IonCardContent style={{ height: '7em' }} class={'ion-hide-sm-down'}>*/}
                {/*  Summary sheet shows all items grouped by quantity as seen on screen.*/}
                {/*</IonCardContent>*/}
                <IonFooter class={'ion-padding ion-align-items-center'}>
                  <IonButton color={'secondary'} expand={'block'} onClick={() => onNewSheet && onNewSheet('offer')}>
                    <IonIcon slot={'start'} icon={createOutline} />
                    <IonText>Customer Offer</IonText>
                  </IonButton>
                  <IonButton expand={'block'} onClick={() => onNewSheet && onNewSheet('bid')}>
                    <IonIcon slot={'start'} icon={createOutline} />
                    <IonText>WholeSale Bid</IonText>
                  </IonButton>
                </IonFooter>
              </IonCard>
            </IonCol>
            <IonCol class={'ion-align-items-center'}>
              <IonCard>
                <img
                  style={{ width: '100%', height: '10em', objectFit: 'cover' }}
                  alt='Silhouette of mountains'
                  src='./assets/img/inventory.jpeg'
                />
                <IonCardHeader>
                  <IonCardTitle>Sell</IonCardTitle>
                  {/*<IonCardSubtitle>Items One per Line</IonCardSubtitle>*/}
                </IonCardHeader>
                {/*<IonCardContent style={{ height: '7em' }} class={'ion-hide-sm-down'}>*/}
                {/*  <IonText>Detailed sheet shows each item on a single line instead of grouping.</IonText>*/}
                {/*</IonCardContent>*/}
                <IonFooter class={'ion-padding ion-align-self-center'}>
                  <IonButton color={'secondary'} expand={'block'} onClick={() => onNewSheet && onNewSheet('listing')}>
                    <IonIcon slot={'start'} icon={createOutline} />
                    <IonText>Retail Listing</IonText>
                  </IonButton>
                  <IonButton expand={'block'} onClick={() => onNewSheet && onNewSheet('ask')}>
                    <IonIcon slot={'start'} icon={createOutline} />
                    <IonText>Wholesale Ask</IonText>
                  </IonButton>
                </IonFooter>
              </IonCard>
            </IonCol>
            <IonCol class={'ion-align-items-center'}>
              <IonCard>
                <img
                  style={{ width: '100%', height: '10em', objectFit: 'cover' }}
                  alt='Silhouette of mountains'
                  src='./assets/img/inventory-sheet.png'
                />
                <IonCardHeader>
                  <IonCardTitle>Other</IonCardTitle>
                  {/*<IonCardSubtitle>Items One per Line</IonCardSubtitle>*/}
                </IonCardHeader>
                {/*<IonCardContent style={{ height: '7em' }} class={'ion-hide-sm-down'}>*/}
                {/*  <IonText>Detailed sheet shows each item on a single line instead of grouping.</IonText>*/}
                {/*</IonCardContent>*/}
                <IonFooter class={'ion-padding ion-align-self-center'}>
                  <IonButton color={'medium'} expand={'block'} onClick={() => onNewSheet && onNewSheet('worksheet')}>
                    <IonIcon slot={'start'} icon={createOutline} />
                    <IonText>Inventory Listing</IonText>
                  </IonButton>
                  <IonButton color={'medium'} expand={'block'} onClick={() => onNewSheet && onNewSheet('feedback')}>
                    <IonIcon slot={'start'} icon={createOutline} />
                    <IonText>Pricing Feedback</IonText>
                  </IonButton>
                </IonFooter>
              </IonCard>
            </IonCol>
            {/*<IonCol class={'ion-align-items-center'}>*/}
            {/*  <IonCard>*/}
            {/*    <img*/}
            {/*      style={{ width: '100%', height: '10em', objectFit: 'cover' }}*/}
            {/*      alt='Silhouette of mountains'*/}
            {/*      src='./assets/img/inventory.jpeg'*/}
            {/*    />*/}
            {/*    <IonCardHeader>*/}
            {/*      <IonCardTitle>Pricing Feedback</IonCardTitle>*/}
            {/*      /!*<IonCardSubtitle>Items One per Line</IonCardSubtitle>*!/*/}
            {/*    </IonCardHeader>*/}
            {/*    <IonCardContent style={{ height: '7em' }} class={'ion-hide-sm-down'}>*/}
            {/*      <IonText>Detailed sheet shows each item on a single line instead of grouping.</IonText>*/}
            {/*    </IonCardContent>*/}
            {/*    <IonFooter class={'ion-padding ion-align-self-center'}>*/}
            {/*      <IonButton color={'medium'} expand={'block'}>*/}
            {/*        <IonIcon slot={'start'} icon={createOutline} />*/}
            {/*        <IonText>Create</IonText>*/}
            {/*      </IonButton>*/}
            {/*    </IonFooter>*/}
            {/*  </IonCard>*/}
            {/*</IonCol>*/}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default NewSheetForm;
