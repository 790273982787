import { ActionType } from '../../util/types';
import { CoinModel, CoinValueItem } from '../../models/types';
import { getSelectedItemsData } from '../dataApi';
import { ItemState } from './item.state';
import { calcGroupBy, calcTotal, groupSelectedItemsBy, generateId } from './item.reducer';
import { ValueItem } from 'src/services/valueSheets/models/valueItem';
import * as _ from 'lodash';

/**
 * data is controlled internally
 * @param data
 */
const setData = (data: Partial<ItemState>) => {
  return {
    type: 'set-item-data',
    data,
  } as const;
};

export const selectItems =
  (selectedItems: ValueItem[], items: ValueItem[], models: CoinModel[], matchBy: string[]) =>
  async (dispatch: React.Dispatch<any>): Promise<void> => {
    // console.debug(`updating local state based on server changes: `, items);
    //
    // console.debug(`***** item.action/selectItems using ${items.length} items:`, items.map((i) => i.id).join('->'));

    const firstSort = _.sortBy(items, ['createdAt']);

    // console.debug(`***** item.action/selectItems using ${items.length}
    // firstSort:`, items.map((i) => i.id).join('->'));

    const sortedItems = firstSort
      ?.map((item) => ({
        ...item.value,
        ...item, // using id and audit data from item (not item.value)
        selectiveUpdateKey: selectedItems.find((si) => si.id === item.id)?.key ?? generateId(),
        key: generateId(),
        model: models.find((m) => m.id == item.value.modelId),
        eachAmount: item.value.value,
        bidAmount: item.value.value * (item.faceValue ?? 1),
      }))
      .map((item) => ({
        ...item,
        groupBy: calcGroupBy(item),
      }));

    // console.debug(
    //   `***** item.action/selectItems using ${items.length} sortedItems:`,
    //   items.map((i) => i.id).join('->'),
    // );

    // console.debug(`coinItems is: `, sortedItems);

    // const sortedItems = _.sortBy(coinItems, ['id']);

    dispatch(
      setData({
        selectedItems: sortedItems,
        selectedItemGroups: groupSelectedItemsBy(sortedItems, matchBy),
        selectedTotal: calcTotal(sortedItems),
      }),
    );
  };

/**
 * Load selected item data
 */
export const loadItemData =
  () =>
  async (dispatch: React.Dispatch<any>): Promise<void> => {
    // load items from local cache
    const loadedItems = await getSelectedItemsData();

    if (loadedItems) {
      console.debug(`loading ${loadedItems} loadedItem(s) is`, loadedItems);
      dispatch(
        setData({
          selectedItems: loadedItems,
          selectedItemGroups: groupSelectedItemsBy(loadedItems, []),
          selectedTotal: calcTotal(loadedItems),
        }),
      );
    }
  };

export const selectItem = (item: CoinValueItem, quantity?: number) =>
  ({
    type: 'select-item',
    item,
    quantity,
  }) as const;

/**
 * Remove items matching defined, non-null values of item.
 * @param item
 */
export const removeItem = (item: CoinValueItem, limit?: number) =>
  ({
    type: 'remove-item',
    item,
    limit,
  }) as const;

/**
 * Adjust quantity of the passed item and match criteria to the new quantity. For FV the quantity is always 1
 * @param item
 * @param matchBy
 * @param newQuantity
 */
// eslint-disable-next-line
export const changeQuantity = (item: CoinValueItem, matchBy?: string[], newQuantity?: number) => {
  const changedBy = Number(newQuantity ?? 0) - (item.quantity ?? 1);
  console.debug(`changeQuantity called with:`, { currentQuantity: item.quantity, matchBy, newQuantity, changedBy });
  if (changedBy > 0) return selectItem(item, changedBy);
  else if (changedBy < 0) return removeItem(item, Math.abs(changedBy));
  else {
    // no change in quantity so just update (covers FV)
    return updateItem(item);
  }
};

/**
 * Update the passed item
 * @param item
 */
export const updateItem = (item: CoinValueItem) =>
  ({
    type: 'update-item',
    item,
  }) as const;

export const removeAll = () =>
  ({
    type: 'remove-all',
  }) as const;

export const showMenu = () =>
  ({
    type: 'show-menu',
  }) as const;

export const hideMenu = () =>
  ({
    type: 'hide-menu',
  }) as const;

export const selectSheet = (sheetId: number) =>
  ({
    type: 'select-sheet-id',
    sheetId,
  }) as const;

export type ItemActions =
  | ActionType<typeof setData>
  | ActionType<typeof selectItem>
  | ActionType<typeof removeItem>
  | ActionType<typeof removeAll>
  | ActionType<typeof showMenu>
  | ActionType<typeof hideMenu>
  | ActionType<typeof changeQuantity>
  | ActionType<typeof updateItem>
  | ActionType<typeof selectSheet>;
