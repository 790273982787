import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useLocation, withRouter } from 'react-router';

import {
  IonAccordion,
  IonAccordionGroup,
  IonAvatar,
  IonButton,
  IonButtons,
  IonCardContent,
  IonContent,
  IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonSpinner,
  IonText,
  IonToolbar,
} from '@ionic/react';
import {
  analyticsOutline,
  caretDownCircle,
  closeOutline,
  desktopOutline,
  home,
  peopleOutline,
  pricetagOutline,
} from 'ionicons/icons';

import { connect } from '../../data/connect';
import { setDarkMode } from '../../data/user/user.actions';

import './Menu.scss';
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';
import { useAuth0 } from '@auth0/auth0-react';
import { BidSheet } from '../../models/types';
import { setMenuEnabled, setSearchText } from '../../data/sessions/sessions.actions';
import { trpc } from '../../util/trpc';
import { ValueSheetModel } from 'src/services/valueSheets/models/valueSheet';

const { REACT_APP_BRANCH, REACT_APP_REVIEW_ID, REACT_APP_RTT_APP_ROOT_URL } = process.env;

export const routes = {
  appPages: [
    { title: 'Home', path: '/tabs/home', icon: home },
    { title: 'Dealer Directory', path: '/tabs/dealers', icon: peopleOutline },
  ],
  loggedInPages: [{ title: 'Catalog', path: '/tabs/catalog', icon: analyticsOutline }],
  loggedOutPages: [],
};

interface Pages {
  title: string;
  path: string;
  icon: string;
  iconAssetName?: string;
  routerDirection?: string;
}
interface StateProps {
  darkMode: boolean;
  isAuthenticated: boolean;
  menuEnabled: boolean;
  bidSheets: BidSheet[];
}

interface DispatchProps {
  setDarkMode: typeof setDarkMode;
  setSearchText: typeof setSearchText;
  setMenuEnabled: typeof setMenuEnabled;
}

interface MenuProps extends RouteComponentProps, StateProps, DispatchProps {}

const Menu: React.FC<MenuProps> = ({ menuEnabled, setMenuEnabled }) => {
  const location = useLocation();
  const [bidSheetPages, setBidSheetPages] = useState<Pages[]>();
  const { user, isAuthenticated, isLoading } = useAuth0();

  const bidSheets = trpc.valueSheets.getAllPublishedBidSheets.useQuery({}).data?.data;

  /**
   * Creates menu items for any visible bid sheet using name as title
   */
  useEffect(() => {
    if (bidSheets) {
      console.debug(`bidSheets are: `, bidSheets);
      const newBidSheetPages = bidSheets
        .filter((bs: ValueSheetModel) => bs.isVisible)
        .map((bs: ValueSheetModel) => ({
          title: bs.name,
          path: `/tabs/genericBids/${bs.id}`,
          icon: pricetagOutline,
          iconAssetName: bs.iconName,
        }));
      console.debug(newBidSheetPages);
      setBidSheetPages(newBidSheetPages);
    }
  }, [bidSheets]);

  function renderlistItems(list: Pages[]) {
    return list
      .filter((route) => !!route.path)
      .map((p) => (
        <IonMenuToggle key={p.title} auto-hide='false' color={'primary'}>
          <IonItem
            detail={false}
            routerLink={p.path}
            routerDirection='forward'
            className={location.pathname.startsWith(p.path) ? 'selected' : undefined}
            color={'primary'}
          >
            {p.iconAssetName && <IonIcon slot='start' src={`./assets/icon/${p.iconAssetName}`} />}
            {!p.iconAssetName && <IonIcon slot='start' icon={p.icon} />}
            <IonLabel>{p.title}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      ));
  }

  return (
    <IonMenu type='overlay' disabled={!menuEnabled} contentId='main' menuId='main' side={'end'} color={'primary'}>
      <IonContent forceOverscroll={false} color={'primary'}>
        <IonToolbar color={'primary'}>
          <IonButtons slot={'end'} color={'primary'}>
            <IonMenuToggle
              auto-hide={false}
              menu={'pricingMenu'}
              onClick={() => {
                setMenuEnabled(false);
              }}
              color={'primary'}
            >
              <IonButton>
                <IonIcon slot={'icon-only'} icon={closeOutline} />
              </IonButton>
            </IonMenuToggle>
          </IonButtons>
        </IonToolbar>
        <IonCardContent color={'primary'}>
          <IonList lines={'none'} color={'primary'} style={{ background: 'inherit' }}>
            {renderlistItems(routes.appPages)}
            {isLoading && (
              <IonItem color={'primary'}>
                <IonSpinner class={'ion-text-center'} />
              </IonItem>
            )}
            {isAuthenticated ? renderlistItems(routes.loggedInPages) : renderlistItems(routes.loggedOutPages)}

            {isAuthenticated && bidSheetPages && (
              <IonAccordionGroup color={'primary'}>
                <IonAccordion
                  value='bidSheets'
                  toggleIcon={caretDownCircle}
                  toggleIconSlot='start'
                  style={{ backgroundColor: 'inherit' }}
                  color={'primary'}
                >
                  <IonItem slot='header' lines={'none'} color={'primary'}>
                    <IonLabel>Bid Sheets</IonLabel>
                  </IonItem>
                  <div style={{ paddingLeft: 20 }} slot='content' color={'primary'}>
                    {isAuthenticated && bidSheetPages && renderlistItems(bidSheetPages)}
                  </div>
                </IonAccordion>
              </IonAccordionGroup>
            )}

            {isAuthenticated && (
              <IonItem href={`${REACT_APP_RTT_APP_ROOT_URL}`} color={'primary'}>
                <IonIcon slot={'start'} icon={desktopOutline} />
                <IonText>RoundTable App</IonText>
              </IonItem>
            )}

            {/*<IonItem color={'primary'}>*/}
            {/*  <IonIcon slot='start' icon={moonOutline} />*/}
            {/*  <IonLabel>Dark Mode</IonLabel>*/}
            {/*  <IonToggle checked={darkMode} onClick={() => setDarkMode(!darkMode)} />*/}
            {/*</IonItem>*/}

            {isAuthenticated && (
              <IonMenuToggle auto-hide='false' color={'primary'}>
                <IonItem
                  routerLink={'/tabs/profileTab'}
                  className={location.pathname.startsWith('/tabs/profileTab') ? 'selected' : undefined}
                  color={'primary'}
                >
                  <IonAvatar slot={'start'}>
                    <img src={user?.picture || './assets/img/avatar.svg'} alt='' />
                  </IonAvatar>
                  <IonText>{user?.name}</IonText>
                </IonItem>
              </IonMenuToggle>
            )}
            {isAuthenticated && <LogoutButton />}
            {!isLoading && !isAuthenticated && <LoginButton />}
          </IonList>
        </IonCardContent>
        {/*</IonCard>*/}
      </IonContent>
      <IonFooter className={'ion-text-center ion-no-border'} color={'primary'}>
        <IonToolbar color={'primary'}>
          <IonText color={'light'}>
            {REACT_APP_BRANCH}.{REACT_APP_REVIEW_ID}
          </IonText>
        </IonToolbar>
      </IonFooter>
    </IonMenu>
  );
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    isAuthenticated: state.user.isLoggedin,
    menuEnabled: state.data.menuEnabled,
    bidSheets: state.data.bidSheets,
  }),
  mapDispatchToProps: {
    setDarkMode,
    setSearchText,
    setMenuEnabled,
  },
  component: withRouter(Menu),
});
