import React from 'react';
import { Options } from '../../models/types';
import { selectItem } from '../../data/item/item.actions';
import { connect } from '../../data/connect';

interface OwnProps {
  optionName: string;
  value: number | string;
  valueProp: string;
}

interface StateProps {
  options: Options;
}

interface ItemSelectorProps extends OwnProps, StateProps {
  selectItem: typeof selectItem;
}

/**
 * Quick translation using options
 */
const OptionValue: React.FC<ItemSelectorProps> = ({ optionName, value, valueProp, options }) => {
  return <>{options[optionName as keyof typeof options].find((s: any) => s[valueProp] === value)?.display}</>;
};

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state, ownProps: OwnProps) => ({
    optionName: ownProps.optionName,
    value: ownProps.value,
    field: ownProps.valueProp,
    options: state.data.options,
  }),
  mapDispatchToProps: {
    selectItem,
  },
  component: OptionValue,
});
