import React, { useEffect, useMemo, useState } from 'react';
import {
  IonContent,
  IonPage,
  IonCard,
  IonCardContent,
  IonButton,
  IonIcon,
  IonItemGroup,
  IonItemDivider,
  IonTitle,
  IonBreadcrumbs,
  IonBreadcrumb,
} from '@ionic/react';
import { connect } from '../data/connect';
import DealerItem from '../components/dealers/DealerItem';
import { Virtuoso } from 'react-virtuoso';
import { Dealer } from '../models/types';
import { options, people } from 'ionicons/icons';
import * as _ from 'lodash';
import CommonHeader from '../components/layout/CommonHeader';
import { withAuthenticationRequired } from '@auth0/auth0-react';

interface OwnProps {}

interface StateProps {
  dealers: Dealer[];
}

interface DispatchProps {}

interface DealerListProps extends OwnProps, StateProps, DispatchProps {}

const DealerList: React.FC<DealerListProps> = ({ dealers }) => {
  const [searchText] = useState<string>();
  const [filteredDealers, setFilteredDealers] = useState<Dealer[]>();
  const [dealerGroupNames, setDealerGroupNames] = useState<string[]>();
  const [groupedDealers, setGroupedData] = useState<any>();

  // create list of groups for dealers
  useEffect(() => {
    setDealerGroupNames(_.uniq(_.map(dealers, (d: Dealer) => d.companyName[0])));

    setFilteredDealers(dealers);
  }, [dealers]);

  // create groups of dealers anytime the dealers changes
  useEffect(() => {
    const groupedData = _.groupBy(filteredDealers, (d: Dealer) => d.companyName[0]);
    setGroupedData(groupedData);
  }, [filteredDealers]);

  useMemo(() => {
    if (searchText && searchText.length > 2) {
      setFilteredDealers(
        dealers?.filter(
          (d: Dealer) =>
            d.companyName?.toUpperCase().indexOf(searchText.toUpperCase()) !== -1 ||
            (d.owner?.name && d.owner.name.toUpperCase().indexOf(searchText.toUpperCase()) !== -1) ||
            (d.ebayId && d.ebayId.toUpperCase().indexOf(searchText.toUpperCase()) !== -1),
        ),
      );
    } else setFilteredDealers(dealers);
    // eslint-disable-next-line
  }, [dealers, searchText, setFilteredDealers]);

  return (
    <IonPage>
      <CommonHeader
        pageTitle={
          <IonBreadcrumbs class={'ion-float-start'}>
            <IonBreadcrumb routerLink={'/tabs/profileTab'} className={'ion-hide-sm-down'}>
              <IonIcon icon={people} slot={'start'} />
              Dealer Directory
            </IonBreadcrumb>
          </IonBreadcrumbs>
        }
        endTools={
          <>
            <IonButton disabled fill={'clear'} onClick={() => {}}>
              <IonIcon icon={options} slot='icon-only' />
            </IonButton>
          </>
        }
      >
        {/*<IonFooter>*/}
        {/*  <IonSearchbar*/}
        {/*    placeholder={'Filter Dealers...'}*/}
        {/*    value={searchText}*/}
        {/*    debounce={300}*/}
        {/*    onIonChange={(e) => {*/}
        {/*      setSearchText(`${e.detail.value}`);*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</IonFooter>*/}
      </CommonHeader>
      <IonContent>
        <Virtuoso
          data={dealerGroupNames}
          totalCount={dealerGroupNames?.values.length}
          itemContent={(groupIndex: number, groupName: string) => {
            return (
              <>
                {groupedDealers[groupName] && groupedDealers[groupName].length ? (
                  <IonItemGroup key={groupIndex} style={{ minHeight: '1px' }}>
                    <IonItemDivider>
                      <IonTitle>{groupName}</IonTitle>
                    </IonItemDivider>
                    {groupedDealers[groupName]?.map((dealer: Dealer) => <DealerItem key={dealer.id} dealer={dealer} />)}
                  </IonItemGroup>
                ) : (
                  <div style={{ maxHeight: '1px' }}>&nbsp;</div>
                )}
              </>
            );
          }}
        />
        {/*show message when zero results*/}
        {filteredDealers?.length === 0 && (
          <IonCard>
            <IonCardContent>No matching dealers.</IonCardContent>
          </IonCard>
        )}
      </IonContent>
    </IonPage>
  );
};

export default withAuthenticationRequired(
  connect<OwnProps, StateProps, DispatchProps>({
    mapStateToProps: (state) => ({
      dealers: state.data.dealers,
    }),
    component: DealerList,
  }),
);
