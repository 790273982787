import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient as TrpcQueryClient, QueryClientProvider as TrpcQueryClientProvider } from '@tanstack/react-query';

/* Prime theme */
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { ServerError } from 'src/trpc/trpc';
import { useIonToast } from '@ionic/react';
import { trpc, useTrpcClient } from './util/trpc';

const { REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID, REACT_APP_AUTH0_AUDIENCE } = process.env;
if (REACT_APP_AUTH0_DOMAIN == null || REACT_APP_AUTH0_CLIENT_ID == null) {
  throw new Error(`Missing REACT_APP_AUTH0_DOMAIN or REACT_APP_AUTH0_CLIENT_ID env vars`);
}

const DEFAULT_REFRESH_INTERVAL = 1000 * 30; // 30 seconds

function AuthenticatedQueryProviders() {
  const [message] = useIonToast();
  // useServiceWorkerWithUpdatePrompt();

  const [trpcQueryClient] = useState(
    () =>
      new TrpcQueryClient({
        defaultOptions: {
          queries: {
            refetchInterval() {
              return DEFAULT_REFRESH_INTERVAL;
            },
            refetchOnWindowFocus: true, // config.trpcRefetchOnWindowFocus === 'true',
            onError(error) {
              const e = error as ServerError;
              message({
                message: `Something went wrong, reason: ${e.shape?.message ?? 'Unknown'}`,
                duration: 1000,
                // uuid: e.shape?.uuid,
                // severity: 'error',
              });
            },
          },
          mutations: {
            onError(error) {
              const e = error as ServerError;
              message({
                message: `Something went wrong, reason: ${e.shape?.message ?? 'Unknown'}`,
                // uuid: e.shape?.uuid,
                // severity: 'error',
              });
            },
          },
        },
      }),
  );
  const trpcClient = useTrpcClient();

  return (
    <trpc.Provider client={trpcClient} queryClient={trpcQueryClient}>
      <TrpcQueryClientProvider client={trpcQueryClient}>
        <App />
      </TrpcQueryClientProvider>
    </trpc.Provider>
  );
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <BrowserRouter>
    <Auth0Provider
      domain={REACT_APP_AUTH0_DOMAIN}
      clientId={REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={REACT_APP_AUTH0_AUDIENCE}
    >
      <AuthenticatedQueryProviders />
    </Auth0Provider>
  </BrowserRouter>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
