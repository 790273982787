import React from 'react';
import { IonHeader, IonToolbar, IonContent, IonPage, IonButtons, IonMenuButton, IonTitle, IonItem } from '@ionic/react';
import { connect } from '../data/connect';
import { Virtuoso } from 'react-virtuoso';
import { CoinCategory } from '../models/types';
import { withAuthenticationRequired } from '@auth0/auth0-react';

interface OwnProps {}

interface StateProps {
  coinCategories: CoinCategory[];
}

interface DispatchProps {}

interface CoinCatalogListProps extends OwnProps, StateProps, DispatchProps {}

/**
 * Top level for coin catalog showing the visible categories.
 * @param coinCategories
 * @constructor
 */
const CoinCatalog: React.FC<CoinCatalogListProps> = ({ coinCategories }) => {
  return (
    <IonPage id='dealer-list'>
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Select Category</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={false}>
        <Virtuoso
          style={{ height: '100%' }}
          data={coinCategories}
          totalCount={coinCategories?.length}
          itemContent={(index: number, category: CoinCategory) => {
            return (
              <div style={{ height: '56px' }}>
                <IonItem key={index} detail={true} routerLink={`/tabs/coinCatalog/${category?.id}`}>
                  {category.name}
                </IonItem>
              </div>
            );
          }}
        />
      </IonContent>
    </IonPage>
  );
};

export default withAuthenticationRequired(
  connect<OwnProps, StateProps, DispatchProps>({
    mapStateToProps: (state) => ({
      coinCategories: state.data.coinCategories,
    }),
    component: React.memo(CoinCatalog),
  }),
);
