import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  IonBadge,
  IonButton,
  IonCard,
  IonIcon,
  IonInput,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonText,
  useIonAlert,
} from '@ionic/react';
import { connect } from '../../data/connect';
import { changeQuantity, removeAll, removeItem, selectItem, updateItem } from '../../data/item/item.actions';
import { loadCoinValueData } from '../../data/sessions/sessions.actions';
import { CoinModel, CoinType, CoinValueItem, Options, CoinGradeValue } from '../../models/types';
import { QuantityCellRenderer } from './QuantityCellRenderer';
import { useAuth0 } from '@auth0/auth0-react';
import ValuesList from '../values/ValuesList';
import { SearchCellRenderer } from './SearchCellRenderer';
import { pencil, trashOutline, checkmarkCircleOutline } from 'ionicons/icons';
import OptionValue from '../common/OptionValue'; // trashBinOutline

interface OwnProps {}

interface StateProps {
  userToken: string;
  options: Options;
  selectedItems: CoinValueItem[];
  selectedTotal: number;
  coinModels: CoinModel[];
  coinTypes: CoinType[];
  coinValues: CoinGradeValue[];
  selectedItemGroups: CoinValueItem[];
}

interface DispatchProps {
  removeItem: typeof removeItem;
  removeAll: typeof removeAll;
  selectItem: typeof selectItem;
  changeQuantity: typeof changeQuantity;
  updateItem: typeof updateItem;
  loadCoinValueData: typeof loadCoinValueData;
}

interface ExpandingItemWorksheetProps extends OwnProps, StateProps, DispatchProps {}

const ExpandingItemWorksheet: React.FC<ExpandingItemWorksheetProps> = ({
  options,
  updateItem,
  removeItem,
  changeQuantity,
  coinValues,
  userToken,
  loadCoinValueData,
  selectedItems,
  selectedTotal,
  selectedItemGroups,
}) => {
  const { user } = useAuth0();
  const [displayedGroups, setDisplayedGroups] = useState<CoinValueItem[]>();
  const [selectedRow, setSelectedRow] = useState<CoinValueItem>();
  const [expandedRow, setExpandedRow] = useState<any>([]);
  const [presentAlert] = useIonAlert();

  useEffect(() => {
    // this is the big one...don't want to load it often at all
    if (userToken) {
      console.debug(`load coin value data...`);
      loadCoinValueData(userToken);
    }
    // eslint-disable-next-line
  }, [userToken, user]);

  useMemo(() => {
    if (selectedItemGroups) {
      setDisplayedGroups(selectedItemGroups);
    }
    // eslint-disable-next-line
  }, [selectedItemGroups]);

  const onCellEditComplete = useCallback(
    (e: any) => {
      const { rowData, newValue, field } = e; // , originalEvent: event
      rowData[field] = newValue;
      if (updateItem) return updateItem(rowData);
    },
    [updateItem],
  );

  const textEditor = (options: any) => {
    return (
      <IonInput
        type='text'
        value={options.rowData[options.field]}
        onIonInput={(e: any) => {
          return options.editorCallback(e.target.value);
        }}
        fill={'outline'}
      />
    );
  };

  const optionEditor = (data: any, ops: any[], valueField?: string) => {
    // console.debug(`option valueField value is`, valueField);

    return (
      <IonSelect
        // interface={'popover'}
        placeholder='Select...'
        value={data?.rowData[data.field]}
        onIonChange={(e: any) => {
          // console.debug(`setting ${data.field} on row id= to ${e.target.value}`);
          onCellEditComplete({ rowData: data.rowData, newValue: e.target.value, field: `${data.field}` });
          // return data.editorCallback(e.target.value);
        }}
        fill={'outline'}
      >
        {/*?.filter((g) => g.generic === data.rowData.model?.isType)*/}
        {ops.map((option: any) => (
          <IonSelectOption
            key={valueField && option ? option[valueField] : option?.code}
            value={valueField && option ? option[valueField] : option?.code}
          >
            {option?.display}
          </IonSelectOption>
        ))}
      </IonSelect>
    );
  };

  const amountEditor = (options: any) => {
    return (
      <IonInput
        value={options.rowData[options.field]}
        onIonInput={(e: any) => {
          // console.debug(e);
          return options.editorCallback(e.target.value);
        }}
        type={'number'}
        inputmode={'decimal'}
        fill={'outline'}
      />
    );
  };

  const amountBodyTemplate = (value: number) => {
    // console.debug(`formatting ${value}...`);
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  };

  const rowExpansionTemplate = (data: any) => {
    const relValue = coinValues?.find((v: any) => v.modelId === data.model?.id && v.grade === data.grade) as any;
    return (
      // style={{ paddingLeft: '300px', paddingRight: '300px' }}
      <IonCard className={'ion-padding'}>
        Value source information for {data.model?.name} with grade of {data.grade}
        {/*<ValuesChartDemo />*/}
        <ValuesList value={relValue} model={data.model} />
      </IonCard>
    );
  };

  const confirmRemoveItem = (selectedItem: CoinValueItem) => {
    presentAlert({
      header: 'Confirm',
      message: `Remove '${selectedItem.model.name}${
        selectedItem.model.coinType ? ' ' + selectedItem.model.coinType.name : ''
      }
      ${selectedItem.comments ? ' ' + selectedItem.comments : ''}'?`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Yes, Remove',
          role: 'confirm',
          handler: () => {
            // process delete handler
            removeItem(selectedItem);
          },
        },
      ],
    });
  };

  return (
    <div
      className={'ion-no-padding ion-no-margin'}
      style={{
        display: 'block',
        overflow: 'hidden',
        height: '100%',
        width: '100%',
      }}
    >
      <DataTable
        className={'p-datatable-sm p-datatable-striped p-datatable-gridlines'}
        value={displayedGroups as any}
        editMode='cell'
        dataKey='key'
        rowExpansionTemplate={rowExpansionTemplate}
        expandedRows={expandedRow}
        onRowToggle={(e: any) => {
          setExpandedRow(e.data);
        }}
        onRowExpand={(e) => {
          setSelectedRow(e.data as CoinValueItem);
        }}
        selectionMode='single'
        selection={selectedRow}
        onSelectionChange={(e) => {
          setSelectedRow(e.value);
        }}
        scrollable
        style={{ minWidth: '100%' }}
        scrollHeight={'flex'}
        resizableColumns
        columnResizeMode='expand'
        frozenWidth={'425px'}
        footer={
          <>
            <IonItem lines={'none'} color={'primary'}>
              <SearchCellRenderer />
              <IonText slot={'end'}>
                Grand Total ({selectedItems.length}):{' '}
                {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(selectedTotal)}
              </IonText>
            </IonItem>
          </>
        }
        // loading={itemsLoading}
      >
        <Column
          className={'ion-padding'}
          field='model'
          header='Item'
          frozen={true}
          body={(item: CoinValueItem) => `${item?.model?.name}
           ${item?.model?.coinType ? item.model?.coinType?.name : ''}`}
          headerStyle={{ width: '200px' }}
          resizeable={true}
          alignHeader={'center'}
        />
        <Column expander style={{ width: '50px' }} frozen={true} />
        <Column
          header=''
          body={(item?: CoinValueItem) => (
            <>
              {!item?.id || item?.isChanged ? (
                <>
                  <IonIcon icon={checkmarkCircleOutline} color={'medium'} />
                  {/*{item?.id}*/}
                </>
              ) : (
                <>
                  <IonIcon icon={checkmarkCircleOutline} color={'success'} />
                  {/*{item?.id}*/}
                </>
              )}
            </>
          )}
          style={{ width: '20px' }}
          alignHeader={'center'}
          align={'left'}
        />
        {/*<Column*/}
        {/*  field='key'*/}
        {/*  header='Key'*/}
        {/*  style={{ width: '150px' }}*/}
        {/*  // onCellEditComplete={onCellEditComplete}*/}
        {/*  alignHeader={'center'}*/}
        {/*  align={'left'}*/}
        {/*/>*/}
        <Column
          field='comments'
          header='Desc.'
          body={(item?: CoinValueItem) => <>{item?.comments ? <>{item?.comments}</> : <IonIcon icon={pencil} />}</>}
          editor={textEditor}
          style={{ width: '150px', maxWidth: '150px', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
          onCellEditComplete={onCellEditComplete}
          alignHeader={'center'}
          align={'center'}
        />
        <Column
          field='certificationService'
          header='Cert'
          body={(item?: CoinValueItem) => (
            <>
              {options && item?.certificationService ? (
                <IonBadge color={'medium'}>
                  <OptionValue
                    optionName={'certificationService'}
                    value={item?.certificationService}
                    valueProp={'id'}
                  />
                </IonBadge>
              ) : (
                <IonIcon icon={pencil} />
              )}
            </>
          )}
          editor={(data) => {
            return optionEditor(data, options?.certificationService, 'id');
          }}
          style={{ width: '200px' }}
          align={'center'}
          alignHeader={'center'}
        />
        <Column
          field='grade'
          header='Grade'
          body={(item?: CoinValueItem) => (
            <>
              {item?.grade ? (
                <>
                  <IonBadge color={'primary'}>
                    <OptionValue optionName={'grade'} value={item?.grade} valueProp={'code'} />
                  </IonBadge>
                </>
              ) : (
                <IonIcon icon={pencil} />
              )}
            </>
          )}
          editor={(data) => {
            return optionEditor(data, options?.grade);
          }}
          style={{ width: '200px' }}
          align={'center'}
          alignHeader={'center'}
        />
        <Column
          header='Melt'
          body={(item: CoinValueItem) => {
            if (!item.model?.id) return '';

            const relValue = coinValues?.find((v: any) => v.modelId === item.model?.id && v.grade === 'Melt') as any;
            return amountBodyTemplate(Number(relValue?.value || 0.0));
          }}
          style={{ width: '150px' }}
          alignHeader={'center'}
          align={'right'}
        />
        <Column
          header='CB Value'
          body={(item: CoinValueItem) => {
            if (!item.model?.id) return '';
            const relValue = coinValues?.find(
              (v: any) => v.modelId === item.model?.id && v.grade === item.grade,
            ) as any;
            return amountBodyTemplate(Number(relValue?.value || 0.0));
          }}
          style={{ width: '150px' }}
          alignHeader={'center'}
          align={'right'}
        />
        <Column
          header='Qty/FV'
          body={(item: CoinValueItem) => {
            return (
              <QuantityCellRenderer value={item.quantity} data={item} onChange={changeQuantity} onRemove={removeItem} />
            );
          }}
          headerStyle={{ width: '100px' }}
          style={{ width: '100px' }}
          resizeable={true}
          alignHeader={'center'}
          align={'center'}
        />
        <Column
          field='eachAmount'
          header='My Value'
          body={(item?: CoinValueItem) => (
            <>{item?.eachAmount ? amountBodyTemplate(item?.eachAmount || 0) : <IonIcon icon={pencil} />}</>
          )}
          editor={(options) => amountEditor(options)}
          style={{ width: '150px' }}
          onCellEditComplete={onCellEditComplete}
          alignHeader={'center'}
          align={'right'}
        />
        <Column
          field='bidAmount'
          header='Total'
          body={(item: CoinValueItem) => amountBodyTemplate(item.bidAmount || 0)}
          style={{ width: '150px' }}
          frozen={true}
          alignFrozen={'right'}
          alignHeader={'center'}
          align={'right'}
        />
        <Column
          header=''
          body={(item: CoinValueItem) => {
            return (
              <>
                <IonButton
                  slot={'start'}
                  fill={'clear'}
                  className={'ion-no-padding ion-no-margin'}
                  size={'small'}
                  color={'primary'}
                  onClick={() => {
                    confirmRemoveItem(item);
                  }}
                >
                  <IonIcon slot='icon-only' icon={trashOutline} />
                </IonButton>
              </>
            );
          }}
          frozen={true}
          alignFrozen={'right'}
          alignHeader={'center'}
          style={{ width: '25px' }}
          align={'center'}
        />
      </DataTable>
    </div>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    userToken: state.user.userToken,
    options: state.data.options,
    selectedItems: state.item.selectedItems,
    selectedTotal: state.item.selectedTotal,
    coinModels: state.data.coinModels,
    coinTypes: state.data.coinTypes,
    coinValues: state.data.coinValues,
    selectedItemGroups: state.item.selectedItemGroups,
  }),
  mapDispatchToProps: {
    removeItem,
    removeAll,
    selectItem,
    changeQuantity,
    updateItem,
    loadCoinValueData,
  },
  component: ExpandingItemWorksheet,
});
