import React from 'react';
import {
  IonButton,
  IonButtons,
  IonFooter,
  IonIcon,
  IonMenuToggle,
  IonSpinner,
  IonText,
  IonToolbar,
} from '@ionic/react';
import { connect } from '../../data/connect';
import './CommonHeader.scss';
import { CoinValueItem } from '../../models/types';
import { hideMenu, showMenu } from '../../data/item/item.actions';
import { albumsOutline } from 'ionicons/icons';

interface OwnProps {
  pageTitle?: string;
  children?: any;
  showDate?: boolean;
  startTools?: any;
  endTools?: any;
}

interface StateProps {
  isStale?: boolean;
  loadDateTime?: Date;
  selectedItems?: CoinValueItem[];
  isMenuVisible?: boolean;
}

interface DispatchProps {
  hideMenu: typeof hideMenu;
  showMenu: typeof showMenu;
}

interface CommonFooterProps extends OwnProps, StateProps, DispatchProps {}

/**
 * Provides a reusable search input box that can route to a given page
 * @param pageTitle
 * @param children
 * @param startTools
 * @param endTools
 * @param isStale
 * @param loadDateTime
 * @param showDate
 * @param selectedItems
 * @param isMenuVisible
 * @param hideMenu
 * @param showMenu
 * @constructor
 */
const CommonFooter: React.FC<CommonFooterProps> = ({
  isStale,
  loadDateTime,
  showDate,
  isMenuVisible,
  hideMenu,
  showMenu,
  endTools,
}) => {
  return (
    <IonFooter class={'common-header ion-no-border'}>
      {/*header always on top*/}

      {showDate && (
        <IonToolbar className={'ion-text-center'}>
          {!!showDate && !isStale && loadDateTime ? (
            <>
              <IonText color={'medium'}>
                Loaded {loadDateTime?.toLocaleDateString()} @ {loadDateTime?.toLocaleTimeString()}
              </IonText>
              {endTools && <>{endTools}</>}
              <IonButtons slot={'primary'}>
                <IonMenuToggle
                  className={'ion-hide-md-down'}
                  auto-hide={false}
                  menu={'pricingMenu'}
                  onClick={() => (isMenuVisible ? hideMenu() : showMenu())}
                >
                  {
                    <IonButton>
                      <IonIcon icon={albumsOutline} slot={'start'} />
                      {isMenuVisible ? 'Hide' : 'Show'} List
                      {/*<ItemSelectionBadge count={selectedItems?.length} />*/}
                    </IonButton>
                  }
                </IonMenuToggle>
              </IonButtons>
            </>
          ) : (
            <IonSpinner color={'medium'} name='dots' />
          )}
        </IonToolbar>
      )}
    </IonFooter>
  );
};

export default connect<OwnProps, StateProps>({
  mapStateToProps: (state, ownProps) => ({
    children: ownProps.children,
    isStale: state.data.isStale,
    loadDateTime: state.data.loadDateTime,
    selectedItems: state.item.selectedItems,
    isMenuVisible: state.item.isMenuVisible,
  }),
  mapDispatchToProps: {
    hideMenu,
    showMenu,
  },
  component: React.memo(CommonFooter),
});
