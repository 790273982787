import React from 'react';
import { Route, RouteComponentProps } from 'react-router';
import { IonContent, IonPage, IonRouterOutlet } from '@ionic/react';
import { connect } from '../data/connect';
import { BidSheet, CoinModel, Options } from '../models/types';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { loadBidSheetValueData, setSelectedBidSheet, setSelectedModel } from '../data/sessions/sessions.actions';
import CommonFooter from '../components/layout/CommonFooter';
import BidSheetDetail from './BidSheetDetail';
import BidSheetList from './BidSheetList';

interface OwnProps extends RouteComponentProps {
  bidSheet: BidSheet;
  options: Options;
  isAdmin: boolean;
}

interface StateProps {
  selectedModel: CoinModel;
  selectedBidSheet: BidSheet;
  bidSheetId: number;
  loading: boolean;
  bidSheets: BidSheet[];
}

interface DispatchProps {
  loadBidSheetValueData: typeof loadBidSheetValueData;
  setSelectedModel: typeof setSelectedModel;
  setSelectedBidSheet: typeof setSelectedBidSheet;
}

interface PublishedSheetsProps extends OwnProps, StateProps, DispatchProps {}

const PublishedSheets: React.FC<PublishedSheetsProps> = ({ match }) => {
  return (
    <IonPage>
      <IonContent scrollEvents={true} scrollX={true}>
        <IonRouterOutlet>
          <Route exact path={`${match.url}/`} component={BidSheetList} />
          <Route path={`${match.url}/:bidSheetId`} component={BidSheetDetail} />
        </IonRouterOutlet>
      </IonContent>
      <CommonFooter showDate={true} />
    </IonPage>
  );
};

export default withAuthenticationRequired(
  connect({
    mapStateToProps: (state, ownProps) => ({
      bidSheetId: ownProps?.match.params['bidSheetId'],
      selectedBidSheet: state.data.selectedBidSheet,
      options: state.data.options,
      selectedModel: state.data.selectedModel,
      isAdmin: state.user.isAdmin,
      userToken: state.user.userToken,
      bidSheets: state.data.bidSheets,
    }),
    mapDispatchToProps: {
      loadBidSheetValueData,
      setSelectedModel,
      setSelectedBidSheet,
    },
    component: PublishedSheets,
  }),
);
