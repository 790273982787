import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  IonBreadcrumb,
  IonBreadcrumbs,
  IonButton,
  IonContent,
  IonIcon,
  IonHeader,
  IonPage,
  IonText,
  IonToolbar,
  useIonAlert,
  useIonModal,
  useIonViewDidEnter,
  IonNote,
  useIonToast,
} from '@ionic/react';
import { connect } from '../data/connect';
import ItemSelectionList from '../components/items/ItemSelectionList';
import { CoinGradeValue, CoinModel, CoinValueItem, Options, SelectedItem } from '../models/types';
import PricingFeedbackForm from '../components/PricingFeedbackForm';
import { removeAll, selectItem, selectItems, selectSheet } from '../data/item/item.actions';
import ExpandingItemWorksheet from '../components/items/ExpandingItemWorksheet';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { trpc } from '../util/trpc';
import { useRTTPostItem } from '../hooks/useRTTPostItem';
import {
  albumsOutline,
  addCircleOutline,
  saveOutline,
  trashOutline,
  analyticsOutline,
  pricetagOutline,
  pricetagsOutline,
} from 'ionicons/icons';
import ExportModalButton from '../components/common/ExportModalButton';
import { useWorksheetModal } from '../hooks/useWorksheetModal';
import { useExport } from '../hooks/useExport';
import { setSelectedItemsData } from '../data/dataApi';
import { ValueSheet } from 'src/services/valueSheets/models/valueSheet';
import { useHistory } from 'react-router';
import PopoverMenuButton from '../components/common/PopoverMenuButton';
import NewSheetModalButton from '../components/common/NewSheetModalButton';

interface OwnProps {}

interface StateProps {
  userToken: string;
  selectedTotal: number;
  coinModels: CoinModel[];
  coinValues: CoinGradeValue[];
  options: Options;
  selectedItems: CoinValueItem[];
  selectedItemGroups: CoinValueItem[];
  selectedSheetId: number;
  matchBy: string[];
}

interface DispatchProps {
  removeAll: typeof removeAll;
  selectItem: typeof selectItem;
  selectSheet: typeof selectSheet;
  selectItems: typeof selectItems;
  sheetId: string | number;
  setSelectedItemsData: typeof setSelectedItemsData;
}

interface WorksheetDetailProps extends OwnProps, StateProps, DispatchProps {}

const WorksheetDetail: React.FC<WorksheetDetailProps> = ({
  selectedTotal,
  selectedItems,
  selectedItemGroups,
  coinModels,
  removeAll,
  sheetId,
  selectSheet,
  selectedSheetId,
  selectItems,
  setSelectedItemsData,
  matchBy,
  options,
}) => {
  const [miscItemModel, setMiscItemModel] = useState<CoinModel>();
  const [presentAlert] = useIonAlert();
  const { postToFeedbackForum } = useRTTPostItem();
  const valueSheet = trpc.valueSheets.getById.useQuery({ id: selectedSheetId });
  const { presentSaveAs } = useWorksheetModal();
  const { exportFlat, exportGrouped } = useExport();
  const [message] = useIonToast();
  const trpcContext = trpc.useContext();
  const history = useHistory();

  // set the misc model for use
  useEffect(() => {
    if (coinModels?.length) setMiscItemModel(coinModels?.find((m) => m.name === 'Misc'));
  }, [coinModels]);

  useIonViewDidEnter(() => {
    console.debug(`selecting sheet from ionViewDidEnter using sheetId: ${sheetId}`);
    selectSheet(Number(sheetId));
  }, [sheetId]);

  const selectItemsCallback = useCallback(
    (newItems: SelectedItem[]) => {
      if (coinModels && coinModels.length) {
        console.debug(`selecting ${newItems?.length} items from valueSheet ${selectedSheetId}...`);
        selectItems(selectedItems, newItems, coinModels, matchBy);
      }
    },
    // eslint-disable-next-line
    [coinModels],
  );

  // when value sheet changes, select the items from it
  // provided it isn't saving
  useMemo(() => {
    console.debug(`fetch status is`, valueSheet?.fetchStatus);
    // if (selectedSheetId !== 0 && valueSheet.data?.data && valueSheet?.fetchStatus) {
    if (selectedSheetId !== 0 && valueSheet?.isFetched) {
      selectItemsCallback(valueSheet?.data?.data?.items?.length ? valueSheet?.data?.data?.items : []);
    }
    // }
    // eslint-disable-next-line
  }, [valueSheet?.data?.data, valueSheet?.isFetched, selectedSheetId, coinModels]);

  const submitFeedback = (items: CoinValueItem[]) => {
    postToFeedbackForum({
      title: `CoinBidz Feedback for ${items?.length} item(s)...

      ${items.map((v) => `${v.model?.name}: $${v.eachAmount}`).join('<br />')}
      Total is $${selectedTotal}

      - Originated in CoinBidz.`,
      postType: 3,
      description: ``,
      commentsAllowed: true,
      // tags:
    });
  };
  console.debug(`confirming presence of submitFeeback: `, submitFeedback.name);

  /**
   * First parameter is the component to show, second is the props to pass
   */
  const [present, dismiss] = useIonModal(PricingFeedbackForm, {
    coinGradeValue: {},
    coinModel: miscItemModel,
    grade: 'ANY',
    options,
    onDismiss: () => {
      dismiss();
    },
  });
  console.debug(present.name);

  const deleteValueSheet = trpc.valueSheets.deleteSheet.useMutation({
    onMutate: (variables) => {
      // A mutation is about to happen!
      console.debug('deleting sheet using: ', variables);
      // Optionally return a context containing data to use when for example rolling back
      return { id: variables.id };
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.debug(`error: rolling back delete with id ${context?.id}`, { error, variables, context });
      message(`Unable to delete sheet '${context?.id}'`, 5000);
    },
    onSuccess: (data, variables, context) => {
      // Boom baby!
      console.debug(`deleted valuesheet ${context?.id}`, { data, variables, context });
      message(`Deleted sheet '${context?.id}'`, 3000);

      // invalidate so queries will refresh
      trpcContext.valueSheets.getAllValueSheets.invalidate();

      // remove if active sheet
      if (context?.id === selectedSheetId) {
        removeAll();
        setSelectedItemsData([]);
        selectSheet(0);
        history.replace('/tabs/selectedItems/0');
      }
    },
    // onSettled: (data, error, variables, context) => {
    //   // Error or success... doesn't matter!
    // },
  });

  const handleDelete = (sheet: ValueSheet) => {
    presentAlert({
      header: `Confirm`,
      message: `Delete '${sheet?.name}' and
      remove ${selectedItems?.length} item(s)? This action CANNOT be undone.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Delete',
          role: 'confirm',
          handler: () => {
            // process delete handler
            deleteValueSheet.mutate({ id: sheet.id });
          },
        },
      ],
    });
  };

  const handleNew = (priceCode: string) => {
    console.debug(`new sheet created with priceCode: `, priceCode);
    selectSheet(0);
    removeAll();
    setSelectedItemsData([]);

    // can I save the sheet with random name?

    history.push('/tabs/selectedItems/0');
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          {/*{selectedSheetId != 0 && (*/}
          <div className={'ion-margin ion-float-start'}>
            <NewSheetModalButton
              onNewSheet={(priceCode: string) => {
                console.debug(`new priceCode is: `, priceCode);
                handleNew(priceCode);
              }}
              disabled={false}
              // require user confirmation when we have an unsaved new sheet
              requireConfirm={selectedSheetId == 0 && selectedItems?.length > 0}
            />
          </div>
          {/*)}*/}
          <IonBreadcrumbs slot={'start'}>
            <IonBreadcrumb routerLink={'/tabs/selectedItems'}>
              <IonIcon icon={albumsOutline} slot={'start'} />
              <span className={'ion-hide-sm-down'}>Worksheets</span>
            </IonBreadcrumb>

            {/*OPTIONAL showing of title and everything here based on the state*/}

            {valueSheet && (
              <IonBreadcrumb>{valueSheet?.data?.data?.name ? valueSheet?.data?.data?.name : 'Untitled'}</IonBreadcrumb>
            )}

            {/*{valueSheet && (*/}
            {/*  <IonBreadcrumb>*/}
            {/*    {valueSheet?.data?.data?.name ? valueSheet?.data?.data?.name : 'Untitled'}*/}
            {/*    <PopoverMenuButton*/}
            {/*      types={[*/}
            {/*        {*/}
            {/*          label: 'New',*/}
            {/*          icon: createOutline,*/}
            {/*          onSelect: () => present({ cssClass: 'rounded-modal', backdropDismiss: true }),*/}
            {/*        },*/}
            {/*        {*/}
            {/*          label: 'Open',*/}
            {/*          icon: folderOpenOutline,*/}
            {/*          onSelect: () => present({ cssClass: 'rounded-modal', backdropDismiss: true }),*/}
            {/*        },*/}
            {/*        {*/}
            {/*          label: 'Edit Details',*/}
            {/*          icon: informationCircleOutline,*/}
            {/*          onSelect: () => present({ cssClass: 'rounded-modal', backdropDismiss: true }),*/}
            {/*        },*/}
            {/*        {*/}
            {/*          label: 'Save As',*/}
            {/*          icon: saveOutline,*/}
            {/*          onSelect: () => history.push('/tabs/catalog'),*/}
            {/*        },*/}
            {/*        {*/}
            {/*          label: 'Export',*/}
            {/*          icon: downloadOutline,*/}
            {/*          onSelect: () => history.push('/tabs/genericBids'),*/}
            {/*        },*/}
            {/*        {*/}
            {/*          label: 'Delete',*/}
            {/*          icon: trashBinOutline,*/}
            {/*          onSelect: () => history.push('/tabs/genericBids'),*/}
            {/*        },*/}
            {/*      ]}*/}
            {/*    >*/}
            {/*      <>*/}
            {/*        <IonIcon icon={ellipsisHorizontalCircleOutline} slot={'start'} />*/}
            {/*        <IonText>Menu</IonText>*/}
            {/*      </>*/}
            {/*    </PopoverMenuButton>*/}
            {/*  </IonBreadcrumb>*/}
            {/*)}*/}
          </IonBreadcrumbs>

          <div slot={'end'}>
            <IonNote class={'ion-hide'}>{valueSheet?.fetchStatus}...</IonNote>
            {/*TODO: need to utilize passthrough properties to style it*/}
            <PopoverMenuButton
              types={[
                {
                  label: 'Miscellaneous Item',
                  icon: addCircleOutline,
                  onSelect: () => present({ cssClass: 'rounded-modal', backdropDismiss: true }),
                },
                {
                  label: 'Catalog Coin',
                  icon: analyticsOutline,
                  onSelect: () => history.push('/tabs/catalog'),
                },
                {
                  label: 'Generic Item',
                  icon: pricetagOutline,
                  onSelect: () => history.push('/tabs/genericBids'),
                },
                {
                  label: 'Bullion Item',
                  icon: pricetagsOutline,
                  onSelect: () => history.push('/tabs/genericBids'),
                },
              ]}
            >
              <>
                <IonIcon icon={addCircleOutline} slot={'start'} />
                <IonText>Add Item</IonText>
              </>
            </PopoverMenuButton>
            <IonButton
              className={'ion-hide-sm-down'}
              color={'primary'}
              onClick={presentSaveAs}
              // disabled={(selectedSheetId && selectedItems?.length == 0) || false}
            >
              <IonIcon icon={saveOutline} slot={'start'} />
              <IonText>Save As</IonText>
            </IonButton>
            {/*{sheetId == 0 && (*/}
            {/*  <IonButton*/}
            {/*    className={'ion-hide-sm-down'}*/}
            {/*    color={'danger'}*/}
            {/*    fill={'outline'}*/}
            {/*    onClick={() => {*/}
            {/*      confirmReset();*/}
            {/*    }}*/}
            {/*    // disabled={selectedSheetId !== 0 ? selectedSheetId == 0 &&
            selectedItemGroups?.length == 0 : false}*/}
            {/*  >*/}
            {/*    <IonIcon icon={trashOutline} color={'danger'} slot={'start'} />*/}
            {/*    <IonText>Clear</IonText>*/}
            {/*  </IonButton>*/}
            {/*)}*/}

            <ExportModalButton
              disabled={selectedItems?.length == 0 || false}
              onFlatCsv={() =>
                exportFlat(
                  selectedItems.filter((si) => !si.isDeleted),
                  valueSheet?.data?.data?.name,
                  options,
                )
              }
              onGroupedCsv={() => exportGrouped(selectedItemGroups, valueSheet?.data?.data?.name, options)}
            />
            {selectedSheetId != 0 && (
              <IonButton
                className={'ion-hide-sm-down'}
                color={'danger'}
                onClick={() => {
                  handleDelete(valueSheet.data?.data);
                }}
                // disabled={selectedSheetId !== 0 ? selectedSheetId == 0 && selectedItemGroups?.length == 0 : false}
              >
                <IonIcon icon={trashOutline} />
              </IonButton>
            )}
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className={'ion-hide-sm-up'} style={{ height: '100% - 1em', width: '100%' }}>
          <ItemSelectionList sortBy={[]} groupBy={[]} showMenu={true} />
        </div>
        <div className={'ion-hide-sm-down ion-no-margin ion-no-margin'} style={{ height: '100%', width: '100%' }}>
          <ExpandingItemWorksheet />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default withAuthenticationRequired(
  connect({
    mapStateToProps: (state, ownProps) => ({
      sheetId: ownProps?.match.params['sheetId'],
      userToken: state.user.userToken,
      selectedItems: state.item.selectedItems,
      selectedTotal: state.item.selectedTotal,
      coinModels: state.data.coinModels,
      coinValues: state.data.coinValues,
      options: state.data.options,
      selectedItemGroups: state.item.selectedItemGroups,
      selectedSheetId: state.item.selectedSheetId,
      matchBy: state.item.matchBy,
    }),
    mapDispatchToProps: {
      removeAll,
      selectItem,
      selectSheet,
      selectItems,
      setSelectedItemsData,
    },
    component: WorksheetDetail,
  }),
);
