import React from 'react';
import { CoinValueItem } from '../../models/types';
import QuantitySelector from '../common/QuantitySelector';

export interface QuantityCellRendererParams {
  data?: any;
  value?: number;
  onChange?: (item: CoinValueItem, matchBy: string[], quantity?: number) => void;
  onRemove: (item: CoinValueItem) => void;
  matchCols?: string[];
}

export const QuantityCellRenderer: React.FC<QuantityCellRendererParams> = ({
  value,
  data,
  onChange,
  onRemove,
  matchCols,
}) => {
  return (
    <div style={{ height: '1em' }}>
      {data?.model?.id && data?.model.measure === 'qty' && (
        <QuantitySelector
          quantity={value || 1}
          increment={1}
          // precision={2} // for FV - 0?
          allowDelete={false}
          onChange={(newQty: number) => {
            if (onChange) {
              onChange(data, matchCols || [], newQty);
            }
          }}
          onDelete={() => {
            return onRemove && onRemove(data);
          }}
        />
      )}
      {data?.model?.id && data?.model.measure === 'fv' && (
        <QuantitySelector
          quantity={data?.faceValue || 0}
          increment={0.05}
          precision={2} // for FV
          allowDelete={false}
          onChange={(newQty: number) => {
            if (onChange) {
              onChange({ ...data, faceValue: newQty }, matchCols || [], 1);
            }
          }}
          onDelete={() => {
            return onRemove && onRemove(data);
          }}
        />
      )}
    </div>
  );
};
