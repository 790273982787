import React from 'react';
import {
  IonBreadcrumb,
  IonBreadcrumbs,
  IonContent,
  IonIcon,
  IonItem,
  IonHeader,
  IonLabel,
  IonList,
  IonPage,
  IonToolbar,
  IonCard,
  IonCardContent,
} from '@ionic/react';
import { connect } from '../data/connect';
import { CoinGradeValue, CoinModel, CoinValueItem, Options } from '../models/types';
import { removeAll, selectItem, selectSheet } from '../data/item/item.actions';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { trpc } from '../util/trpc';
import { ValueSheet } from 'src/services/valueSheets/models/valueSheet';
import { albumsOutline } from 'ionicons/icons';
import { useFormat } from '../hooks/useFormat';
import { Link } from 'react-router-dom';
import { setSelectedItemsData } from '../data/dataApi';

interface OwnProps {}

interface StateProps {
  userToken: string;
  selectedTotal: number;
  coinModels: CoinModel[];
  coinValues: CoinGradeValue[];
  options: Options;
  selectedItems: CoinValueItem[];
  selectedItemGroups: CoinValueItem[];
  selectedSheetId: number;
}

interface DispatchProps {
  removeAll: typeof removeAll;
  selectItem: typeof selectItem;
  selectSheet: typeof selectSheet;
  setSelectedItemsData: typeof setSelectedItemsData;
}

interface ProfileTabProps extends OwnProps, StateProps, DispatchProps {}

const SelectedItems: React.FC<ProfileTabProps> = ({ selectedSheetId, selectedItems }) => {
  const valueSheets = trpc.valueSheets.getAllValueSheets.useQuery({
    // cursor: 0,
    // limit: 0,
  });
  const { formatAsDateTime } = useFormat();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonBreadcrumbs slot={'start'}>
            <IonBreadcrumb routerLink={'/tabs/selectedItems'}>
              <IonIcon icon={albumsOutline} slot={'start'} />
              <span className={'ion-hide-sm-down'}>Worksheets</span>
            </IonBreadcrumb>
          </IonBreadcrumbs>
          <div slot={'end'} className={'ion-margin-right ion-padding-right'} />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {selectedSheetId == 0 && selectedItems?.length > 0 && (
          <IonCard color={'danger'}>
            <IonCardContent>
              You have {selectedItems?.length} items selected as a&nbsp;
              <Link to={'/tabs/selectedItems/0'}>draft worksheet</Link>. Selecting an existing worksheet will replace
              this and cannot be undone.
            </IonCardContent>
          </IonCard>
        )}
        <IonList>
          {valueSheets &&
            valueSheets.data &&
            valueSheets.data.data.map((valueSheet: ValueSheet, index: number) => (
              <IonItem
                key={`${valueSheet.id}_${index}`}
                style={{ maxWidth: '50em' }}
                detail={true}
                routerLink={`/tabs/selectedItems/${valueSheet.id}`}
              >
                <IonLabel>
                  <h2>{valueSheet.name}</h2>
                  <p>{valueSheet.comments}</p>
                </IonLabel>
                <IonLabel slot={'end'}>
                  <h2>{''}</h2>
                  <p>{formatAsDateTime(valueSheet.updatedAt)}</p>
                </IonLabel>
              </IonItem>
            ))}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default withAuthenticationRequired(
  connect<OwnProps, StateProps, DispatchProps>({
    mapStateToProps: (state) => ({
      userToken: state.user.userToken,
      selectedItems: state.item.selectedItems,
      selectedTotal: state.item.selectedTotal,
      coinModels: state.data.coinModels,
      coinValues: state.data.coinValues,
      options: state.data.options,
      selectedItemGroups: state.item.selectedItemGroups,
      selectedSheetId: state.item.selectedSheetId,
    }),
    mapDispatchToProps: {
      removeAll,
      selectItem,
      selectSheet,
      setSelectedItemsData,
    },
    component: SelectedItems,
  }),
);
