import React from 'react';
import {
  IonMenu,
  IonContent,
  IonIcon,
  IonToolbar,
  IonButtons,
  IonButton,
  IonMenuToggle,
  IonText,
  IonFooter,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonHeader,
} from '@ionic/react';
import { connect } from '../../data/connect';
import { RouteComponentProps, withRouter } from 'react-router';
import { CoinCategory, CoinType, CoinValueItem, Grade } from '../../models/types';
import ItemSelectionList from '../items/ItemSelectionList';
import { closeOutline, pencil } from 'ionicons/icons';
import { hideMenu, showMenu } from '../../data/item/item.actions';

interface StateProps {
  selectedCategory: CoinCategory;
  selectedType: CoinType;
  activeGrades: Grade[];
  isAdmin: boolean;
  coinCategories: CoinCategory[];
  coinTypes: CoinType[];
  isMenuVisible: boolean;
  selectedItems: CoinValueItem[];
  selectedTotal: number;
  selectedSheetId: number;
}

interface DispatchProps {
  hideMenu: typeof hideMenu;
  showMenu: typeof showMenu;
}

interface PricingMenuInputProps extends RouteComponentProps, StateProps, DispatchProps {
  onDismiss: () => void;
  onSelect: (text: string, route: string) => void;
}

const PricingMenu: React.FC<PricingMenuInputProps> = ({
  isMenuVisible,
  hideMenu,
  selectedItems,
  selectedTotal,
  selectedSheetId,
}) => {
  return (
    <IonMenu side='end' contentId='main' menuId='pricingMenu' type='overlay' disabled={!isMenuVisible}>
      <IonHeader class={'common-header ion-no-border'}>
        <IonToolbar style={{ height: '6em' }}>
          <IonButtons slot={'end'} color={'primary'}>
            <IonMenuToggle
              auto-hide={false}
              menu={'pricingMenu'}
              onClick={() => {
                if (isMenuVisible) hideMenu();
              }}
            >
              <IonButton>
                <IonIcon slot={'icon-only'} icon={closeOutline} />
              </IonButton>
            </IonMenuToggle>
          </IonButtons>
        </IonToolbar>
        <IonToolbar class={'ion-hide-sm-up'}>
          <IonButton
            expand={'block'}
            routerLink={`/tabs/selectedItems/${selectedSheetId || 0}`}
            onClick={() => hideMenu()}
          >
            <IonIcon icon={pencil} />
            <IonText color={'secondary'}>Edit & Continue</IonText>
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ItemSelectionList sortBy={['model.id']} groupBy={['model.id']} />
      </IonContent>
      <IonFooter>
        <IonItem color={'light'} lines={'none'}>
          <IonGrid>
            <IonRow class='ion-padding-bottom ion-padding-top'>
              <IonCol size={'8'}>
                <IonLabel class='ion-text-wrap'>Total ({selectedItems?.length})</IonLabel>
              </IonCol>
              <IonCol size={'4'} class='ion-text-right'>
                {selectedTotal
                  ? `$${Number(selectedTotal).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`
                  : `$0`}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
      </IonFooter>
    </IonMenu>
  );
};

export default connect<{}, StateProps>({
  mapStateToProps: (state) => ({
    selectedCategory: state.data.selectedCategory,
    selectedType: state.data.selectedType,
    activeGrades: state.data.activeGrades,
    isAdmin: state.data.activeGrades,
    coinCategories: state.data.coinCategories,
    coinTypes: state.data.coinTypes,
    isMenuVisible: state.item.isMenuVisible || false,
    selectedItems: state.item.selectedItems,
    selectedTotal: state.item.selectedTotal,
    selectedSheetId: state.item.selectedSheetId,
  }),
  mapDispatchToProps: {
    hideMenu,
  },
  component: withRouter(PricingMenu),
});
