import { useIonModal } from '@ionic/react';
import { useHistory } from 'react-router';
import SearchForm from '../components/SearchForm';
import { CoinModel } from '../models/types';

export function useSearch(): any {
  const history = useHistory();

  /**
   * First parameter is the component to show, second is the props to pass
   */
  const [present, dismiss] = useIonModal(SearchForm, {
    onSelect: (text: string, cm: CoinModel) => {
      dismiss();
      // go to the route
      console.debug(`closed search for ${text} resulted in model`, cm);
      history.push(`/tabs/catalog/${cm.typeId}`);
    },
    onDismiss: () => {
      dismiss();
    },
  });

  /**
   * Show the search window.
   * @param searchTerm
   */
  const openSearch = async (searchTerm?: string) => {
    console.debug(`opening search with search term ${searchTerm}`);
    present({ cssClass: 'rounded-modal', backdropDismiss: true });
    // pick values in order to show
  };

  return {
    openSearch,
  } as const;
}
