import { IonBadge, IonCol, IonGrid, IonItem, IonLabel, IonList, IonListHeader, IonRow } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { CoinGradeValue, CoinModel, CoinType, Options } from '../../models/types';
import { connect } from '../../data/connect';
import { getCoinValueAuctionResults, getCoinValueSourceBids } from '../../data/dataApi';
import * as _ from 'lodash';

interface OwnProps {
  value?: CoinGradeValue;
  model?: CoinModel;
}

interface StateProps {
  userToken: string;
  selectedModel?: CoinModel;
  selectedType?: CoinType;
  options?: Options;
}

interface ValuesListProps extends OwnProps, StateProps {}

const ValuesList: React.FC<ValuesListProps> = ({ value, userToken }) => {
  const [sourceBids, setSourceBids] = useState<CoinGradeValue[]>();
  const [auctionResults, setAuctionResults] = useState<CoinGradeValue[]>();
  const [values, setValues] = useState<CoinGradeValue[]>();

  // initialize value
  useEffect(() => {
    if (value && value.grade && value.modelId) {
      getCoinValueSourceBids(userToken, value.id).then((values) => setSourceBids(values));
      getCoinValueAuctionResults(userToken, value.id).then((values) => setAuctionResults(values));
    } else {
      // do we need to reset to zero here?
      setSourceBids([]);
      setAuctionResults([]);
    }
  }, [value, userToken]);

  // initialize value
  useEffect(() => {
    if (sourceBids && auctionResults) {
      const values = _.concat(sourceBids, auctionResults); // , ['updatedAt']);
      const sortedValues = _.sortBy(values, [(v) => v.updatedAt?.epoch]).reverse();
      setValues(sortedValues);
    } else {
      setValues([]);
    }
  }, [sourceBids, auctionResults]);

  return (
    <>
      {values && values.length > 0 && (
        <IonList>
          <IonListHeader>
            <IonLabel>Source Values:</IonLabel>
          </IonListHeader>
          {values.map((cgv: CoinGradeValue, index: number) => (
            <IonItem key={index}>
              <IonGrid>
                <IonRow>
                  <IonCol size={'3'} class={'ion-hide-sm-down'}>
                    <IonLabel class='ion-text-wrap'>{cgv?.formattedUpdatedAt}</IonLabel>
                  </IonCol>
                  <IonCol size={'3'}>
                    <IonBadge color={`${cgv.priceCode === 'bid' ? 'success' : 'primary'}`}>{cgv.priceCode}</IonBadge>
                  </IonCol>
                  <IonCol sizeXs={'6'} sizeMd={'3'}>
                    {cgv.priceSource}
                  </IonCol>
                  <IonCol size={'3'} class='ion-text-right'>
                    <IonLabel>
                      ${cgv.value} {cgv.flatAdder ? `(${cgv.flatAdder})` : ''}
                      {cgv.percentAdder ? `(${cgv.percentAdder})` : ''}
                    </IonLabel>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonItem>
          ))}
        </IonList>
      )}
    </>
  );
};

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state, ownProps: OwnProps) => ({
    userToken: state.user.userToken,
    value: ownProps.value,
    selectedModel: state.data.selectedModel,
    selectedType: state.data.selectedType,
    options: state.data.options,
  }),
  mapDispatchToProps: {},
  component: ValuesList,
});
