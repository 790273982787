import React, { useEffect, useState } from 'react';
import { connect } from '../data/connect';
import './layout/CommonHeader.scss';
import { CoinCategory, CoinType } from '../models/types';
import * as _ from 'lodash';
import CategoryCard from './CategoryCard';
import { IonCol, IonGrid, IonRow } from '@ionic/react';

interface OwnProps {}

interface StateProps {
  categories: CoinCategory[];
  coinTypes: CoinType[];
}

interface DispatchProps {}

interface CategoryListingProps extends OwnProps, StateProps, DispatchProps {}

/**
 * Provides a reusable search input box that can route to a given page
 * @param pageTitle
 * @param children
 * @param startTools
 * @param endTools
 * @param isStale
 * @param selectedItems
 * @param isMenuVisible
 * @param hideMenu
 * @param showMenu
 * @constructor
 */
const CateogryListing: React.FC<CategoryListingProps> = ({ categories, coinTypes }) => {
  const [groupedData, setGroupedData] = useState<any>();

  useEffect(() => {
    if (coinTypes) {
      // create grouped categories
      const groupedData = _.groupBy(
        _.sortBy(coinTypes, ['categoryId', 'sortOrder']),
        (type: CoinType) => type.categoryId,
      );
      setGroupedData(groupedData);
    }
  }, [coinTypes]);

  return (
    <IonGrid fixed={false}>
      <IonRow>
        <IonCol>
          {categories?.length &&
            groupedData &&
            categories
              .slice(0, categories.length / 3 + 1)
              .map((category: CoinCategory, categoryIndex: number) => (
                <CategoryCard
                  key={categoryIndex}
                  category={category}
                  coinTypes={groupedData[category?.id] as CoinType[]}
                />
              ))}
        </IonCol>
        <IonCol>
          {categories?.length &&
            groupedData &&
            categories
              .slice(categories.length / 3 + 1, (categories.length / 3) * 2)
              .map((category: CoinCategory, categoryIndex: number) => (
                <CategoryCard
                  key={categoryIndex}
                  category={category}
                  coinTypes={groupedData[category?.id] as CoinType[]}
                />
              ))}
        </IonCol>
        <IonCol>
          {categories?.length &&
            groupedData &&
            categories
              .slice((categories.length / 3) * 2)
              .map((category: CoinCategory, categoryIndex: number) => (
                <CategoryCard
                  key={categoryIndex}
                  category={category}
                  coinTypes={groupedData[category?.id] as CoinType[]}
                />
              ))}
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default connect<OwnProps, StateProps>({
  mapStateToProps: (state) => ({
    categories: state.data.coinCategories,
    coinTypes: state.data.coinTypes,
  }),
  mapDispatchToProps: {},
  component: React.memo(CateogryListing),
});
