import React, { useCallback, useEffect, useMemo } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonSplitPane, isPlatform, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Menu from './components/layout/Menu';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import './App.scss';

import MainTabs from './pages/MainTabs';
import { connect } from './data/connect';
import { AppContextProvider } from './data/AppContext';
import { loadCoinCatalogData, loadCoinCategoryData, loadDealerData } from './data/sessions/sessions.actions';
import { loadUserData, setIsAdmin, setUsername, setUserToken } from './data/user/user.actions';
import { useAuth0 } from '@auth0/auth0-react';
import PricingMenu from './components/layout/PricingMenu';
import { CoinValueItem, Options } from './models/types';
import { loadItemData } from './data/item/item.actions';
import { trpc } from './util/trpc';
import { useIonToast } from '@ionic/react';
import { setSelectedItemsData } from './data/dataApi';

setupIonicReact({
  backButtonText: '',
  swipeBackEnabled: false,
});

const App: React.FC = () => {
  return (
    <>
      <AppContextProvider>
        <IonicAppConnected />
      </AppContextProvider>
      {/*TODO debugging capabilities*/}
      {/*<ReactQueryDevtools />*/}
      {/*<TRPCReactQueryDevtools position="bottom-right" />*/}
    </>
  );
};

interface StateProps {
  darkMode: boolean;
  isStale: boolean;
  userToken: string;
  isLoading: string;
  options: Options;
  selectedItems: CoinValueItem[];
  selectedSheetId: number;
}

interface DispatchProps {
  loadDealerData: typeof loadDealerData;
  loadCoinCategoryData: typeof loadCoinCategoryData;
  loadUserData: typeof loadUserData;
  setUsername: typeof setUsername;
  loadCoinCatalogData: typeof loadCoinCatalogData;
  setIsAdmin: typeof setIsAdmin;
  setUserToken: typeof setUserToken;
  loadItemData: typeof loadItemData;
  setSelectedItemsData: typeof setSelectedItemsData;
}

interface IonicAppProps extends StateProps, DispatchProps {}

const IonicApp: React.FC<IonicAppProps> = ({
  userToken,
  isStale,
  darkMode,
  loadUserData,
  loadDealerData,
  loadCoinCategoryData,
  loadCoinCatalogData,
  setIsAdmin,
  setUserToken,
  loadItemData,
  selectedItems,
  selectedSheetId,
  setSelectedItemsData,
}) => {
  const { isAuthenticated, user, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const [present] = useIonToast();
  const trpcContext = trpc.useContext();

  // mutation for selectedItems
  const updateItems = trpc.valueSheets.updateItems.useMutation({
    onMutate: (variables: any) => {
      // A mutation is about to happen!
      console.debug('updateItems using: ', variables);
      // Optionally return a context containing data to use when for example rolling back
      return { ...variables };
    },
    onError: (error: any, variables: any, context: any) => {
      // An error happened!
      console.debug(`error: rolling back optimistic updateItems ${context?.name}`, { error, variables, context });
      present(`Unable to update items for sheet id='${context?.sheetId}'`, 5000);
    },
    onSuccess: (data: any, variables: any, context: any) => {
      // Boom baby!
      console.debug(`Updated items for sheet id=${data}.`, { data, variables, context });
      // present(`Updated items for sheet id='${data}'`, 3000);

      trpcContext.valueSheets.getById.invalidate();
    },
    // onSettled: (data, error, variables, context) => {
    //   // Error or success... doesn't matter!
    // },
  });

  // mutation for selectedItems
  const createItems = trpc.valueSheets.createItems.useMutation({
    onMutate: (variables: any) => {
      // A mutation is about to happen!
      console.debug('createItems using: ', variables);
      // Optionally return a context containing data to use when for example rolling back
      return { name: variables.sheetId };
    },
    onError: (error: any, variables: any, context: any) => {
      // An error happened!
      console.debug(`error: rolling back optimistic updateItems ${context?.sheetId}`, { error, variables, context });
      present(`Unable to create items (sheet=${context?.sheetId})`, 5000);
    },
    onSuccess: (data: any, variables: any, context: any) => {
      // Boom baby!
      console.debug(`Created items for ${context?.scheetId}.`, { data, variables, context });
      // present(`Created items for sheet '${context?.sheetId}'`, 3000);

      trpcContext.valueSheets.getById.invalidate();
    },
    // onSettled: (data, error, variables, context) => {
    //   // Error or success... doesn't matter!
    // },
  });

  // mutation for selectedItems
  const deleteItems = trpc.valueSheets.deleteItems.useMutation({
    onMutate: (variables: any) => {
      // A mutation is about to happen!
      // console.debug('deleteItems using: ', variables);
      // Optionally return a context containing data to use when for example rolling back
      return { ...variables };
    },
    onError: (error: any, variables: any, context: any) => {
      // An error happened!
      console.debug(`error: rolling back deleteItems ${context?.sheetId}`, { error, variables, context });
      present(`Unable to create items (sheet=${context?.sheetId})`, 5000);
    },
    onSuccess: (data: any, variables: any, context: any) => {
      // Boom baby!
      console.debug(`Deleted items for sheet ${context?.sheetId}.`, { data, variables, context });
      // present(`Deleted items for sheet '${context?.sheetId}'`, 3000);

      trpcContext.valueSheets.getById.invalidate();
    },
    // onSettled: (data, error, variables, context) => {
    //   // Error or success... doesn't matter!
    // },
  });

  useEffect(() => {
    loadUserData();
    loadDealerData();
    loadCoinCategoryData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isAuthenticated && !userToken) {
      const { REACT_APP_AUTH0_AUDIENCE } = process.env;
      getAccessTokenSilently({
        audience: REACT_APP_AUTH0_AUDIENCE,
      })
        .then((token: string) => {
          console.debug(`setting user token`, token);
          setUserToken(token);
        })
        .catch(() => {
          console.warn(`user will need to re-authenticate`);
          if (!isPlatform('mobile')) loginWithRedirect();
        });
    }

    // // Listen to changes in the network state, handle return online.
    // window.addEventListener('online', () => {
    //   console.debug("CoinBidz is online");
    //   loadDealerData();
    // });
    //
    // // Listen to changes in the network state, handle go offline
    // window.addEventListener('offline', () => {
    //   console.log("CoinBidz is offline");
    // });

    // eslint-disable-next-line
  }, [isAuthenticated, userToken]);

  useEffect(() => {
    if (user) {
      setUsername(user?.preferred_username);
      const { REACT_APP_AUTH0_AUDIENCE } = process.env;
      const roles = user?.[`${REACT_APP_AUTH0_AUDIENCE}/roles`] || ([] as string[]);
      setIsAdmin(roles?.indexOf('Admin') !== -1);
    }
    // eslint-disable-next-line
  }, [user]);

  /**
   * Loads data that is token dependant
   */
  useEffect(() => {
    if (userToken && isStale === true) {
      console.debug(`time to load token dependant data...`);
      loadCoinCatalogData(userToken);
    } else if (userToken) {
      // load the selected item data IF the sheet is 0
      if (selectedSheetId == 0) {
        loadItemData();
      }
    }
    // eslint-disable-next-line
  }, [userToken, isStale, selectedSheetId]);

  // trigger store when selected items changes
  useEffect(() => {
    // save to cache
    if (selectedItems?.length && selectedSheetId == 0) {
      setSelectedItemsData(selectedItems);
    }
    // eslint-disable-next-line
  }, [selectedItems, selectedSheetId]);

  /**
   * When selected sheet id changes the getById needs to be invalidated
   */
  useEffect(() => {
    if (selectedSheetId !== 0) {
      console.warn(`selectedSheetId: `, selectedSheetId);
      trpcContext.valueSheets.getById.invalidate();

      // kill the cache - TODO need to do this once when sheet is first opened
      setSelectedItemsData([]);
    }
    // eslint-disable-next-line
  }, [selectedSheetId]);

  const persistSelectedItemsCallback = useCallback(
    (selectedItems: CoinValueItem[]) => {
      if (selectedSheetId == 0) return;

      console.debug(`**************** Save selectedItems changes **************************`);
      console.debug(`${selectedItems?.length} selectedItems: `, selectedItems);

      // only update items w/ ids where not deleted
      const itemsToUpdate = selectedItems?.filter((item) => item.id && item.isChanged && !item.isDeleted);

      const itemsToCreate = selectedItems?.filter((item) => !item.id && !item.isDeleted);

      const itemsToDelete = selectedItems?.filter((item) => item.id && item.isDeleted);

      // TODO cleanup model to be more sensical so this translation isn't necessary
      itemsToUpdate &&
        itemsToUpdate.length > 0 &&
        updateItems.mutate({
          sheetId: selectedSheetId,
          items: itemsToUpdate?.map((item) => ({
            id: item.id ?? 0,
            comments: item.comments,
            faceValue: Number(item.faceValue || 0.0),
            valueId: item.valueId ?? 0,
            sheetId: item.sheetId ?? 0,
            certificationService: item.certificationService ?? 1,
          })),
          values: itemsToUpdate.map((item) => ({
            id: item.valueId ?? 0,
            modelId: item.model?.id,
            value: Number(item.eachAmount || 0.0),
            grade: item.grade || '',
            priceSource: '',
            priceCode: 'worksheet',
          })),
        });

      itemsToCreate &&
        itemsToCreate.length > 0 &&
        createItems.mutate({
          sheetId: selectedSheetId,
          items: itemsToCreate?.map((item) => ({
            comments: item.comments,
            faceValue: Number(item.faceValue || 0.0),
            certificationService: item.certificationService ?? 1,
          })),
          values: itemsToCreate.map((item) => ({
            modelId: item.model?.id,
            value: Number(item.eachAmount || 0.0),
            grade: item.grade || '',
            priceSource: '',
            priceCode: 'worksheet',
          })),
        });

      itemsToDelete &&
        itemsToDelete.length > 0 &&
        deleteItems.mutate({
          sheetId: selectedSheetId,
          items: itemsToDelete?.map((item) => ({
            id: item.id ?? 0,
            valueId: item.valueId ?? 0,
          })),
        });
    },
    // eslint-disable-next-line
    [selectedSheetId],
  );

  // when selected items changes
  useMemo(() => {
    // _.differenceWith(objects, [{ 'x': 1, 'y': 2 }], _.isEqual);
    persistSelectedItemsCallback(selectedItems);
    // eslint-disable-next-line
  }, [selectedItems]);

  return (
    <IonApp className={`${darkMode ? 'dark-theme' : ''}`}>
      <IonReactRouter>
        {/*use when='false' to enable/disable split pane programmatically*/}
        <IonSplitPane contentId='main'>
          <Menu />
          <IonRouterOutlet id='main'>
            {/*
            We use IonRoute here to keep the tabs state intact,
            which makes transitions between tabs and non tab pages smooth
            */}
            <Route path='/tabs' render={() => <MainTabs />} />
            {/*<Route path="/" component={HomeOrTutorial} exact />*/}
            <Redirect exact from='/' to='/tabs' />
          </IonRouterOutlet>
          <PricingMenu />
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;

const IonicAppConnected = connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    isStale: state.data.isStale,
    userToken: state.user.userToken,
    isLoading: state.data.isLoading,
    options: state.data.options,
    selectedItems: state.item.selectedItems,
    selectedSheetId: state.item.selectedSheetId,
  }),
  mapDispatchToProps: {
    loadDealerData,
    loadCoinCategoryData,
    loadUserData,
    setUsername,
    loadCoinCatalogData,
    setIsAdmin,
    setUserToken,
    loadItemData,
    setSelectedItemsData,
  },
  component: IonicApp,
});
