import { IonButton, IonIcon, useIonModal } from '@ionic/react';
import { downloadOutline } from 'ionicons/icons';
import React, { useRef } from 'react';
import ExportForm from './ExportForm';

interface ExportFormProps {
  onGroupedPdf?: () => void;
  onGroupedCsv?: () => void;
  onFlatCsv?: () => void;
  disabled: boolean;
}

/**
 * Provides a form to view/add/edit a bid
 * @catalog contains category, type, model for selection
 * @constructor
 */
const ExportModalButton: React.FC<ExportFormProps> = ({ onGroupedPdf, onGroupedCsv, onFlatCsv, disabled }) => {
  const buttonRef = useRef<HTMLIonButtonElement>(null);

  /**
   * First parameter is the component to show, second is the props to pass
   */
  const [present, dismiss] = useIonModal(ExportForm, {
    onGroupedPdf: () => {
      onGroupedPdf && onGroupedPdf();
    },
    onFlatCsv: () => {
      onFlatCsv && onFlatCsv();
    },
    onGroupedCsv: () => {
      onGroupedCsv && onGroupedCsv();
    },
    onDismiss: () => {
      dismiss();
    },
  });

  return (
    <IonButton
      className={'ion-hide-sm-down ion-float-end'}
      onClick={() =>
        present({
          cssClass: 'rounded-modal export-modal', // see App.scss for modal height
          backdropDismiss: true,
          presentingElement: buttonRef.current || undefined,
        })
      }
      disabled={disabled}
      color={'tertiary'}
      ref={buttonRef}
    >
      {/*mobile*/}
      <IonIcon icon={downloadOutline} slot={'icon-only'} className={'ion-hide-md-up'} />
      {/*web*/}
      <IonIcon icon={downloadOutline} className={'ion-hide-md-down'} />
    </IonButton>
  );
};

export default ExportModalButton;
