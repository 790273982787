import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  IonItem,
  IonButton,
  IonIcon,
  IonCard,
  IonCardContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonContent,
  IonSearchbar,
  IonLabel,
  IonButtons,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { CoinModel, CoinType, Options, User } from '../models/types';
import { connect } from '../data/connect';
import { Virtuoso } from 'react-virtuoso';
import { parseCoinInfo } from '../data/dataApi';
import { setSearchText, setSelectedModel } from '../data/sessions/sessions.actions';
import { selectItem } from '../data/item/item.actions';
import ItemSelector from './items/ItemSelector';

interface StateProps {
  coinModels: CoinModel[];
  coinTypes: CoinType[];
  searchText: string;
  options: Options;
  user: User;
}

interface SearchFormProps extends StateProps {
  onDismiss: () => void;
  onAdd?: (searchText: string, cm: CoinModel) => void;
  onSelect?: (searchText: string, cm: CoinModel) => void;
  setSearchText: typeof setSearchText;
  setSelectedModel: typeof setSelectedModel;
  selectItem: typeof selectItem;
}

/**
 * Provides a form to search
 * @param coinInfo information already gathered about a coin that will be bid on
 * @param onDismiss callback when user wishes to close
 * @param onSelect callback when user chooses an item
 * @constructor
 */
const SearchForm: React.FC<SearchFormProps> = ({
  coinModels,
  onDismiss,
  onSelect,
  searchText,
  setSearchText,
  setSelectedModel,
  onAdd,
}) => {
  const [filteredCoinModels, setFilteredCoinModels] = useState<CoinModel[]>();
  const searchBarRef = useRef<any>();

  useMemo(() => {
    if (searchText && searchText.length > 2) {
      // do the actual filter here...
      const coinInfo = parseCoinInfo(searchText);
      console.debug(`parsed info:`, [coinInfo, coinModels]);

      const models = coinModels?.filter((cm: CoinModel) => {
        return (
          cm.isVisible === 1 &&
          !!cm.coinType?.id &&
          cm.coinType?.isVisible === 1 &&
          // if pcgs number supplied then it MUST match
          (!coinInfo.pcgsCoinNumber || cm.pcgsNumber === coinInfo.pcgsCoinNumber) &&
          // otherwise compare on year, name, mintmark, variety, etc.
          (!coinInfo.year || cm.name.startsWith(`${coinInfo.year}`)) &&
          // if entered mintmark it must be in the string
          (!coinInfo.mintmark || cm?.name.indexOf(`${coinInfo.mintmark}`) !== -1) &&
          // if entered denomination it must match exactly
          (!coinInfo.denomination || cm?.coinType?.denomination?.code === coinInfo.denomination) &&
          (!coinInfo?.keywords?.length ||
            cm.name.toUpperCase().indexOf(coinInfo.keywords[0]) !== -1 ||
            !coinInfo?.keywords?.length ||
            cm.coinType?.name.toUpperCase().indexOf(coinInfo.keywords[0]) !== -1)
        );
      });

      setFilteredCoinModels(models);
    } else {
      setFilteredCoinModels([]);
    }
  }, [coinModels, searchText]);

  useEffect(() => {
    setTimeout(() => {
      searchBarRef.current.setFocus();
      searchBarRef?.current?.click();
    }, 500);
  }, [setFilteredCoinModels, searchBarRef]);

  return (
    <IonPage>
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot='end'>
            <IonButton onClick={() => onDismiss()}>
              <IonIcon slot={'icon-only'} icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonSearchbar
        animated={true}
        debounce={500}
        onIonInput={(e) => {
          setSearchText(`${e.detail?.value}`);
        }}
        placeholder='ex. 1893 S Morgan'
        value={searchText}
        ref={(ref) => (searchBarRef.current = ref)}
        mode={'ios'}
      />
      <IonContent>
        {filteredCoinModels && filteredCoinModels.length > 0 ? (
          <Virtuoso
            data={filteredCoinModels}
            totalCount={filteredCoinModels?.length}
            itemContent={(index: number, cm: CoinModel) => {
              return (
                <div key={index} style={{ minHeight: '1px' }}>
                  <IonItem
                    detail={!!onSelect}
                    key={cm.id}
                    routerLink={`#`}
                    onClick={(e) => {
                      e.preventDefault();
                      if (onSelect) {
                        setSelectedModel(cm);
                        onSelect(searchText, cm);
                      }
                    }}
                  >
                    <IonLabel>
                      <h2>{cm.name}</h2>
                      <p>{cm?.coinType ? cm?.coinType?.name : cm.typeId}</p>
                    </IonLabel>
                    {cm.coinType?.id && (
                      <IonLabel slot={'end'}>
                        <p>{cm.coinType.category?.name}</p>
                      </IonLabel>
                    )}
                    {onAdd && (
                      <ItemSelector
                        slot={'end'}
                        item={{
                          model: cm,
                          bidAmount: 0,
                          eachAmount: 0,
                        }}
                        quantity={1}
                        onSuccess={() => {
                          console.debug(`selection successful`);
                          if (onAdd) onAdd(searchText, cm);
                        }}
                      />
                    )}
                  </IonItem>
                </div>
              );
            }}
          />
        ) : (
          searchText &&
          searchText?.length && (
            <IonCard>
              <IonCardContent>{'No matches.'}</IonCardContent>
            </IonCard>
          )
        )}
      </IonContent>
    </IonPage>
  );
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    coinModels: state.data.coinModels,
    coinTypes: state.data.coinTypes,
    searchText: state.data.searchText,
    options: state.data.options,
    user: state.user,
  }),
  mapDispatchToProps: {
    setSearchText,
    setSelectedModel,
    selectItem,
  },
  component: SearchForm,
});
