import { CoinModel, Grade } from '../models/types';

export function useUrlFormatter(): any {
  /**
   * Format URL by replacing applicable keys
   */
  const formatGSUrl = (gsUrl: string, model: CoinModel, grade: Grade): string | undefined => {
    if (!model?.gsCode || model?.gsCode?.length === 0 || !grade?.code || !grade?.display) return undefined;
    return gsUrl
      .replace('{model.gsCode}', model.gsCode)
      .replace('{grade.code}', numberOnly(grade.code))
      .replace('{grade.isCac}', grade.display.includes('CAC') ? '1' : '0');
  };

  /**
   * Format URL by replacing applicable keys
   */
  const formatPCGSUrl = (pcgsUrl: string, model: CoinModel): string | undefined => {
    if (!model?.pcgsNumber || model.pcgsNumber === '0') return undefined;
    return pcgsUrl.replace('{model.pcgsNumber}', model.pcgsNumber);
  };

  /**
   * Format URL by replacing applicable keys
   * REACT_APP_GC_SEARCH_URL
   * https://www.greatcollections.com/search.php?fromyear={model.year}&toyear={model.year}&grade_range_1={gradeNumber}&grade_range_2={gradeNumber}&ungraded=0&fromprice=&toprice=&mode=product&sort=01&action=Ended&series=&q={type.name}&listing_type=4&frombid=0&tobid=500
   */
  const formatGCSearchUrl = (gsUrl: string, model: CoinModel, grade: Grade): string | undefined => {
    if (!model?.year || model?.year?.length === 0 || !grade?.code || !model.coinType?.name) return undefined;
    return gsUrl
      .replaceAll('{model.year}', model.year)
      .replaceAll('{gradeNumber}', numberOnly(grade.code))
      .replaceAll('{type.name}', model.coinType?.name?.replace(' ', '+'));
  };

  /**
   * Format URL by replacing applicable keys
   * https://www.pcgs.com/auctionprices/details/{year}/{modelpcgsNumber}
   */
  const formatPCGSAuctionsUrl = (pcgsAuctionsUrl: string, model: CoinModel): string | undefined => {
    if (!model?.pcgsNumber || model.pcgsNumber === '0' || !model?.year || model?.year?.length === 0) return undefined;
    return pcgsAuctionsUrl.replace('{model.year}', model.year).replace('{model.pcgsNumber}', model.pcgsNumber);
  };

  const numberOnly = (str: string): string => {
    console.debug(`looking for numbers in`, str);
    const numbers = str.match('\\d+');
    if (numbers?.length) {
      console.debug(`numbers`, numbers);
      return numbers[0];
    }
    return '';
  };

  return {
    formatGSUrl,
    formatPCGSUrl,
    formatGCSearchUrl,
    formatPCGSAuctionsUrl,
    numberOnly,
  } as const;
}
