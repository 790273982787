import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { IonHeader, IonToolbar, IonButtons, IonContent, IonBackButton, IonPage, IonItem, IonTitle } from '@ionic/react';
import { connect } from '../data/connect';
import { CoinCategory, CoinType } from '../models/types';
import { Virtuoso } from 'react-virtuoso';
import { withAuthenticationRequired } from '@auth0/auth0-react';

interface OwnProps extends RouteComponentProps {
  coinCategory: CoinCategory;
  coinTypes: CoinType[];
}

interface StateProps {}

interface DispatchProps {}

interface CoinCategoryProps extends OwnProps, StateProps, DispatchProps {}

/**
 * Catalog form to show listing of coin types within a category.
 * @param coinCategory
 * @param coinTypes
 * @constructor
 */
const CoinCategoryDetail: React.FC<CoinCategoryProps> = ({ coinCategory, coinTypes }) => {
  const [filteredTypes, setFilteredTypes] = useState<CoinType[]>();

  const handleItemClick = (e: any, potentialParentId: number) => {
    const subTypes = coinTypes.filter((ct: CoinType) => ct.parentTypeId === potentialParentId);
    if (subTypes.length > 0) {
      setFilteredTypes(subTypes);
      e.preventDefault();
    }
  };

  /**
   * default to show only those types without a parent
   **/
  useEffect(() => {
    setFilteredTypes(coinTypes.filter((ct: CoinType) => !ct.parentTypeId));
  }, [coinTypes]);

  return (
    <IonPage id='dealer-detail'>
      <IonHeader className='ion-no-border'>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonBackButton defaultHref='/tabs/catalog' />
          </IonButtons>
          <IonTitle>{coinCategory?.name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {filteredTypes && (
          <Virtuoso
            style={{ height: '100%' }}
            data={filteredTypes}
            totalCount={filteredTypes?.length}
            itemContent={(index: number, coinType: CoinType) => {
              return (
                <div style={{ height: '56px' }}>
                  <IonItem
                    key={index}
                    detail={true}
                    onClick={(e) => {
                      handleItemClick(e, coinType.id);
                    }}
                    routerLink={`/tabs/type/${coinType.id}`}
                  >
                    {/*<IonAvatar slot="start">*/}
                    {/*<img src={coinType?.obverseImageUrl || "./assets/img/avatar.svg"} alt="Coin"/>*/}
                    {/*</IonAvatar>*/}
                    {coinType.name}
                  </IonItem>
                </div>
              );
            }}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default withAuthenticationRequired(
  connect({
    mapStateToProps: (state, ownProps) => ({
      coinCategory: state.data.coinCategories.find((x: CoinCategory) => x.id == ownProps.match.params['id']),
      coinTypes: state.data.coinTypes.filter((x: CoinType) => x.categoryId == ownProps.match.params['id']),
    }),
    component: CoinCategoryDetail,
  }),
);
