import React from 'react';
import { IonAvatar, IonItem, IonLabel } from '@ionic/react';
import { Dealer } from '../../models/types';

interface DealerItemProps {
  dealer: Dealer;
}

/**
 * Provides a simple list item for a Company/Dealer record.
 * @param dealer the record to show
 * @constructor
 */
const DealerItem: React.FC<DealerItemProps> = ({ dealer }) => {
  return (
    <IonItem>
      <IonAvatar slot='start'>
        <img src={dealer?.owner?.avatar || './assets/img/avatar.svg'} alt='Dealer' />
      </IonAvatar>
      <IonLabel>
        <h2>{dealer.companyName}</h2>
        <p>Owner: {dealer.owner?.name}</p>
      </IonLabel>
      {dealer.level && (
        <IonLabel slot={'end'}>
          <p>{dealer.level}</p>
        </IonLabel>
      )}

      {/*shows an indicator if the dealer has been disabled*/}
      {/*{ dealer.frontendStatus===0 && <IonIcon icon={sad} slot="end" /> }*/}
    </IonItem>
  );
};

export default DealerItem;
