import React from 'react';
import { IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonIcon, IonLabel } from '@ionic/react';
import { Route, Redirect } from 'react-router';

import DealerList from './DealerList';
import CoinTypeDetail from './CoinTypeDetail';
import { analyticsOutline, home, people } from 'ionicons/icons';
import CoinTypeList from './CoinTypeList';
import ProfileTab from './ProfileTab';
import Home from './Home';
import SelectedItems from './SelectedItems';
import CoinCatalog from './CoinCatalog';
import CoinCategoryDetail from './CoinCategoryDetail';
import PublishedSheets from './PublishedSheets';

interface MainTabsProps {}

const MainTabs: React.FC<MainTabsProps> = () => {
  return (
    <IonTabs>
      {/*
          Using the render method prop cuts down the number of renders your components will have due to route changes.
          Use the component prop when your component depends on the RouterComponentProps passed in automatically.
        */}
      <IonRouterOutlet animated={false}>
        <Route path='/tabs/dealers' component={DealerList} />

        <Route path='/tabs/catalog' component={CoinTypeList} exact={true} />
        <Route path='/tabs/catalog/:typeId' component={CoinTypeDetail} />
        <Route path='/tabs/genericBids' component={PublishedSheets} />

        <Route path='/tabs/profileTab' component={ProfileTab} exact={true} />
        <Route path='/tabs/home' component={Home} exact={true} />
        <Route path='/tabs/selectedItems' component={SelectedItems} />

        {/*TODO - these would be good on mobile*/}
        <Route path='/tabs/coinCatalog' component={CoinCatalog} exact={true} />
        <Route path='/tabs/coinCatalog/:id' component={CoinCategoryDetail} />

        {/*use as intro for non-authenticated users who have not yet seen it*/}
        {/*<Route path='/tabs/tutorial' component={Tutorial} />*/}

        {/*default route changes based on auth status*/}
        <Route exact path='/tabs' render={() => <Redirect to={`/tabs/home`} />} />

        {/*fallback route*/}
        <Route render={() => <Redirect to='/tabs/home' />} />
      </IonRouterOutlet>

      <IonTabBar slot='bottom' className={'ion-hide-sm-up'}>
        <IonTabButton tab='home' href='/tabs/home'>
          <IonIcon icon={home} />
          <IonLabel>Home</IonLabel>
        </IonTabButton>
        <IonTabButton tab='dealers' href='/tabs/dealers'>
          <IonIcon icon={people} />
          <IonLabel>Dealers</IonLabel>
        </IonTabButton>
        <IonTabButton tab='catalog' href='/tabs/catalog'>
          <IonIcon icon={analyticsOutline} />
          <IonLabel>Guide</IonLabel>
        </IonTabButton>
        <IonTabButton tab='genericBids' href='/tabs/genericBids'>
          <IonIcon src={'./assets/icon/coins.svg'} />
          <IonLabel>Bid Sheets</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default MainTabs;
