import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { IonButton, IonIcon } from '@ionic/react';
import { person } from 'ionicons/icons';

const LoginButton: React.FC<{}> = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  return (
    <>
      {!isAuthenticated && (
        <IonButton onClick={() => loginWithRedirect()}>
          <IonIcon slot={'start'} icon={person} />
          Log In
        </IonButton>
      )}
    </>
  );
};

export default LoginButton;
