import React from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import {
  IonBreadcrumb,
  IonBreadcrumbs,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
  useIonViewWillEnter,
} from '@ionic/react';
import { connect } from '../data/connect';
import { BidSheet, CoinModel, Options } from '../models/types';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { loadBidSheetValueData, setSelectedBidSheet, setSelectedModel } from '../data/sessions/sessions.actions';
import { trpc } from '../util/trpc';
import { ValueSheetModel } from 'src/services/valueSheets/models/valueSheet';
import PopoverMenuButton, { ActionType } from '../components/common/PopoverMenuButton';
import CommonHeader from '../components/layout/CommonHeader';
import { lockOpenOutline } from 'ionicons/icons';

interface OwnProps extends RouteComponentProps {
  bidSheet: BidSheet;
  options: Options;
}

interface StateProps {
  selectedModel: CoinModel;
  selectedBidSheet: BidSheet;
  bidSheetId: number;
  loading: boolean;
  bidSheets: BidSheet[];
  isAdmin: boolean;
}

interface DispatchProps {
  loadBidSheetValueData: typeof loadBidSheetValueData;
  setSelectedModel: typeof setSelectedModel;
  seSelectedBidSheet: typeof setSelectedBidSheet;
}

interface BidSheetProps extends OwnProps, StateProps, DispatchProps {}

const BidSheetList: React.FC<BidSheetProps> = ({ isAdmin }) => {
  const history = useHistory();

  const bidSheets = trpc.valueSheets.getAllPublishedBidSheets.useQuery({}).data?.data;

  useIonViewWillEnter(() => {
    console.log('ionViewWillEnter event fired');
    setSelectedBidSheet(undefined);
  }, []);

  return (
    <IonPage>
      <CommonHeader
        pageTitle={
          <>
            <IonBreadcrumbs slot={'start'}>
              <IonBreadcrumb routerLink={'/tabs/genericBids'} className={'ion-hide-sm-down'}>
                Generics &amp; Bullion
              </IonBreadcrumb>
            </IonBreadcrumbs>
          </>
        }
        startTools={<>{/*<IonMenuButton />*/}</>}
        endTools={
          <PopoverMenuButton
            types={([] as ActionType[]).concat(
              isAdmin
                ? [
                    {
                      label: 'Edit in Retool',
                      icon: lockOpenOutline,
                      onSelect: () => {
                        window.open(`${process.env.REACT_APP_ADMIN_BID_SHEETS}`, '_blank');
                      },
                    },
                  ]
                : [],
            )}
          />
        }
        showDate={true}
      />

      <IonContent scrollEvents={true}>
        {/*<>*/}
        {/*<Refresher />*/}
        {/*<ValueGrid*/}
        {/*  models={selectedBidSheet?.formattedModels}*/}
        {/*  visibleGrades={activeGrades || options.grade}*/}
        {/*  showTypeImage={false}*/}
        {/*/>*/}

        {/*{JSON.stringify(bidSheets)}*/}
        {/*</>*/}

        <IonGrid>
          <IonRow>
            {bidSheets &&
              bidSheets
                ?.filter((bs: ValueSheetModel) => bs.isVisible)
                ?.map((bs: ValueSheetModel) => (
                  <IonCol
                    class={'ion-align-items-center'}
                    sizeXs={'12'}
                    sizeSm={'6'}
                    sizeMd={'4'}
                    sizeLg={'3'}
                    sizeXl={'2'}
                    key={bs.id}
                  >
                    <IonCard>
                      {/*<img*/}
                      {/*  style={{ width: '100%', height: '10em', objectFit: 'cover' }}*/}
                      {/*  alt='coin image'*/}
                      {/*  src='./assets/img/inventory.jpeg'*/}
                      {/*/>*/}
                      <IonCardHeader>
                        <IonCardTitle class={'ion-text-nowrap'}>{bs.name}</IonCardTitle>
                        <IonCardSubtitle class={'ion-text-nowrap'}>{bs.models?.length} Items</IonCardSubtitle>
                      </IonCardHeader>
                      <IonCardContent style={{ height: '7em' }} class={'ion-hide'}>
                        {bs.comments}
                      </IonCardContent>
                      <IonFooter class={'ion-padding ion-align-items-center'}>
                        <IonButton
                          className={''}
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(`/tabs/genericBids/${bs.id}`);
                          }}
                        >
                          <IonText>Open</IonText>
                        </IonButton>
                      </IonFooter>
                    </IonCard>
                  </IonCol>
                ))}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default withAuthenticationRequired(
  connect({
    mapStateToProps: (state, ownProps) => ({
      bidSheetId: ownProps?.match.params['bidSheetId'],
      selectedBidSheet: state.data.selectedBidSheet,
      options: state.data.options,
      selectedModel: state.data.selectedModel,
      isAdmin: state.user.isAdmin,
      userToken: state.user.userToken,
      bidSheets: state.data.bidSheets,
    }),
    mapDispatchToProps: {
      loadBidSheetValueData,
      setSelectedModel,
      setSelectedBidSheet,
    },
    component: BidSheetList,
  }),
);
