import { SessionsActions } from './sessions.actions';
import { ConfState } from './conf.state';
import { CoinModel } from '../../models/types';
import * as _ from 'lodash';

export const sessionsReducer = (state: ConfState, action: SessionsActions): ConfState => {
  switch (action.type) {
    case 'set-conf-loading': {
      return { ...state, isLoading: action.isLoading };
    }
    case 'set-conf-data': {
      return { ...state, ...action.data };
    }
    case 'add-favorite': {
      return { ...state, favorites: [...state.favorites, action.sessionId] };
    }
    case 'remove-favorite': {
      return { ...state, favorites: [...state.favorites.filter((x) => x !== action.sessionId)] };
    }
    case 'update-filtered-tracks': {
      return { ...state, filteredTracks: action.filteredTracks };
    }
    case 'set-search-text': {
      return { ...state, searchText: action.searchText };
    }
    case 'set-selected-model': {
      return { ...state, selectedModel: action.selectedModel };
    }
    case 'set-menu-enabled': {
      return { ...state, menuEnabled: action.menuEnabled };
    }
    case 'set-stale': {
      return { ...state, isStale: action.isStale };
    }
    case 'set-load-date-time': {
      return { ...state, loadDateTime: action.loadDateTime };
    }
    case 'set-selected-bid-sheet': {
      // setting selected bid sheet properly
      if (action.selectedBidSheet) {
        return {
          ...state,
          selectedBidSheet: {
            ...action.selectedBidSheet,
            formattedModels: _.sortBy(
              action.selectedBidSheet?.formattedModels.map((model) => ({
                ...model,
                coinType: state.coinTypes.find((ct) => ct.id === model.typeId),
              })) || ([] as CoinModel[]),
              ['coinType.sort_order', 'model.sort_order'],
            ),
          },
        };
      } else {
        return { ...state, selectedBidSheet: undefined };
      }
    }
  }
};
