import { IonSearchbar, useIonModal } from '@ionic/react';
import React from 'react';
import { CoinModel } from '../../models/types';
import SearchForm from '../SearchForm';

export interface SearchCellRendererParams {}

export const SearchCellRenderer: React.FC<SearchCellRendererParams> = () => {
  /**
   * First parameter is the component to show, second is the props to pass
   */
  const [present, dismiss] = useIonModal(SearchForm, {
    allowAdd: true,
    onAdd: (searchText: string, cm: CoinModel) => {
      console.debug(`used ${searchText} to select model`, cm);
    },
    onDismiss: () => {
      dismiss();
    },
  });

  return (
    <>
      <IonSearchbar
        animated={false}
        placeholder='Click to add...'
        onIonFocus={() => present({ cssClass: 'rounded-modal', backdropDismiss: true })}
        mode='ios'
        color={'light'}
        class={'ion-no-margin ion-no-padding'}
      />
    </>
  );
};
