import React, { useEffect, useState } from 'react';
import { IonRefresher, RefresherEventDetail, IonRefresherContent } from '@ionic/react';
import { connect } from '../data/connect';
import { RouteComponentProps, withRouter } from 'react-router';
import { setStale } from '../data/sessions/sessions.actions';
import { chevronDownOutline } from 'ionicons/icons';

interface StateProps {
  isStale: boolean;
}

interface DispatchProps {
  setStale: typeof setStale;
}

interface RefresherProps extends RouteComponentProps, StateProps, DispatchProps {}

/**
 * Provides a reusable pull-down refresh component
 */
const Refresher: React.FC<RefresherProps> = ({ isStale, setStale }) => {
  const [refreshEvent, setRefreshEvent] = useState<RefresherEventDetail>();

  useEffect(() => {
    if (refreshEvent && isStale === false) {
      refreshEvent.complete();
      console.log('Refresh completed.');
    }
  }, [refreshEvent, isStale]);

  return (
    <IonRefresher
      slot='fixed'
      onIonRefresh={(event: CustomEvent<RefresherEventDetail>) => {
        console.log('Begin values refresh...');

        // this triggers the actual refresh
        setStale(true);

        // keep track of event here since Ionic-Specific
        setRefreshEvent(event.detail);
      }}
      pullFactor={0.5}
      pullMin={150}
      pullMax={150}
    >
      <IonRefresherContent
        pullingIcon={chevronDownOutline}
        pullingText='Pull down to update...'
        // refreshingSpinner="dots"
        // refreshingText={"Loading..."}
      ></IonRefresherContent>
    </IonRefresher>
  );
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    isStale: state.data.isStale,
  }),
  mapDispatchToProps: {
    setStale,
  },
  component: withRouter(Refresher),
});
