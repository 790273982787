import { IonButton, IonContent, IonIcon, IonItem, IonList, IonPopover, IonText } from '@ionic/react';
import { ellipsisHorizontalOutline } from 'ionicons/icons';
import React, { ReactNode, useRef, useState } from 'react';

export interface ActionType {
  label: string;
  icon?: string;
  onSelect?: (t: ActionType) => void;
}

interface OwnProps {
  icon?: string;
  label?: string;
  /** which actions to show **/
  types: ActionType[];
  /** optional css class name to apply to the IonButton trigger **/
  className?: string;
  children?: ReactNode;
}

/**
 * Presents menu options to the user for selection
 * @param onChange triggered when quantity value should change based upon user actions
 */
const PopoverMenuButton: React.FC<OwnProps> = ({ types, className, children }) => {
  const popover = useRef<HTMLIonPopoverElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const openPopover = (e: any) => {
    // let ignore;
    if (popover && popover.current) {
      // popover.current ? (popover.current.event = e) : (ignore = e);
      popover.current.event = e;
      // console.debug(`ignore set to `, ignore);
    }
    setPopoverOpen(true);
  };

  return (
    <>
      <IonButton onClick={openPopover} className={className} fill={'clear'}>
        {/*<IonText>Export</IonText>*/}
        {children || <IonIcon icon={ellipsisHorizontalOutline} slot={'icon-only'} />}
      </IonButton>
      <IonPopover ref={popover} isOpen={popoverOpen} onDidDismiss={() => setPopoverOpen(false)} dismissOnSelect={true}>
        <IonContent>
          <IonList>
            {types.map((type: ActionType) => (
              <IonItem
                key={type.label}
                button={true}
                detail={false}
                onClick={() => {
                  if (type.onSelect) type.onSelect(type);
                }}
              >
                {type?.icon && <IonIcon slot='start' src={type.icon} />}
                <IonText>{type.label}</IonText>
              </IonItem>
            ))}
          </IonList>
        </IonContent>
      </IonPopover>
    </>
  );
};

export default PopoverMenuButton;
