import { combineReducers } from './combineReducers';
import { sessionsReducer } from './sessions/sessions.reducer';
import { userReducer } from './user/user.reducer';
import {
  BidSheet,
  CoinCategory,
  CoinModel,
  CoinType,
  CoinValueItem,
  Dealer,
  Denomination,
  Grade,
  Mintmark,
  Options,
  Strike,
  User,
} from '../models/types';
import { itemsReducer } from './item/item.reducer';

export const initialState: AppState = {
  data: {
    // dealers
    dealers: [] as Dealer[],
    //
    // catalog
    isStale: true, // first load
    coinTypes: [] as CoinType[],
    coinCategories: [] as CoinCategory[],
    coinModels: [] as CoinModel[],
    //
    // bid sheets
    bidSheets: [] as BidSheet[],
    //
    // general
    isLoading: false,
    menuEnabled: false,
    //
    // options
    options: {
      denomination: [] as Denomination[],
      mintmark: [] as Mintmark[],
      strike: [] as Strike[],
      grade: [] as Grade[],
    } as Options,
  },
  user: {
    hasSeenTutorial: false,
    darkMode: false,
    isLoggedin: false,
    loading: false,
    isAdmin: false,
  } as User,
  item: {
    selectedItems: [] as CoinValueItem[],
    selectedTotal: 0,
    isMenuVisible: false,
    pickToApply: ['grade', 'eachAmount', 'comments', 'bidAmount', 'faceValue', 'certificationService'],
    matchBy: [
      'id',
      'model',
      'type',
      'grade',
      'comments',
      'groupBy',
      'eachAmount',
      'faceValue',
      'bidAmount',
      'key',
      'certificationService',
    ],
    // this is RedBook order
    // sortOrder: ['model.coinType.sortOrder', 'model.coinType.id', 'model.sortOrder', 'model.id', 'grade'],
    // this is entry order
    sortOrder: ['key'],
    selectedSheetId: 0,
  },
};

export const reducers = combineReducers({
  data: sessionsReducer,
  user: userReducer,
  item: itemsReducer,
});

export type AppState = ReturnType<typeof reducers>;
