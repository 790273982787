import { CoinModel, PostItem } from '../models/types';
import { createPost } from '../data/dataApi';
import { useIonToast } from '@ionic/react';
import { useAuth0 } from '@auth0/auth0-react';

export function useRTTPostItem(): any {
  const { getAccessTokenSilently } = useAuth0();
  const [present] = useIonToast();

  const postToFeedbackForum = async (postItem: PostItem): Promise<boolean> => {
    return getAccessTokenSilently().then((token: string) => {
      return createPost(token, postItem)
        .then((response: { success: boolean; message: string }) => {
          // show message
          present(response.message, 5000);
          // return the result
          return response.success;
        })
        .catch((err) => {
          console.debug(err);
          present(`Error occurred while submitting feedback. Please try again later.`, 5000);
          return false;
        });
    });
  };

  const postFeedback = async (
    cm: CoinModel,
    grade: string,
    ourValue: number,
    userValue: number,
    message: string,
  ): Promise<boolean> => {
    return getAccessTokenSilently().then((token: string) => {
      return createPost(token, {
        title: `Feedback for ${cm.coinType?.name} ${cm.name} (pcgs ${cm.pcgsNumber}) in ${grade}.
            Recommending ${ourValue} => ${userValue}. ${message}`,
        postType: 3,
        description: `Originated in CoinBidz`,
        commentsAllowed: true,
        // tags:
      })
        .then((response: { success: boolean; message: string }) => {
          // show message
          present(response.message, 5000);
          // return the result
          return response.success;
        })
        .catch((err) => {
          console.debug(err);
          present(`Error occurred while submitting feedback. Please try again later.`, 5000);
          return false;
        });
    });
  };

  return {
    postFeedback,
    postToFeedbackForum,
  };
}
