import React from 'react';
import { Route, RouteComponentProps } from 'react-router';
import { IonContent, IonPage, IonRouterOutlet } from '@ionic/react';
import { connect } from '../data/connect';
import { BidSheet, Options, ValueSheet } from '../models/types';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { loadBidSheetValueData, setSelectedBidSheet, setSelectedModel } from '../data/sessions/sessions.actions';
import CommonHeader from '../components/layout/CommonHeader';
import WorksheetList from './WorksheetList';
import WorksheetDetail from './WorksheetDetail';

interface OwnProps extends RouteComponentProps {
  bidSheet: BidSheet;
  options: Options;
  isAdmin: boolean;
}

interface StateProps {
  loading: boolean;
  sheets: ValueSheet[];
}

interface DispatchProps {
  loadBidSheetValueData: typeof loadBidSheetValueData;
  setSelectedModel: typeof setSelectedModel;
  setSelectedBidSheet: typeof setSelectedBidSheet;
  sheetId: string | number;
  selectedSheet: ValueSheet;
}

interface PublishedSheetsProps extends OwnProps, StateProps, DispatchProps {}

const SelectedItems: React.FC<PublishedSheetsProps> = ({ match }) => {
  return (
    <IonPage>
      <CommonHeader />

      <IonContent scrollEvents={true} scrollX={true}>
        <IonRouterOutlet>
          <Route exact path={`${match.url}/`} component={WorksheetList} />
          <Route path={`${match.url}/:sheetId`} component={WorksheetDetail} />
        </IonRouterOutlet>
      </IonContent>
    </IonPage>
  );
};

export default withAuthenticationRequired(
  connect({
    mapStateToProps: (state) => ({
      selectedSheet: state.data.selectedSheet,
      options: state.data.options,
      selectedModel: state.data.selectedModel,
      isAdmin: state.user.isAdmin,
      userToken: state.user.userToken,
      bidSheets: state.data.bidSheets,
    }),
    mapDispatchToProps: {
      loadBidSheetValueData,
      setSelectedModel,
      setSelectedBidSheet,
    },
    component: SelectedItems,
  }),
);
