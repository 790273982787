import React, { useEffect, useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonText,
  IonToolbar,
} from '@ionic/react';
import { CoinGradeValue, CoinModel, Grade } from '../../models/types';
import { Page, StyleSheet, Document, Text, Image, View, PDFDownloadLink } from '@react-pdf/renderer';
import { closeOutline } from 'ionicons/icons';
import { useExport } from '../../hooks/useExport';

interface ValuesPdfProps {
  title: string;
  models: CoinModel[];
  grades: Grade[];
  onDismiss: () => void;
}

interface DynamicStyles {
  coinNameStyle: any;
  coinValueColumnStyle: any;
  pageStyle: any;
}

/**
 * Shows a PDF view of the passed models as rows and grades as columns
 */
const ValuesPdf: React.FC<ValuesPdfProps> = ({ title, models, grades, onDismiss }) => {
  const { gradesWithValues, standardizeModelValues, excludeSpecialGrades } = useExport();
  const [targetModelValues, setTargetModelValues] = useState<CoinModel[]>();
  const [filteredGrades, setFilteredGrades] = useState<Grade[]>();
  const [dStyle, setDStyle] = useState<DynamicStyles>();

  useEffect(() => {
    if (models && grades) {
      setFilteredGrades(
        excludeSpecialGrades(
          gradesWithValues(
            models,
            grades.filter((g: Grade) => !g.code.endsWith('CAC') && !g.code.endsWith('plus')),
          ),
        ),
      );
    }
    // eslint-disable-next-line
  }, [models, grades]);

  useEffect(() => {
    if (filteredGrades) {
      setTargetModelValues(standardizeModelValues(models, filteredGrades));
    }
    // eslint-disable-next-line
  }, [filteredGrades, models]);

  useEffect(() => {
    if (targetModelValues && filteredGrades) {
      // we must apply some styling based upon the number of columns
      const columnCount = filteredGrades.length;
      const valueWidth = Math.min(876 / columnCount, 42); // max value col width of 60
      const nameWidth = Math.min(1020 - 20 - 40 - valueWidth * columnCount - 20, 250); // max name col width of 250
      setDStyle({
        pageStyle: {
          paddingTop: 35,
          paddingBottom: 65,
          paddingHorizontal: 20,
        },
        coinNameStyle: {
          width: nameWidth,
        },
        coinValueColumnStyle: {
          width: valueWidth,
        },
      });
    }
  }, [targetModelValues, filteredGrades]);

  // styles typically shown this way which allows for type checking scss should work as well
  const styles = StyleSheet.create({
    title: {
      marginTop: -35,
      marginLeft: 15,
      fontSize: 16,
    },
    subtitle: {
      fontSize: 10,
      textAlign: 'center',
    },
    header: {
      fontSize: 12,
      marginBottom: 10,
      textAlign: 'center',
      color: 'grey',
    },
    logo: {
      width: 125,
      height: 40,
    },
    row: {
      flexDirection: 'row',
      paddingVertical: 2,
      paddingHorizontal: 1,
      fontSize: 9,
    },
    headerRow: {
      borderBottomColor: 'lightgrey',
      borderBottom: '2px',
    },
    dataRow: {
      border: 'none',
    },
    evenRow: {
      backgroundColor: '#f2f2f2',
    },
    coinName: {
      borderRight: '1px',
      borderColor: 'lightgrey',
      borderStyle: 'dotted',
      textOverflow: 'ellipsis',
    },
    coinValueHeader: {
      textAlign: 'right',
    },
    coinValue: {
      // color: 'grey',
      textAlign: 'right',
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    upIndicator: {
      color: 'green',
    },
    downIndicator: {
      color: 'red',
    },
  });

  const MyDoc = () => (
    <Document title={title} author={'CoinBidz'}>
      {dStyle && (
        <Page orientation={'landscape'} style={dStyle.pageStyle}>
          <View style={styles.header} fixed>
            <Image style={styles.logo} src={'assets/img/CoinBidz_logo.png'} />
            <Text style={styles.title}>{title}</Text>
            <Text style={styles.subtitle}>
              as of {new Date().toLocaleDateString()} @ {new Date().toLocaleTimeString()}{' '}
            </Text>
          </View>

          <View style={[styles.row, styles.headerRow]} fixed>
            <Text style={[styles.coinName, filteredGrades && dStyle.coinNameStyle]}> </Text>
            {filteredGrades &&
              filteredGrades?.map((g: Grade, columnIndex) => (
                <Text
                  key={`grade-${g.code}-${columnIndex}`}
                  style={[styles.coinValueHeader, dStyle.coinValueColumnStyle]}
                >
                  {g.code}
                </Text>
              ))}
          </View>
          {targetModelValues &&
            targetModelValues?.map((cm: CoinModel, rowIndex: number) => (
              // zebra stripe rows by appending evenRow style
              <View
                key={`model-${cm.name}`}
                style={rowIndex % 2 === 0 ? [styles.row, styles.dataRow] : [styles.row, styles.dataRow, styles.evenRow]}
              >
                <Text style={[styles.coinName, dStyle.coinNameStyle]}>{cm.name}</Text>
                {cm.values?.map((v: CoinGradeValue) => (
                  <>
                    {!v || !v?.valueChange ? (
                      <Text style={[styles.coinValue, dStyle.coinValueColumnStyle]}>{v?.formattedValue || `--`}</Text>
                    ) : (
                      <Text
                        style={[
                          styles.coinValue,
                          dStyle.coinValueColumnStyle,
                          v?.valueChange > 0 ? styles.upIndicator : styles.downIndicator,
                        ]}
                      >
                        {v?.valueChange > 0 ? `^ ${v?.formattedValue}` : `- ${v?.formattedValue}`}
                      </Text>
                    )}
                  </>
                ))}
              </View>
            ))}
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) => `- Page ${pageNumber} / ${totalPages} -`}
            fixed
          />
        </Page>
      )}
    </Document>
  );

  return (
    <IonPage>
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot='end'>
            <IonButton onClick={() => onDismiss()}>
              <IonIcon slot={'icon-only'} icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent class={'ion-text-center'}>
        <IonCard>
          <IonCardContent>
            <PDFDownloadLink document={MyDoc()} fileName={`${title}.pdf`}>
              {({ loading }) =>
                loading ? (
                  `Generating pdf document...`
                ) : (
                  <>
                    <IonText>{`${title}.pdf`}</IonText>
                    <br />
                    <IonButton onClick={() => onDismiss()}>Download</IonButton>
                  </>
                )
              }
            </PDFDownloadLink>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default ValuesPdf;
