import { ItemActions } from './item.actions';
import { ItemState } from './item.state';
import { CoinValueItem } from '../../models/types';
import * as _ from 'lodash';

export const calcTotal = (items: CoinValueItem[]): number => {
  // qty uses eachAmount while fv uses bidAmount
  return items
    ?.filter((i) => !i.isDeleted)
    ?.reduce(
      (sum: number, current: CoinValueItem) =>
        Number(sum) + Number((current?.model?.measure === 'fv' ? current.bidAmount : current.eachAmount) || 0),
      0,
    );
};

export const calcGroupBy = (item: CoinValueItem): string => {
  return `${item.model?.id}_${item.grade}_${item.eachAmount}_${item.faceValue !== 0}_${item.certificationService}_${
    item.comments
  }`;
};

export const groupSelectedItemsBy = (items: CoinValueItem[], matchBy: string[]): CoinValueItem[] => {
  const groups = _.chain(items)
    .filter((i) => !i.isDeleted)
    .groupBy('groupBy') // groupBy is maintained inside reducer based pon grouped fields
    .mapValues((values) => ({
      ..._.pick(_.first(values), matchBy),
      quantity: values.length,
    }))
    .flatMap() // gives us just the value of each group
    .value() as CoinValueItem[];

  // now update the bid amount (total) on each group
  return groups.map((item: CoinValueItem) => ({
    ...item,
    bidAmount:
      Number(item.model?.measure === 'fv' ? item?.faceValue : 1) *
      Number(item?.eachAmount ?? 1) *
      Number(item.quantity ?? 1),
  }));
};

export const generateId = (): string => {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

export const itemsReducer = (state: ItemState, action: ItemActions): ItemState => {
  switch (action.type) {
    case 'set-item-data': {
      return {
        ...state,
        ...action.data,
        selectedItemGroups: groupSelectedItemsBy(action.data.selectedItems ?? [], state.matchBy),
        selectedTotal: calcTotal(action.data.selectedItems ?? []),
      };
    }
    case 'select-item': {
      // replicate objects the number of times
      const newItems = _.fill(Array(action.quantity || 0), {
        ...action.item,
        id: undefined,
        createdAt: new Date(),
        groupBy: calcGroupBy(action.item),
      }).map((item) => ({
        ...item,
        key: generateId(),
      }));

      const selectedItems = [...state.selectedItems.concat(newItems)];

      return {
        ...state,
        selectedItems,
        selectedTotal: calcTotal(selectedItems),
        selectedItemGroups: groupSelectedItemsBy(selectedItems, state.matchBy),
      };
    }
    case 'remove-item': {
      // find items we are removing (could be multiple)
      // limited by limit if specified
      const deleteKeys = state.selectedItems
        .filter((it) => it.groupBy === action.item.groupBy && !it.isDeleted)
        .map((it) => it.key);

      // delete starting at the end
      const limitedDeleteKeys = action.limit ? deleteKeys.slice(deleteKeys.length - action.limit) : deleteKeys;

      console.debug(`deleting ids: `, limitedDeleteKeys);

      const selectedItems = state.selectedItems.map((item) => ({
        ...item,
        isDeleted: item.isDeleted || limitedDeleteKeys.indexOf(item.key) !== -1,
      }));

      // if selected sheet is 0 (draft) then filter out the deleted items
      const newSelectedItems = state.selectedSheetId === 0 ? selectedItems.filter((i) => !i.isDeleted) : selectedItems;

      return {
        ...state,
        selectedItems: newSelectedItems,
        selectedTotal: calcTotal(newSelectedItems),
        selectedItemGroups: groupSelectedItemsBy(selectedItems, state.matchBy),
      };
    }

    // update ALL items matching
    case 'update-item': {
      // the values to apply should be based on the new values
      const changeToApply = _.pick(action.item, state.pickToApply);
      const matchingItemKeys = state.selectedItems
        .filter((item) => item.groupBy === action.item.groupBy)
        .map((i) => i.key);
      const updateItems = state.selectedItems.map((item) => {
        if (matchingItemKeys.indexOf(item.key) != -1) {
          return {
            ...item,
            ...changeToApply,
            groupBy: calcGroupBy({
              ...item,
              ...changeToApply,
            }),
            isChanged: true,
            bidAmount:
              (item.eachAmount || 0) *
              (action.item.model?.measure === 'fv' ? action.item.faceValue || 0 : matchingItemKeys.length),
          };
        } else {
          return item;
        }
      });

      // const newSelectedItems = _.sortBy(updateItems, state.sortOrder);

      return {
        ...state,
        selectedItems: updateItems,
        selectedTotal: calcTotal(updateItems),
        selectedItemGroups: groupSelectedItemsBy(updateItems, state.matchBy),
      };
    }
    case 'remove-all': {
      return {
        ...state,
        selectedItems: [],
        selectedItemGroups: [],
        selectedTotal: 0,
      };
    }
    case 'show-menu': {
      return {
        ...state,
        isMenuVisible: true,
      };
    }
    case 'hide-menu': {
      return {
        ...state,
        isMenuVisible: false,
      };
    }
    case 'select-sheet-id': {
      return {
        ...state,
        selectedSheetId: action.sheetId,
      };
    }
  }
};
