import React from 'react';
import {
  IonAvatar,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
} from '@ionic/react';
import { connect } from '../data/connect';
import CommonHeader from '../components/layout/CommonHeader';
import { BidSheet } from '../models/types';
import { Link } from 'react-router-dom';
import CategoryListing from '../components/CategoryListing';

interface OwnProps {}

interface StateProps {
  bidSheets?: BidSheet[];
}

interface DispatchProps {}

interface ProfileTabProps extends OwnProps, StateProps, DispatchProps {}

const Home: React.FC<ProfileTabProps> = () => {
  const quickLaunchSlides = [
    {
      link: '/tabs/genericBids/1',
      name: 'Generic Gold',
      iconName: '/assets/img/1850Liberty20Obverse.png',
    },
    {
      link: '/tabs/genericBids/4',
      name: 'Bullion',
      iconName: '/assets/img/catalog/types/101_O_thumbnail.png',
    },
    {
      link: '/tabs/genericBids/23',
      name: 'Pre-65 Silver',
      iconName: '/assets/img/catalog/types/50_O_thumbnail.png',
    },
    {
      link: '/tabs/genericBids/28',
      name: 'Generic Dollars',
      iconName: '/assets/img/catalog/types/26_O_thumbnail.png',
    },
    {
      link: '/tabs/catalog',
      name: 'Other',
      iconName: '/assets/img/catalog/types/136_O_thumbnail.png',
    },
  ];
  return (
    <IonPage>
      <CommonHeader />
      <IonContent fullscreen={true}>
        <IonGrid>
          <IonRow class='ion-justify-content-center ion-padding'>
            {quickLaunchSlides &&
              quickLaunchSlides?.map((bs, index: number) => (
                <IonCol key={index} sizeXs={'4'} sizeLg={'2'} style={{ align: 'center', textAlign: 'center' }}>
                  <IonCard
                    routerLink={bs.link}
                    style={{ width: '100%', alignContent: 'center', textAlign: 'center' }}
                    className={'plain'}
                  >
                    <div>
                      <img src={bs.iconName} width={75} />
                    </div>
                    <div>
                      <IonLabel>{bs.name}</IonLabel>
                    </div>
                  </IonCard>
                </IonCol>
              ))}
          </IonRow>
          <IonRow class={'ion-hide'}>
            <IonCol size={'12'}>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>Welcome</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonText>
                    <p>
                      <b>CoinBidz</b> is a valuation tool to assist creating bids and locating markets across the
                      Roundtable network. Roundtable trading is a type of investment strategy where a group of traders,
                      often with diverse backgrounds and perspectives, collaborate to make investment decisions. The
                      goal of roundtable trading is to leverage the collective knowledge, expertise, and resources of
                      the group to make informed and profitable trades. This can involve sharing information about
                      market trends, analyzing data, discussing investment opportunities, and making joint investment
                      decisions. Roundtable trading can be performed in-person or online, and participants can come from
                      a variety of backgrounds, including professional traders, portfolio managers, and individual
                      investors. The key to successful roundtable trading is having a group of participants who are
                      committed to sharing information and working together to achieve common goals.
                    </p>
                    <IonImg
                      src={'assets/img/RoundTable2021_banner.png'}
                      alt={'RoundTable Logo'}
                      style={{ width: '200px', margin: '20px' }}
                    />
                  </IonText>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonRow class={'ion-hide'}>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>Bulk Valuations</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonText>
                    <p>
                      Providing accurate estimates can be a complex and challenging task, as there are many factors to
                      consider. CoinBidz helps by publishing aggregated values based for thousands of numismatic
                      products using auction results and live bids for numismatic and bullion items for exclusive use by
                      the RoundTable Trading network of dealers.
                    </p>
                  </IonText>
                  <IonButton routerLink={'/tabs/catalog'} color={'primary'}>
                    Start Estimate
                  </IonButton>
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>Create Bid/Ask Sheet</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <p>
                    The bid sheet is an important tool for both buyers and sellers, as it provides a clear and objective
                    evaluation of the coin being offered. By using a coin bid sheet, buyers can make informed decisions
                    and bid with confidence, while sellers can ensure that their coins are accurately and fairly
                    represented. Published CoinBidz values are considered sight-seen properly graded coins.
                  </p>
                  <IonButton routerLink={'/tabs/catalog'} color={'medium'}>
                    Create Bid/Ask Sheet
                  </IonButton>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>

        <CategoryListing />

        <ul style={{ listStyle: 'none', padding: '10' }} className={'ion-hide'}>
          <li style={{ display: 'inline-block', textAlign: 'center' }}>
            <Link to={''}>
              <IonAvatar>
                <img src='assets/img/1850Liberty20Obverse.png' />
              </IonAvatar>
              <IonLabel>Generic Gold</IonLabel>
            </Link>
          </li>
          <li style={{ display: 'inline-block', textAlign: 'center' }}>
            <IonAvatar>
              <img src='https://swiperjs.com/demos/images/nature-2.jpg' />
            </IonAvatar>
            <IonLabel>Bullion</IonLabel>
          </li>
          <li style={{ display: 'inline-block', textAlign: 'center' }}>
            <IonAvatar>
              <img src='https://swiperjs.com/demos/images/nature-3.jpg' />
            </IonAvatar>
            <IonLabel>90% Silver</IonLabel>
          </li>
          <li style={{ display: 'inline-block', textAlign: 'center' }}>
            <IonAvatar>
              <img src='https://swiperjs.com/demos/images/nature-4.jpg' />
            </IonAvatar>
            <IonLabel>Generic Coins</IonLabel>
          </li>
          <li style={{ display: 'inline-block', textAlign: 'center' }}>
            <IonAvatar>
              <img src='https://swiperjs.com/demos/images/nature-5.jpg' />
            </IonAvatar>
            <IonLabel>Other</IonLabel>
          </li>
        </ul>
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  component: Home,
  mapStateToProps: (state) => ({
    bidSheets: state.data.bidSheets,
  }),
});
