import { useIonModal } from '@ionic/react';
import WorksheetForm from '../components/items/WorksheetForm';
import { BidSheet } from '../models/types';
import { trpc } from '../util/trpc';

export function useWorksheetModal(): any {
  const trpcUtils = trpc.useContext(); // .valueSheets.getById.useQuery();

  /**
   * First parameter is the component to show, second is the props to pass
   */
  const [present, dismiss] = useIonModal(WorksheetForm, {
    worksheet: {},
    onSave: (newWorksheet: BidSheet) => {
      console.debug(`newWorksheet is:`, newWorksheet);
      dismiss();
    },
    onCancel: () => {
      console.debug(`worksheet form dismissed`);
      dismiss();
    },
  });

  const presentSaveAs = () => {
    console.debug('****presentSaveAs', trpcUtils.valueSheets.getAllValueSheets);
    present({ cssClass: 'rounded-modal worksheet-modal', backdropDismiss: true });
  };

  return {
    presentSaveAs,
  } as const;
}
