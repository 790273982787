import { IonButton, useIonToast } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { CoinValueItem } from '../../models/types';
import { selectItem } from '../../data/item/item.actions';
import { connect } from '../../data/connect';

interface OwnProps {
  item: CoinValueItem;
  quantity?: number;
  onSuccess?: () => void;
  slot?: string;
}

interface StateProps {
  selectedItems: CoinValueItem[];
}

interface ItemSelectorProps extends OwnProps, StateProps {
  selectItem: typeof selectItem;
}

const ItemSelector: React.FC<ItemSelectorProps> = ({ item, selectItem, onSuccess, quantity, selectedItems, slot }) => {
  const [toast] = useIonToast();
  const [matchingCount, setMatchingCount] = useState<number>();

  /**
   * Query how many of the current item are in the selected items
   */
  useEffect(() => {
    if (selectedItems && selectedItems.length > 0 && item?.model?.id) {
      // might need to allow passing match props
      const matchingItems = selectedItems?.filter(
        (eachItem: CoinValueItem) =>
          eachItem.model?.id === item.model?.id && (!item.grade || item.grade === eachItem.grade),
      );

      setMatchingCount(matchingItems.length);
    } else {
      setMatchingCount(0);
    }
  }, [item, selectedItems]);

  return (
    <IonButton
      slot={slot || ''}
      color={matchingCount === 0 ? 'primary' : 'success'}
      expand={'block'}
      onClick={() => {
        if (item) {
          selectItem(item, quantity || 0);
          if (onSuccess) {
            console.debug(`Added ${quantity} ${item?.model.name}
            ${item?.model.coinType?.name ? item?.model.coinType?.name : ''}`);
            toast(
              `Added ${quantity} ${item?.model.name} ${item?.model.coinType?.name ? item?.model.coinType?.name : ''}`,
              5000,
            );
            onSuccess();
          }
        }
      }}
    >
      {selectedItems && matchingCount ? <span>Add Qty ({matchingCount})</span> : <span>Add to List</span>}
    </IonButton>
  );
};

// export default ItemSelector;

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state, ownProps: OwnProps) => ({
    item: ownProps.item,
    selectedItems: state.item.selectedItems,
  }),
  mapDispatchToProps: {
    selectItem,
  },
  component: ItemSelector,
});
